import React, { PureComponent } from 'react';
import { Button, Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

import './UserAudit.scss';

const api = new ApiService();

class UserAudit extends PureComponent {
  state = {
    isLoading: false,
    siteList: [],
    searchType: undefined,
    searchQuery: '',
    errorMessage: '',
    dateStart: new Date(),
    dateEnd: new Date(),
  };

  getDateChangeCallback = (fieldName) => (e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: this.getSubmitErrorsByDate(dateStart, dateEnd),
        }));
      },
    );
  };

  getSubmitErrorsByDate = (startDate, endDate) => {
    const { translate } = this.props;
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = StandardizeTimeForDate(new Date(2007, 0, 1), true);
    const maxSelectedDate = StandardizeTimeForDate(new Date());

    if (
      minimumSelectedDate - normalizedStartDate > 0 ||
      minimumSelectedDate - normalizedEndDate > 0 ||
      normalizedStartDate - maxSelectedDate > 0 ||
      normalizedEndDate - maxSelectedDate > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  fetchAuthorizationAuditList = async () => {
    const { dateStart, dateEnd } = this.state;
    const { userId } = this.props.dataItem;

    try {
      await this.setState({ isLoading: true });

      const list = await api.getUsersAudit({
        key: 1,
        value: userId,
        dateStart: getDataByTimezoneOffset(StandardizeTimeForDate(dateStart, true)).toISOString(),
        dateEnd: getDataByTimezoneOffset(StandardizeTimeForDate(dateEnd)).toISOString(),
      });

      this.setState({
        isLoading: false,
        siteList: list,
      });
    } catch (err) {
      const { showError } = this.props;
      showError(err);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate, dataItem } = this.props;
    const { errorMessage, dateStart, dateEnd } = this.state;

    return (
      <Row className="UserAudit-toolbar">
        <div>
          <div className="UserAudit-toolbarUserInfo">
            <div className="EasypayUserCardGrid-toolbarItem">
              <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.fullName')}</div>
              <Input
                className="EasypayUserCardGrid-input"
                value={`${dataItem.firstName} ${dataItem.lastName}`}
                disabled
              />
            </div>

            <div className="EasypayUserCardGrid-toolbarItem">
              <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.phoneNumber')}</div>
              <Input className="EasypayUserCardGrid-phoneInput" value={`${dataItem.phone || ''}`} disabled />
            </div>
          </div>

          <div className="UserAudit-dateRow">
            <div className="UserAudit-dateFieldRow">
              <div className="UserAudit-dateLabel">{translate('page.changingProfileHistory.dateFrom')}</div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>

            <div className="UserAudit-dateFieldRow">
              <div className="UserAudit-dateLabel">{translate('page.changingProfileHistory.dateTo')}</div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>
          </div>
        </div>

        <Button type="primary" onClick={this.fetchAuthorizationAuditList} disabled={errorMessage}>
          {translate('core.buttonTitles.search')}
        </Button>

        {errorMessage && <div className="UserAudit-errorMessage">{errorMessage}</div>}
      </Row>
    );
  };

  render() {
    const { siteList, isLoading } = this.state;

    return (
      <Grid
        data={siteList}
        isLoading={isLoading}
        name="EasyPayBalanceGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAuthorizationBalanceInfo}
      >
        <GridColumn field="Id" title="page.auditSite.PpNumber" width="80px" />
        <GridColumn field="Operation" title="page.auditSite.operation" width="120px" />
        <GridColumn field="InstrumentName" title="page.auditSite.tool" width="140px" />
        <GridColumn field="InstrumentValue" title="page.auditSite.toolValue" width="200px" />
        <GridColumn field="IP" title="page.auditSite.IPAddress" width="160px" />
        <GridColumn field="ServiceId" title="page.auditSite.service" width="160px" />
        <GridColumn field="Amount" title="page.auditSite.amount" width="80px" />
        <GridColumn field="Status" title="page.auditSite.status" width="100px" />
        <GridColumn
          field="DatePost"
          title="grids.accountsDetail.column-date"
          width="240px"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
        <GridColumn field="UserAgent" title="page.auditSite.agent" width="160px" />
        <GridColumn field="ErrorMessage" title="page.auditSite.error" width="160px" />
        <GridColumn field="RestrictionRule" title="page.auditSite.restrictionRule" width="160px" />
        <GridColumn field="UserId" title="page.profilesMenu.userId" width="160px" />
        <GridColumn field="PhoneNumber" title="page.auditSite.phoneNumber" width="180px" />
      </Grid>
    );
  }
}

export default withTranslate(UserAudit);
