import React, { PureComponent } from 'react';
import { Row, Input } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, tabService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

import './EasypayUserAccountNumbersGrid.styled.scss';

const api = new ApiService();

const AGGREGATE = [
  { field: 'WalletNumber', aggregate: 'count', format: 'n0' },
  { field: 'Balance', aggregate: 'sum', format: 'n2' },
  { field: 'Limit', aggregate: 'sum', format: 'n2' },
  { field: 'BlockedAmount', aggregate: 'sum', format: 'n2' },
];

class EasypayUserAccountNumbersGrid extends PureComponent {
  currentArray = findArray('easypayUsersManage');

  state = {
    accounts: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getWallets();
  }

  buildToolbar = () => {
    const { translate, dataItem } = this.props;

    return (
      <Row className="EasypayUserAccountNumbersGrid-toolbar">
        <div className="EasypayUserAccountNumbersGrid-toolbarItem">
          <div className="EasypayUserAccountNumbersGrid-label">{translate('page.easypayUsersManage.fullName')}</div>
          <Input
            className="EasypayUserAccountNumbersGrid-input"
            value={`${dataItem.firstName} ${dataItem.lastName}`}
            disabled
          />
        </div>

        <div className="EasypayUserAccountNumbersGrid-toolbarItem">
          <div className="EasypayUserAccountNumbersGrid-label">{translate('page.easypayUsersManage.phoneNumber')}</div>
          <Input className="EasypayUserAccountNumbersGrid-phoneInput" value={`${dataItem.phone || ''}`} disabled />
        </div>
      </Row>
    );
  };

  getWallets = async () => {
    const {
      dataItem: { userId },
    } = this.props;
    try {
      await this.setState({ isLoading: true });
      const wallets = await api.getUserWallets(userId);

      this.setState({ isLoading: false, accounts: wallets });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getHandlers = () => {
    const { dataItem: dataItemFromProps } = this.props;

    return [
      {
        title: 'page.easypayUsersManage.walletTransactions',
        action: ({ dataItem }) =>
          tabService.addTab({ type: 'accountNumberInfo', dataItem: { ...dataItemFromProps, ...dataItem } }),
        dropdown: false,
        show: true,
      },
    ];
  };

  render() {
    const { accounts, isLoading } = this.state;

    return (
      <Grid
        data={accounts}
        name="easypayUserAccountNumbersGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.getWallets}
        isLoading={isLoading}
        aggregate={AGGREGATE}
      >
        <GridColumn
          field="WalletNumber"
          title="page.easypayUsersManage.accountNumber"
          width="140"
          handlers={this.getHandlers()}
          dropdown={checkElement('easypayUsersManage-walletTransaction', this.currentArray)}
        />
        <GridColumn field="TypeName" title="page.easypayUsersManage.type" width="80" />
        <GridColumn field="Balance" title="page.easypayUsersManage.balance" width="100" />
        <GridColumn field="Limit" title="page.easypayUsersManage.limit" width="100" />
        <GridColumn field="StatusName" title="page.easypayUsersManage.status" width="150" />
        <GridColumn field="BlockedAmount" title="page.easypayUsersManage.blockedBalance" width="200" />
        <GridColumn
          field="DateUpdate"
          title="page.easypayUsersManage.date"
          width="140"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="Description" title="page.easypayUsersManage.comment" width="240" />
        <GridColumn field="UserId" title="page.easypayUsersManage.userNumber" width="180" />
        <GridColumn field="WalletId" title="page.easypayUsersManage.walletId" width="100" />
      </Grid>
    );
  }
}

export default withTranslate(EasypayUserAccountNumbersGrid);
