import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Input } from 'antd';
import { ApiService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import Grid from '../../components/grids/baseGrid';

const api = new ApiService();

class TransactionDetails extends PureComponent {
  state = {
    data: [],
    isLoading: false,
  };

    componentDidMount() {
      this.makeQuery();
    }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  makeQuery = async () => {
    const {
      dataItem: { transactionId },
    } = this.props;
    if (!transactionId) return;
    try {
      this.toggleLoader(true);
      const value = {
        transactionId,
        Hist: true,
      };
      const data = await api.transactionDetails(value);
      this.setState({ data });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  renderToolbar = () => {
    const { translate, dataItem } = this.props;
    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }} className="GridTransactionsDetail-toolbar">
        <div className="issued-certificates--toolbar">
          <p>{translate('grids.terminal-events.column-transactionId')}</p>
          <Input value={dataItem.transactionId} disabled style={{maxWidth:'100%'}}/>
        </div>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data } = this.state;

    return (
      <>
        <Grid
          data={data}
          onRefresh={this.makeQuery}
          isLoading={isLoading}
          toolbar={this.renderToolbar()}
          name="transactionDetails"
          permissionName="searchtransactions"
        >
          <GridColumn field="Name" title="grids.transactionDeatails.column-name" />
          <GridColumn field="Value" title="grids.transactionDeatails.column-value" width="1600px" />
        </Grid>
      </>
    );
  };
}

export default withTranslate(TransactionDetails);
