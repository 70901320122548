const easypayUsersManage = (translate, value) => {
  switch (value) {
    case 'easypayUsersManage-content':
      return `${translate(`page.adminPanel.pageContent`)}`;

    case 'easypayUsersManage-wallets':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.easypayUsersManage.accountNumbers')}`;

    case 'easypayUsersManage-userAudit':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.userAudit.userAuditTitle')}`;

    case 'easypayUsersManage-cards':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.easypayUsersManage.cards')}`;

    case 'easypayUsersManage-transactionHistory':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.easypayUsersManage.transactionHistory')}`;

    case 'easypayUsersManage-rating':
      return `${translate(`page.adminPanel.dropdown-button`)}:
              ${translate('page.userRatingGrid.userRatingGridTitle')}`;

    case 'easypayUsersManage-walletTransaction':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.easypayUsersManage.accountNumbers')}, ${translate(
        `page.adminPanel.dropdown-button`,
      )}: ${translate(`page.easypayUsersManage.walletTransactions`)}`;

    case 'easypayUsersManage-walletTransactionDetails':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.easypayUsersManage.accountNumbers')}, ${translate(
        `page.adminPanel.type-tab`,
      )}: ${translate('page.easypayUsersManage.walletTransactions')}, ${translate(
        `page.adminPanel.dropdown-button`,
      )} : ${translate(`page.easypayUsersManage.details`)}`;

    case 'easypayUsersManage-cardTransaction':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.easypayUsersManage.cards')}, ${translate(
        `page.adminPanel.dropdown-button`,
      )}: ${translate(`page.easypayUsersManage.cardTransactions`)}`;

    case 'easypayUsersManage-cardTransactionDetails':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.easypayUsersManage.cards')}, ${translate(`page.adminPanel.type-tab`)}: ${translate(
        'page.easypayUsersManage.cardTransactions',
      )}, ${translate(`page.adminPanel.dropdown-button`)} : ${translate(`page.easypayUsersManage.details`)}`;

    case 'easypayUsersManage-transactionHistoryDetails':
      return `${translate(`page.adminPanel.type-tab`)}:
              ${translate('page.easypayUsersManage.transactionHistory')}, ${translate(
        `page.adminPanel.dropdown-button`,
      )}: ${translate(`page.easypayUsersManage.details`)}`;

    default:
      return value;
  }
};

export default easypayUsersManage;
