import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import { aggregateBy, filterBy, orderBy } from '@progress/kendo-data-query';
import { formatNumber } from '@telerik/kendo-intl';
import { Sortable } from '@progress/kendo-react-sortable';

import EmptyData from './emptyData';
import VirtualDesktopGrid from './virtualScroll/desktop';
import VirtualMobileGrid from './virtualScroll/mobile';
import { CellDate, CellDropdown, CellLink } from './cells';
import { FilterScroll } from './filters';
import Toolbar from './toolbar/toolbar';
import { withTranslate } from '../../contexts/localContext';
import './baseGrid.scss';
import { StorageService } from '../../services';
import BaseHeaderCell from './baseHeaderCell';
import CellLastTransaction from '../../pages/monitorActivity/cellLastTransaction';
import SortableItemUI from './sortableItemUI';
import { getUniqueByFieldName } from '../../utils/getUniqueByFieldName';
import { getIsFunction } from '../../utils/getIsFunction';
import { availableTablesList } from './constants';
import LoadingPanel from '../loader';
import GqlService from './gql.service';
import PagePanel from './PagePanel';
import FilterConstructorModal from '../FilterConstructorModal/FilterConstructorModal';
import PopupGroupDialog from './Dialogs/PopupGroupDialog';

const baseBreakpoint = 992;

// уникальный ключ столбца
const buildKey = (level, i, field) => `${level}_${i}_${field}`;

// Поиск столбцов по типу фильтра
const getFieldsByType = (object, type) =>
  Object.values(object)
    .map(({ field, props: { filter } }) => ({ field, filter }))
    .filter((q) => q.filter === type)
    .map(({ field }) => field);

const CellAggregate = (props) => (
  <td colSpan={props.colSpan} style={props.style}>
    {props.value}
  </td>
);

const CellAggregateExcel = (props) => {
  return props.value;
};

const sortFunc = (a, b) => {
  return a.orderIndex - b.orderIndex;
};

class VirtualBaseGrid extends Component {
  static defaultProps = {
    rowHeight: 20,
    rowCount: 80,
    exportExcel: true,
    remoteCommands: false,
    locked: false,
    multiSelected: false,
    isShowSpecificFilterByFieldName: '',
    isPhotoCell: false,
    isTooltip: false,
    specificFieldsToHide: [],
    specificCheckBoxTitle: '',
    tooltip: true,
    dropdown: false,
    isGroup: false,
  };
  static propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    aggregate: PropTypes.any,
    toolbar: PropTypes.any,
    onRefresh: PropTypes.func,
    rowHeight: PropTypes.number,
    rowCount: PropTypes.number,
    exportExcel: PropTypes.bool,
  };

  grid = React.createRef();

  exporter = React.createRef();

  constructor(props) {
    super(props);
    const titles = this.buildColumnCollection(props);

    this.state = {
      breakpoint: window.innerWidth,
      dataState: {
        sort: [...this.getInitialSort()],
        filter: {
          logic: 'and',
          filters: [...this.getInitialFilter()],
        },
      },
      view: props.data,
      titles,
      mobileMode: this.getInitialMobileMode(),
      visibleDialog: false,
      visibleDialogGroup: false,

      columnTree: [...this.getInitialColumnTree()],
      dialogSettingsTree: [...this.getInitialColumnTree()],
      dialogGroupSettingsTree: this.getGroupTreeByStorage([...this.getInitialColumnTree()]),

      isChildActive: true,
      filterActivity: this.getInitialFilterActivity(),
      selectedID: null,
      aggregates: [],
      selectedItems: [],
      headerSelected: false,
      isShowSpecificFields: false,
      currentStateByDrag: null,
      gridSkip: 0,
      handleExelExportAggregates: null,
      isFilterConstructorOpen: false,
    };
  }

  getGroupTreeByStorage = (tree) => {
    const { name } = this.props;

    const groupTreeByStorage = StorageService.getItem(`groupingState_${name}`);

    if (!groupTreeByStorage || !groupTreeByStorage.length) {
      return tree;
    }

    return (tree || []).map((treeItem) => {
      const itemByStorage = groupTreeByStorage.find(({ key }) => key === treeItem.key);

      return itemByStorage ? { ...treeItem, grouped: true, orderIndex: itemByStorage.orderIndex } : treeItem;
    });
  };

  getInitialFilterActivity = () => {
    if (window.innerWidth > 991) {
      return true;
    } else {
      const storageMode = StorageService.getItem('mobileMode');
      if (storageMode === true) {
        return false;
      } else {
        return true;
      }
    }
  };

  getInitialSort = () => {
    const { initialSort, name, setSortingString, isGQL, normalizeGQLFieldsMap, setFiltersString } = this.props;
    let sort = [];
    if (StorageService.getItem(`fs-${name}`)) {
      const storageSort = StorageService.getItem(`fs-${name}`).sort;
      if (storageSort.length !== 0) {
        sort = storageSort;
        if (isGQL) {
          setSortingString(GqlService.getSortString(storageSort, normalizeGQLFieldsMap));
        }
      }
      if (storageSort.length === 0 && initialSort) {
        sort.push(initialSort);
      }
    }
    if (!StorageService.getItem(`fs-${name}`) && initialSort) {
      sort.push(initialSort);
    }
    return sort;
  };

  // in Future large switch/case
  getInitialFilter = () => {
    let initFilters = [];
    const {
      history: {
        location: { search },
      },
      name,
      initialFilters,
      setFiltersString,
      isGQL,
      normalizeGQLFieldsMap,
    } = this.props;
    if (name === 'monitorActivity' && search !== '') {
      let filter = {
        field: 'StatusState',
        operator: 'eq',
        value: '',
      };
      const val = new URLSearchParams(search).getAll('status')[0];
      switch (val) {
        case 'active':
          filter.value = 'активный';
          break;
        case 'inactive':
          filter.value = 'неактивный';
          break;
        case 'defective':
          filter.value = 'неисправен';
          break;
        case 'disconnected':
          filter.value = 'отключен';
          break;
        case 'arrested':
          filter.value = 'арестован';
          break;
        default:
          filter.value = '';
      }
      if (filter.value !== '') {
        initFilters.push(filter);
      }
    }

    if (search === '' && StorageService.getItem(`fs-${name}`)) {
      initFilters = StorageService.getItem(`fs-${name}`).filter.map((elem) => {
        if (!isNaN(Date.parse(elem.value)) && typeof elem.value !== 'number') {
          elem.value = new Date(elem.value);
        }
        return elem;
      });
      if (isGQL) {
        setFiltersString(
          initFilters && initFilters.length
            ? GqlService.getFilterGroupString({ filters: initFilters }, normalizeGQLFieldsMap)
            : '',
        );
      }
    }

    if (search === '' && !StorageService.getItem(`fs-${name}`) && initialFilters) {
      initFilters = initialFilters;
    }

    if (isGQL) {
      setFiltersString(
        GqlService.getFilterGroupString(
          {
            logic: 'and',
            filters: initFilters,
          },
          normalizeGQLFieldsMap,
        ),
      );
    }

    return initFilters;
  };

  getInitialMobileMode = () => {
    if (window.innerWidth < 991) {
      const storageMode = StorageService.getItem('mobileMode');
      if (storageMode) {
        return storageMode;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getNewChildren = (children, backendGrid) => {
    let newChildren = [];
    for (const child of children) {
      for (const backendGridElem of backendGrid) {
        if (child.props.field === backendGridElem.FieldName) {
          newChildren.push(child);
        }
      }
    }
    return newChildren;
  };

  getFieldsFromBackEndApi = (backConfig, name, permissionName) => {
    let finalArr = [];
    const currentFilteredByName = backConfig.filter((elem) => elem.WebTag === permissionName);
    if (currentFilteredByName.length > 0) {
      finalArr = currentFilteredByName[0].Elements.filter((el) => el.ElementName === name);
      if (finalArr.length > 0) return finalArr[0];
    }
    return finalArr;
  };

  onChangeCheckBoxSpecificFields = () => {
    this.setState(
      ({ isShowSpecificFields }) => {
        return {
          isShowSpecificFields: !isShowSpecificFields,
        };
      },
      () => this.modifyColumnTreeBySpecificFields(this.state.isShowSpecificFields),
    );
  };

  modifyColumnTreeBySpecificFields = (value) => {
    const { columnTree } = this.state;
    let currentColumnTree;
    if (value === false) currentColumnTree = this.modifyFinalColumnTree(columnTree);
    else currentColumnTree = this.updateColumnTreeToAllChecked(columnTree);
    this.setState({ columnTree: currentColumnTree });
  };

  updateColumnTreeToAllChecked = (columnTree) => {
    return columnTree.map((el) => {
      return { ...el, checked: true };
    });
  };

  modifyFinalColumnTree = (columnTree) => {
    const { specificFieldsToHide } = this.props;
    return columnTree.map((el) => {
      if (specificFieldsToHide.indexOf(el.field) !== -1) {
        return {
          ...el,
          checked: false,
        };
      } else return el;
    });
  };

  getInitialColumnTree = () => {
    const { name, children, permissionName } = this.props;
    let columnTree;
    const backConfig = StorageService.getItem('backConfig');
    let currentFilteredByName;
    let currentGrid;
    if (backConfig) {
      currentFilteredByName = this.getFieldsFromBackEndApi(backConfig, name, permissionName);
      // !!! Убрать if когда таблица Админка - Фото терминалов залетит в админку
      if (currentFilteredByName.length === 0 && !availableTablesList.find((tableName) => tableName === name)) {
        return;
      }
      const childrenFields = children.filter(Boolean).map((el) => el.props.field);
      if (currentFilteredByName.Fields !== undefined) {
        const rawCurrentGrid = currentFilteredByName.Fields.filter((el) => childrenFields.includes(el.FieldName));
        currentGrid = getUniqueByFieldName(rawCurrentGrid, 'FieldName');
      }
    }

    // Если нет конфига с бэкэнда
    if ((!backConfig && !currentFilteredByName) || currentFilteredByName.length === 0) {
      if (StorageService.getItem(name) && StorageService.getItem(name).length !== children.length) {
        columnTree = this.buildTree(children);
      }
      if (!StorageService.getItem(name)) {
        columnTree = this.buildTree(children);
      }
      if (StorageService.getItem(name) && StorageService.getItem(name).length === children.length) {
        columnTree = StorageService.getItem(name);
      }
    }

    if (!StorageService.getItem(name) && currentFilteredByName !== undefined && currentGrid && currentGrid.length > 0) {
      const newChildren = this.getNewChildren(children, currentGrid);
      if (children.length !== newChildren.length) {
        columnTree = this.buildTree(children);
      }
    }

    if (StorageService.getItem(name) && currentFilteredByName !== undefined && currentGrid && currentGrid.length > 0) {
      const realStorageTree = StorageService.getItem(name);
      columnTree =
        StorageService.getItem(name).length === currentGrid.length ? realStorageTree : this.buildTree(children);
    }

    // 2) - Если нет объекта с деревом гриды в local storage, но есть настройки с backend для данной гриды
    // в этом случае делаем проходку по children, и выбираем только совпадающие с настройками backend
    else if (StorageService.getItem(name) && StorageService.getItem(name).length === children.length) {
      columnTree = StorageService.getItem(name);
    } else {
      columnTree = this.buildTree(children);
    }

    return this.modifyFinalColumnTree(columnTree);
  };

  cleanAllFilters = () => {
    const { dataState, selectedItems } = this.state;
    const { data, fieldForSelection, isGQL, setFiltersString } = this.props;
    const { filter } = dataState;

    if (isGQL) {
      setFiltersString('');
    }

    this.setState({
      dataState: { ...dataState, filter: { ...filter, filters: [] } },
      view: data.map((item) => ({
        ...item,
        selected: selectedItems.includes(item[fieldForSelection]),
      })),
      headerSelected: false,
    });
  };

  isFilter = () => {
    const {
      dataState: { filter },
    } = this.state;
    if (filter.filters.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  buildTree = (columns, level = 0) =>
    columns
      .filter(Boolean)
      .map((column, i) => {
        const { field } = column.props;
        if (this.secureFilter(field)) return null;

        // const { translate } = this.props;
        const { title, children, width, filter } = column.props;
        let item = {
          text: title,
          key: buildKey(level, i, field),
          checked: true,
          orderIndex: 0,
          width: width,
          field,
          locked: false,
          filter,
        };

        if (children) {
          item.items = this.buildTree(children, level + 1);
          item.expanded = true;
        }

        return item;
      })
      .filter((column) => column !== null);

  isVisibleColumn = (key, items) => {
    for (const item of items) {
      if (item.key === key) {
        return item.checked;
      }

      // if (item.items) {
      //   const ret = this.isVisibleColumn(key, item.items);
      //   if (ret !== null)
      //     return ret;
      // }
    }

    return null;
  };

  buildColumnCollection = ({ children }) => {
    let titles = [];
    const buildColumns = ({ children }) => {
      if (children === undefined) return;

      children.map((item) => {
        if (item.type !== GridColumn) return [];
        // return null;

        const { title, field, ...props } = item.props;
        titles.push({
          title: translate(title),
          field,
          props,
        });

        if (item.props.children) buildColumns(item.props);

        return [];
      });
    };

    const { translate } = this.props;

    buildColumns({ children });
    return titles;
  };

  toggleDialog = () => {
    this.setState(({ visibleDialog, dialogSettingsTree, columnTree }) => {
      return {
        visibleDialog: !visibleDialog,
        dialogSettingsTree: columnTree,
      };
    });
  };

  toggleGroupDialog = () => {
    this.setState(({ visibleDialogGroup, columnTree }) => {
      return {
        visibleDialogGroup: !visibleDialogGroup,
      };
    });
  };

  toggleCloseGroupDialog = () => {
    this.setState(
      ({ visibleDialogGroup, columnTree }) => {
        return {
          visibleDialogGroup: !visibleDialogGroup,
          dialogGroupSettingsTree: columnTree,
        };
      },
      () => {
        StorageService.removeItem(`groupingState_${this.props.name}`);
      },
    );
  };

  onTreeOk = async () => {
    const { name, onFieldsConfigChange } = this.props;
    const { columnTree, dialogSettingsTree } = this.state;

    await this.setState({ columnTree: dialogSettingsTree });
    await StorageService.setItem(name, dialogSettingsTree);
    await this.toggleDialog();

    if (getIsFunction(onFieldsConfigChange)) {
      await onFieldsConfigChange(dialogSettingsTree.filter(({ checked }) => checked).map(({ field }) => field));
    }
  };

  onTreeOkGroup = async () => {
    this.setState(
      ({ visibleDialogGroup }) => {
        return {
          visibleDialogGroup: !visibleDialogGroup,
        };
      },
      () => {
        StorageService.setItem(
          `groupingState_${this.props.name}`,
          this.state.dialogGroupSettingsTree.filter(({ grouped }) => grouped),
        );
      },
    );
  };

  filterTreeViewBySpecificFields = (el) => {
    const { isShowSpecificFields } = this.state;
    const { specificFieldsToHide } = this.props;
    if (isShowSpecificFields === true) return true;
    else if (specificFieldsToHide.indexOf(el.field) !== -1) return false;
    else return true;
  };

  onDragEnd = ({ newState }) => {
    this.setState(({ dialogSettingsTree }) => {
      return {
        dialogSettingsTree: dialogSettingsTree.map((colTreeElem) => {
          let orderIndex;
          if (colTreeElem.checked === false) {
            orderIndex = null;
          }
          orderIndex = newState.findIndex((el) => colTreeElem.field === el.field);
          return { ...colTreeElem, orderIndex };
        }),
      };
    });
  };

  onNavigate = (event) => {
    this.setState({
      columnTree: event.newState,
    });
  };

  onDragEndGroup = ({ newState }) => {
    this.setState(({ dialogGroupSettingsTree }) => {
      return {
        dialogGroupSettingsTree: dialogGroupSettingsTree.map((colTreeElem) => {
          const orderIndex = newState.findIndex((el) => colTreeElem.field === el.field);
          return { ...colTreeElem, orderIndex };
        }),
      };
    });
  };

  // onNavigateGroup = (event) => {
  //   this.setState({
  //     columnTree: event.newState,
  //   });
  // };

  renderPopupDialog = () => {
    const { dialogSettingsTree } = this.state;
    const { translate } = this.props;
    // for (const colTreeElem of columnTree) {
    //   colTreeElem.text = translate(colTreeElem.text);
    // }

    const data = dialogSettingsTree
      .map((el) => {
        return { ...el, text: translate(el.text) };
      })
      .filter((el) => this.filterTreeViewBySpecificFields(el))
      .sort(sortFunc);

    return (
      <Dialog
        title={translate('base-grid.choose-columns')}
        onClose={this.toggleDialog}
        // width="400px"
      >
        <Sortable
          idField={'field'}
          data={data}
          itemUI={(props) => (
            <SortableItemUI
              checked={props.dataItem.checked}
              onSwitchChange={() => this.onSwitchChange(props.dataItem)}
              onCheckChange={() => this.onCheckChange(props.dataItem)}
              {...props}
              // onDragEnd={this.onDragEnd}
            />
          )}
          onDragEnd={this.onDragEnd}
          onNavigate={this.onNavigate}
        />
        <DialogActionsBar>
          <button className="k-button k-primary" onClick={this.onTreeOk}>
            Ok
          </button>
          <button className="k-button k-primary" onClick={this.toggleDialog}>
            {translate('base-grid.rebuild')}
          </button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  onSwitchChangeGroup = (item) => {
    this.setState(({ dialogGroupSettingsTree }) => {
      const lockedOrder = dialogGroupSettingsTree.filter((item) => item.grouped).length;

      return {
        dialogGroupSettingsTree: dialogGroupSettingsTree.map((el) => {
          const orderIndex = item.key === el.key ? lockedOrder : el.orderIndex;
          return { ...el, grouped: item.key === el.key ? !el.grouped : el.grouped, orderIndex };
        }),
      };
    });
  };

  componentDidMount() {
    const { defaultSelectItems } = this.props;
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

    if (defaultSelectItems) {
      this.setState({ selectedItems: defaultSelectItems });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDimensions();
    // locale
    const {
      data,
      name,
      onSelect,
      fieldForSelection,
      multiSelected,
      defaultSelectItems,
      updateDefaultSelectItemsCallback,
    } = this.props;
    const { dataState, headerSelected, selectedItems, view } = this.state;
    const { dataState: prevDataState, selectedItems: prevSelectedItems } = prevState;
    const { defaultSelectItems: prevDefaultSelectItems } = prevProps;

    if (data !== prevProps.data) {
      this.setState({ selectedItems: [], gridSkip: 0 }, () => {
        StorageService.removeItem(`selectionListForGrid-${name}`);
        this.updateData(data);
        this.updateAggregate();
      });
    }

    // new rule for dynamic update aggregation if datastate was changed
    if (dataState !== prevDataState) {
      this.updateAggregate();
    }

    if (prevDataState && dataState.filter.filters.length > prevDataState.filter.filters.length && headerSelected) {
      this.setState(
        ({ view }) => ({
          headerSelected: false,
          selectedItems: [],
          view: view.map((item) => ({ ...item, selected: false })),
        }),
        () => {
          this.setState(({ view }) => ({
            view: view.map((item) => ({ ...item, selected: false })),
          }));
        },
      );
    }

    // if (isGQLDefaultItemsUpdateFlag && isGQL) {
    //   resetIsGQLDefaultItemsUpdateFlag();
    //   this.setState({ selectedItems: defaultSelectItems });
    // }

    if (selectedItems.length !== prevSelectedItems.length) {
      if (onSelect) {
        onSelect(view.filter((item) => selectedItems.includes(item[fieldForSelection])) || []);
      }
    }

    if (
      !multiSelected &&
      selectedItems.length &&
      prevSelectedItems.length &&
      selectedItems[0] !== prevSelectedItems[0]
    ) {
      if (onSelect) {
        onSelect(view.filter((item) => selectedItems.includes(item[fieldForSelection])) || []);
      }
    }

    const isUpdateByDefaultSelectItems =
      updateDefaultSelectItemsCallback &&
      updateDefaultSelectItemsCallback({ defaultSelectItems, prevDefaultSelectItems, selectedItems });

    if (isUpdateByDefaultSelectItems) {
      this.setState({ selectedItems: defaultSelectItems });
    }
  }

  updateDimensions = () => {
    if (this.state.breakpoint !== window.innerWidth) {
      this.setState({ breakpoint: window.innerWidth });
    }
    if (this.grid.current) {
      const onResize = this.grid.current.onResize;
      onResize();
    }
  };

  calcAggregateByData = (data) => {
    const { aggregate } = this.props;

    const aggregatesByData = Object.entries(aggregateBy(data, aggregate)).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: { ...value, countCallback: aggregate.find(({ field }) => field === key).countCallback },
      }),
      {},
    );

    if (!data.length) {
      return Object.keys(aggregatesByData).reduce((acc, key) => ({ ...acc, [key]: 0 }), {});
    }

    return Object.entries(aggregatesByData).reduce((acc, [key, value]) => {
      const resultValue = value.countCallback ? value.countCallback(aggregatesByData) : Object.values(value)[0];
      const resultValueByFormat = formatNumber(resultValue, aggregate.find(({ field }) => field === key).format);

      return { ...acc, [key]: resultValueByFormat };
    }, {});
  };

  updateBySortAndFilter = (data, dataState) =>
    this.props.isGQL ? data : filterBy(orderBy(data, dataState.sort), dataState.filter);

  updateAggregate = () => {
    const { aggregate, data } = this.props;
    const { dataState } = this.state;
    if (!aggregate) return;

    const aggregates = this.calcAggregateByData(this.updateBySortAndFilter(data, dataState));

    this.setState({ aggregates });
  };

  updateData = (data) => {
    const { fieldForSelection } = this.props;
    const { titles, dataState, selectedItems } = this.state;

    const fields = getFieldsByType(titles, 'date');
    const view = Array.from(data).map((item) => {
      for (const field of fields) {
        const dt = item[field];
        item[field] = new Date(dt);
      }
      return item;
    });

    this.setState(
      {
        view: this.updateBySortAndFilter(view, dataState).map((item) => ({
          ...item,
          selected: !!selectedItems.includes(item[fieldForSelection]),
        })),
      },
      () => {
        const { view } = this.state;
        const viewIds = view.map((item) => item[fieldForSelection]);
        this.setState(({ selectedItems }) => ({
          selectedItems: selectedItems.filter((item) => viewIds.includes(item)),
        }));
        this.checkHeaderSelected();
      },
    );
  };

  onDataState = ({ data }) => {
    const serverData = this.props.data;

    this.setState({ view: this.updateBySortAndFilter(serverData, data), dataState: data });
  };

  footerCell = (field) => {
    const { aggregates } = this.state;
    if (!aggregates) return null;
    if (!field) return null;

    const value = aggregates[field];
    if (value === undefined) return null;
    // возвращаем функцию которая строит footer
    return (props) => <CellAggregate value={value} {...props} />;
  };

  footerCellExcel = (field) => {
    const { aggregates, handleExelExportAggregates } = this.state;
    const { getHandleExelExportDate } = this.props;

    if (!aggregates || (getHandleExelExportDate && !handleExelExportAggregates)) return null;
    if (!field) return null;

    const value = getHandleExelExportDate ? handleExelExportAggregates[field] : aggregates[field];

    if (value === undefined) return null;

    // возвращаем функцию которая строит footer
    return (props) => <CellAggregateExcel value={value} {...props} />;
  };

  gridCell = (props) => {
    const { permissionName } = this.props;
    const { onClick, filter, dropdown, handlers, field, remoteCommands, groupDropdown, groupList } = props;
    const { name } = this.props;
    // const { remoteCommands } = this.props;
    // todo add number and other items

    if (name === 'monitorActivity' && field === 'DateLastTransaction') {
      return (props) => <CellLastTransaction {...props} />;
    }

    if (
      filter === 'date'
      // && props.dataItem && props.dataItem[props.field]
    ) {
      return (props) => <CellDate onClick={onClick} {...props} />;
    }

    if (dropdown === true) {
      return (props) => (
        <CellDropdown
          remoteCommands={remoteCommands}
          handlers={handlers}
          permissionName={permissionName}
          gridName={name}
          {...props}
        />
      );
    }

    if (!onClick) return null;

    return (props) => <CellLink onClick={onClick} {...props} />;
  };

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  };

  // TODO rewrite for full secure filter
  secureFilter = (field) => {
    const { name, permissionName } = this.props;
    const backConfig = StorageService.getItem('backConfig');
    if (!backConfig || backConfig === undefined) return false;
    const currentFilteredByName = this.getFieldsFromBackEndApi(backConfig, name, permissionName);
    if (!currentFilteredByName) return false;
    const currentGrid = currentFilteredByName.Fields;
    if (currentGrid !== undefined && currentGrid.length > 0) {
      const findElem = currentGrid.find((elem) => String(elem.FieldName).trim() === String(field).trim());
      if (findElem === undefined) return true;
      return false;
    }
    return false;
  };

  renderExcelColumns = (columns, level = 0) => {
    const { columnTree } = this.state;
    const { translate, name, withoutManageColumnAction } = this.props;
    return columns
      .filter(Boolean)
      .filter((column, index) => {
        const { field } = column.props;
        const key = buildKey(level, index, field);

        return (
          column.props.field !== 'selected' &&
          (!columnTree || !columnTree.length > 0 || this.isVisibleColumn(key, columnTree) || withoutManageColumnAction)
        );
      })
      .map((column) => {
        // пропускаем елемент если не столбик
        if (column.type !== GridColumn) return column;

        // проверка на необходимость отображения столбца,
        // согласно настроек на бекенде
        const { field } = column.props;
        if (this.secureFilter(field)) return null;

        const { children, title, width, ...props } = column.props;

        let ExcelColumn = ExcelExportColumn;
        if (!field) {
          ExcelColumn = ExcelExportColumnGroup;
        }
        return (
          <ExcelColumn
            {...props}
            title={translate(title)}
            //Експорт таблиці не сприймає назви колонок з "."(наприклад Merchant.OrderId), цей хак призначений для фіксу цього багу
            field={field.includes('.') ? `['${field}']` : field}
            width={Number(width)}
            key={`grid-${name}-excel-export-column-${title}`}
            footer={this.footerCellExcel(field)}
            footerCellOptions={{ bold: true }}
          >
            {children && this.renderExcelColumns(children, level + 1)}
          </ExcelColumn>
        );
      });
  };

  renderExcel = () => {
    const { exportExcel, data, name, children } = this.props;

    if (!exportExcel) return;

    return (
      <ExcelExport data={data} fileName={`${name}.xlsx`} ref={this.exporter}>
        {this.renderExcelColumns(children)}
      </ExcelExport>
    );
  };

  onExport = async () => {
    const { data, getHandleExelExportDate, nonGqlExportFormatter } = this.props;
    const { dataState } = this.state;

    if (getHandleExelExportDate) {
      const handleExelExportDate = await getHandleExelExportDate();
      const aggregatesByExelData = this.calcAggregateByData(handleExelExportDate);

      this.setState(
        {
          handleExelExportAggregates: aggregatesByExelData,
        },
        () => {
          this.exporter.current.save(handleExelExportDate);
        },
      );

      return;
    }

    this.exporter.current.save(
      this.updateBySortAndFilter(nonGqlExportFormatter ? nonGqlExportFormatter(data) : data, dataState),
    );
  };

  triggerFilters = () => {
    this.setState(({ filterActivity }) => {
      return {
        filterActivity: !filterActivity,
      };
    });
  };

  renderHeaderClassName = (field, dataState) => {
    const { filterActivity, mobileMode } = this.state;
    let classList = '';
    if (this.isColumnActive(field, dataState) || this.checkCustomFilterActivity(field, dataState))
      classList += ' active';
    if (mobileMode && filterActivity) classList += ' mobile-visible';
    return classList;
  };

  renderGridColumns = (columns, level = 0) => {
    const { translate, isGQL, allowWordWrap, withoutManageColumnAction, isGroup } = this.props;
    const { columnTree, dataState, filterActivity, view, headerSelected } = this.state;

    return columns
      .map((column, i) => {
        // пропускаем елемент если не столбик
        if (column.type !== GridColumn) return column;

        // проверка на необходимость отображения столбца,
        // согласно настроек на бекенде
        const { field } = column.props;
        if (this.secureFilter(field)) return null;

        // создаем уникальный ключ столбца
        const key = buildKey(level, i, field);

        const isVisible = columnTree && columnTree.length > 0 ? this.isVisibleColumn(key, columnTree) : null;
        if (!isVisible && !withoutManageColumnAction) return null;

        // разделяем свойства столбца и его детей,
        // для возможности независимого рендеринга
        const {
          children,
          title,
          locked,
          showAllSelected,
          headerSelectionValue,
          isPhotoCell,
          isTooltip,
          warningInfo,
          ...props
        } = column.props;

        // рекурсивный рендеринг столбцов
        return (
          <GridColumn
            // columnMenu={this.gridFilter(field)}
            columnMenu={(props) => (
              <>
                {filterActivity ? (
                  <FilterScroll
                    {...props}
                    data={view}
                    field={field}
                    format={column.props.format && column.props.format}
                    formatCellData={column.props.formatFilterCellData}
                    isGQL={isGQL}
                  />
                ) : null}
              </>
            )}
            footerCell={this.footerCell(field)}
            cell={this.gridCell(props)}
            {...props}
            title={translate(title)}
            key={key}
            orderIndex={this.getOrder(key)}
            width={this.getWidth(key) || props.width || '180'}
            headerSelectionValue={headerSelectionValue}
            headerClassName={this.renderHeaderClassName(field, dataState)}
            headerCell={(props) => {
              return (
                <>
                  {filterActivity ? (
                    <BaseHeaderCell
                      headerSelected={headerSelected}
                      onChangeCallback={this.onChangeCallback}
                      showAllSelected={showAllSelected}
                      filterActivity={filterActivity}
                      warningInfo={warningInfo}
                      allowWordWrap={allowWordWrap}
                      {...props}
                    />
                  ) : null}
                </>
              );
            }}
            locked={this.getLock(key)}
            isPhotoCell={isPhotoCell}
            isTooltip={isTooltip}
          >
            {children && this.renderGridColumns(children, level + 1)}
          </GridColumn>
        );
      })
      .filter((column) => column !== null);
  };

  isColumnActive(field, dataState) {
    return JSON.stringify(dataState.filter).includes(`"field":"${field}"`);
  }

  checkCustomFilterActivity = (field, dataState) => {
    if (!dataState.filter || dataState.filter.filters.length < 1) return;
    for (const elem of dataState.filter.filters) {
      elem.active = this.isFilterActive(field, elem);
      if (dataState.filter.filters.length > 1) {
        dataState.filter.filters.forEach((elem) => {
          if (dataState.filter.filters.find((el) => el.field === field)) {
            elem.active = true;
          }
        });
      }
      return elem.active;
    }
  };

  isFilterActive = (field, elem) => {
    if (elem.field === field) {
      return true;
    }
    return false;
  };

  getOrder = (key) => {
    let findOrder;
    const { columnTree } = this.state;
    columnTree.map((col) => {
      if (col.key === key) {
        findOrder = col.orderIndex;
      } else {
        if (col.items) {
          for (const child of col.items) {
            if (child.key === key) {
              findOrder = child.orderIndex;
            }
          }
        }
      }
      return findOrder;
    });
    return findOrder;
  };

  getWidth = (key) => {
    let findWidth;
    const { columnTree } = this.state;
    columnTree.map((col) => {
      if (col.key === key) {
        findWidth = col.width;
      }
      return findWidth;
    });
    return findWidth;
  };

  getLock = (key) => {
    let findLock;
    const { columnTree } = this.state;
    columnTree.map((col) => {
      if (col.key === key) {
        findLock = col.locked;
      }
      if (col.items) {
        for (const child of col.items) {
          if (child.key === key) {
            findLock = child.locked;
          }
        }
      }
      return findLock;
    });
    return findLock;
  };

  onSwitchChange = (item) => {
    this.setState(({ dialogSettingsTree, columnTree }) => {
      return {
        dialogSettingsTree: dialogSettingsTree.map((el) => {
          return { ...el, locked: item.key === el.key ? !el.locked : el.locked };
        }),
      };
    });
  };

  onCheckChange = (item) => {
    this.setState(({ dialogSettingsTree }) => ({
      dialogSettingsTree: dialogSettingsTree.map((el) => (item.key === el.key ? { ...el, checked: !el.checked } : el)),
    }));
  };

  injectKeys = (columns, level = 0) => {
    return columns.map((column, i) => {
      let { field, children } = column;
      const key = buildKey(level, i, field);
      if (children) {
        children = this.injectKeys(children, level + 1);
      }
      column.key = key;
      return column;
    });
  };

  onColumnReorder = (e) => {
    const { columnTree } = this.state;
    const { name } = this.props;
    const eventColsModify = this.injectKeys(e.columns);
    for (const colTreeElem of columnTree) {
      for (const eventColumn of eventColsModify) {
        //FIELD for secure filter
        //for prod now field=key
        if (colTreeElem.field === eventColumn.field) {
          if (colTreeElem.checked === false) {
            colTreeElem.orderIndex = null;
          }
          colTreeElem.orderIndex = eventColumn.orderIndex;
        }
      }
    }

    this.setState({ columnTree: [...columnTree] }, () => {
      StorageService.setItem(name, columnTree);
    });
  };

  onColumnResize = (e) => {
    const { columnTree } = this.state;
    const { name } = this.props;
    if (e.end) {
      const eventColsModify = this.injectKeys(e.columns);
      for (const colTreeElem of columnTree) {
        for (const eventColumn of eventColsModify) {
          // for prod now field=key
          if (colTreeElem.field === eventColumn.field) {
            colTreeElem.width = eventColumn.width;
          }
        }
      }
      this.forceUpdate();
      this.setState({ columnTree: [...columnTree] }, () => StorageService.setItem(name, columnTree));
      this.forceUpdate();
    }
  };

  onSortChange = (e) => {
    const { dataState } = this.state;
    const { data, name, setSortingString, isGQL, normalizeGQLFieldsMap } = this.props;
    const sort = e.sort;
    this.setState({ dataState: { ...dataState, sort } }, () => {
      if (isGQL) {
        setSortingString(GqlService.getSortString(sort, normalizeGQLFieldsMap));
      } else {
        this.updateData(data);
      }
      const obj = {
        sort,
        filter: dataState.filter.filters,
      };
      StorageService.setItem(`fs-${name}`, obj);
    });
  };

  onFilterChange = ({ filter }) => {
    const { dataState } = this.state;
    const { data, name, isGQL, setFiltersString, normalizeGQLFieldsMap } = this.props;
    const filterState = { ...filter, filters: this.getInitialFilterArray(filter ? filter.filters : []) };

    this.setState({ dataState: { ...dataState, filter: filterState } }, () => {
      if (isGQL) {
        setFiltersString(GqlService.getFilterGroupString(filterState, normalizeGQLFieldsMap));
        this.updateAggregate();
      } else {
        this.updateData(data);
      }
      const obj = {
        sort: dataState.sort,
        filter: this.getInitialFilterArray(filter ? filter.filters : []),
      };

      StorageService.setItem(`fs-${name}`, obj);
    });
  };

  getInitialFilterArray = (filters) =>
    filters && filters.length ? filters.filter(({ filters, operator }) => (filters && filters.length) || operator) : [];

  getSelectedItemsByDataItem = (dataItem, selectedItems, fieldForSelection) => {
    const isSelected = selectedItems.includes(dataItem[fieldForSelection]);
    return [
      ...new Set(
        isSelected
          ? selectedItems.filter((el) => el !== dataItem[fieldForSelection])
          : [...selectedItems, dataItem[fieldForSelection]],
      ),
    ];
  };

  onSingleRowClick = (event, subProps = undefined) => {
    const { fieldForSelection } = this.props;

    if (!fieldForSelection) {
      return;
    }

    const currentDataItem = event.dataItem || subProps.dataItem;

    if (!currentDataItem) {
      return;
    }

    this.setState({
      selectedItems: currentDataItem && !currentDataItem.selected ? [currentDataItem[fieldForSelection]] : [],
    });
  };

  onRowClickMulti = (event, subProps = undefined) => {
    const { name, fieldForSelection } = this.props;

    const currentDataItem = event.dataItem || subProps.dataItem;

    if (!currentDataItem) {
      return;
    }

    this.setState(
      ({ selectedItems }) => ({
        selectedItems: this.getSelectedItemsByDataItem(currentDataItem, selectedItems, fieldForSelection),
      }),
      () => {
        this.checkHeaderSelected();
      },
    );

    this.forceUpdate();
  };

  headerClickHandler = () => {
    const { fieldForSelection, name } = this.props;

    this.setState(({ view, headerSelected }) => {
      return {
        view: view.map((item) => ({ ...item, selected: headerSelected })),
        selectedItems: headerSelected ? view.map((el) => el[fieldForSelection]) : [],
      };
    });

    this.forceUpdate();
  };

  checkHeaderSelected = () => {
    const { fieldForSelection } = this.props;

    this.setState(({ view, selectedItems }) => {
      if (view.length > selectedItems.length) {
        return { headerSelected: false };
      }

      const isHeaderSelectedActive = view.every((currentItem) =>
        selectedItems.includes(currentItem[fieldForSelection]),
      );

      return { headerSelected: isHeaderSelectedActive };
    });
  };

  onChangeCallback = () => {
    this.setState(({ headerSelected }) => {
      return {
        headerSelected: !headerSelected,
      };
    }, this.headerClickHandler);
  };

  getDataBySelected = (view) => {
    const { selectedItems, columnTree } = this.state;
    const { fieldForSelection } = this.props;

    if (!columnTree || !columnTree.length || !view || !view.length) {
      return [];
    }

    return view.map((item) => ({
      ...item,
      selected: selectedItems.includes(item[fieldForSelection]),
      checked: selectedItems.includes(item[fieldForSelection]),
    }));
  };

  renderGrid = () => {
    const {
      dataState,
      view,
      breakpoint,
      titles,
      mobileMode,
      columnTree,
      isLoading,
      selectedItems,
      filterActivity,
      isShowSpecificFields,
      gridSkip,
      visibleDialog,
    } = this.state;

    const {
      children,
      toolbar,
      name,
      className,
      onRefresh,
      toggleLoader,
      multiSelected,
      fieldForSelection,
      specificFieldsToHide,
      specificCheckBoxTitle,
      translate,
      isRemoteShow,
      showRemoteCallback,
      isShowSpecificFilterByFieldName,
      handleRowAmountChange,
      pageChange,
      pageValue,
      hasNextPage,
      hasPreviousPage,
      isGQL,
      showRemoteLabel,
      withOutBaseButtons,
      withoutManageColumnAction,
      filterConstructorStaff,
      isGroup = false,
      ...props
    } = this.props;

    let Grid = VirtualMobileGrid;
    if (breakpoint >= baseBreakpoint) {
      Grid = VirtualDesktopGrid;
    }

    return visibleDialog ? (
      <Toolbar
        name={name}
        selectedItem={selectedItems}
        onRefresh={onRefresh}
        cleanSelected={this.cleanselectedItems}
        onExport={this.onExport}
        toggleDialog={this.toggleDialog}
        clearFilters={this.cleanAllFilters}
        isFilter={this.isFilter}
        triggerFilters={this.triggerFilters}
        switchMode={this.switchGridMode}
        gridmode={mobileMode}
        dataState={dataState}
        specificFieldsToHide={specificFieldsToHide}
        onChangeCheckBoxSpecificFields={this.onChangeCheckBoxSpecificFields}
        isShowSpecificFields={isShowSpecificFields}
        specificCheckBoxTitle={specificCheckBoxTitle}
        translate={translate}
        isRemoteShow={isRemoteShow}
        showRemoteCallback={showRemoteCallback}
        isShowSpecificFilterByFieldName={isShowSpecificFilterByFieldName}
        showRemoteLabel={showRemoteLabel}
        updateData={this.updateData}
        updateAggregate={this.updateAggregate}
        onChangeViaSpecificFilterByFieldName={this.onChangeViaSpecificFilterByFieldName}
        onClearViaSpecificFilterFieldName={this.onClearViaSpecificFilterFieldName}
        serverData={this.props.data}
        data={view}
        handleRowAmountChange={handleRowAmountChange}
        pageChange={pageChange}
        pageValue={pageValue}
        isGQL={isGQL}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        withOutBaseButtons={withOutBaseButtons}
        withoutManageColumnAction={withoutManageColumnAction}
        isGroup={isGroup}
      >
        {toolbar}
      </Toolbar>
    ) : (
      <>
        <Grid
          {...props}
          gridSkip={gridSkip}
          handleGridSkipChange={(gridSkip) => {
            this.setState({ gridSkip });
          }}
          ref={this.grid}
          sortable
          resizable
          titles={titles}
          sort={dataState.sort}
          filter={dataState.filter}
          onDataStateChange={this.onDataState}
          onRowClick={multiSelected ? this.onRowClickMulti : this.onSingleRowClick}
          className={`base-grid ${className || ''}`}
          data={this.getDataBySelected(view)}
          onColumnReorder={this.onColumnReorder}
          selectedField="selected"
          onColumnResize={this.onColumnResize}
          onSelectionChange={multiSelected ? this.onRowClickMulti : this.onSingleRowClick}
          onHeaderSelectionChange={this.headerClickHandler}
          onSortChange={this.onSortChange}
          onFilterChange={this.onFilterChange}
          mobileMode={mobileMode}
          isLoading={isLoading}
          triggerFilters={this.triggerFilters}
          mobileFilterActivity={filterActivity}
          toggleLoader={toggleLoader}
          selectedItem={selectedItems}
          columnTree={columnTree}
          fieldForSelect={fieldForSelection}
          gridName={name}
          isGroup={isGroup}
          groupTree={this.state.dialogGroupSettingsTree}
        >
          {toolbar && (
            <Toolbar
              name={name}
              selectedItem={selectedItems}
              onRefresh={onRefresh}
              cleanSelected={this.cleanselectedItems}
              onExport={this.onExport}
              toggleDialog={this.toggleDialog}
              toggleGroupDialog={this.toggleGroupDialog}
              clearFilters={this.cleanAllFilters}
              isFilter={this.isFilter}
              triggerFilters={this.triggerFilters}
              switchMode={this.switchGridMode}
              gridmode={mobileMode}
              dataState={dataState}
              specificFieldsToHide={specificFieldsToHide}
              onChangeCheckBoxSpecificFields={this.onChangeCheckBoxSpecificFields}
              isShowSpecificFields={isShowSpecificFields}
              specificCheckBoxTitle={specificCheckBoxTitle}
              translate={translate}
              isRemoteShow={isRemoteShow}
              showRemoteCallback={showRemoteCallback}
              isShowSpecificFilterByFieldName={isShowSpecificFilterByFieldName}
              showRemoteLabel={showRemoteLabel}
              updateData={this.updateData}
              updateAggregate={this.updateAggregate}
              onChangeViaSpecificFilterByFieldName={this.onChangeViaSpecificFilterByFieldName}
              onClearViaSpecificFilterFieldName={this.onClearViaSpecificFilterFieldName}
              serverData={this.props.data}
              data={view}
              handleRowAmountChange={handleRowAmountChange}
              pageChange={pageChange}
              pageValue={pageValue}
              isGQL={isGQL}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              withOutBaseButtons={withOutBaseButtons}
              withoutManageColumnAction={withoutManageColumnAction}
              onFilterConstructorOpen={
                filterConstructorStaff
                  ? () => {
                      this.setState({ isFilterConstructorOpen: true });
                    }
                  : undefined
              }
              isGroup={isGroup}
            >
              {toolbar}
            </Toolbar>
          )}
          {this.renderGridColumns(children)}
          <EmptyData />
        </Grid>

        {isGQL && breakpoint < baseBreakpoint && (
          <PagePanel
            hasPreviousPage={hasPreviousPage}
            pageChange={pageChange}
            pageValue={pageValue}
            hasNextPage={hasNextPage}
            isPlacedBottom
          />
        )}
      </>
    );
  };

  switchGridMode = () => {
    let mob;
    this.setState(
      ({ mobileMode }) => {
        mob = !mobileMode;
        return {
          mobileMode: !mobileMode,
        };
      },
      () => StorageService.setItem('mobileMode', mob),
    );
  };

  setGridMode = (value) => {
    StorageService.setItem('mobileMode', value);
  };

  cleanselectedItems = () => {
    const { name } = this.props;
    this.setState({ selectedItems: [] }, () => StorageService.removeItem(`selectionListForGrid-${name}`));
  };

  onChangeViaSpecificFilterByFieldName = (inputValue, fieldName) => {
    const { view } = this.state;
    const { isGQL, onSpecificFilterByFieldNameSubmit } = this.props;

    if (!inputValue) {
      return;
    }

    const splittedInputValue = inputValue.split(/[ .,]+/);

    if (onSpecificFilterByFieldNameSubmit) {
      onSpecificFilterByFieldNameSubmit(splittedInputValue.join(','));
      return;
    }

    this.setState({ gridSkip: 0 });

    const newView = new Set();
    for (const elem of view) {
      for (const splitElem of splittedInputValue) {
        if (elem[fieldName] === Number(splitElem)) {
          newView.add(elem);
        }
      }
    }

    this.updateData([...newView]);
    this.updateAggregate();
  };

  onClearViaSpecificFilterFieldName = () => {
    const { data } = this.props;
    const { isGQL, onSpecificFilterByFieldNameSubmit } = this.props;

    if (onSpecificFilterByFieldNameSubmit) {
      onSpecificFilterByFieldNameSubmit();
    }

    this.updateData(data);
    this.updateAggregate();
  };

  render() {
    const {
      visibleDialog,
      visibleDialogGroup,
      mobileMode,
      isFilterConstructorOpen,
      dataState: { filter },
    } = this.state;
    const { isLoading, filterConstructorStaff, name, isGQL } = this.props;

    return (
      <>
        {this.renderExcel()}
        {this.renderGrid()}
        {!!isLoading && <LoadingPanel mobileMode={mobileMode} />}
        {!!visibleDialog && this.renderPopupDialog()}
        {!!visibleDialogGroup && (
          <PopupGroupDialog
            toggleCloseGroupDialog={this.toggleCloseGroupDialog}
            dialogGroupSettingsTree={this.state.dialogGroupSettingsTree}
            onSwitchChangeGroup={this.onSwitchChangeGroup}
            onTreeOkGroup={this.onTreeOkGroup}
            filterTreeViewBySpecificFields={this.filterTreeViewBySpecificFields}
            onDragEndGroup={this.onDragEndGroup}
          />
        )}

        {isFilterConstructorOpen && filterConstructorStaff && (
          <FilterConstructorModal
            fieldsInfo={filterConstructorStaff.fieldsInfo}
            open={isFilterConstructorOpen}
            onClose={() => {
              this.setState({ isFilterConstructorOpen: false });
            }}
            filters={filter}
            isGQL={isGQL}
            onSave={(filters) => {
              this.setState(
                ({ dataState }) => ({
                  dataState: { ...dataState, filter: filters },
                  isFilterConstructorOpen: false,
                }),
                () => {
                  this.onFilterChange({ filter: filters });
                },
              );
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(withTranslate(VirtualBaseGrid));
