import React, { PureComponent } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import { ApiService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import DropdownNormalizersServiceMFO from '../../helper';
import { MFO_TYPES_CONFIG } from '../constants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getCustomDate } from '../../../../utils/getCustomDate';
import { formatDate } from '@telerik/kendo-intl';


const convertToISOFormat = (date) => formatDate(date, 'dd.MM.yyyy');

const api = new ApiService();

class PaymentTypeModal extends PureComponent {
  state = {
    isLoading: false,
    ownersMfoPaymentTypesList: [],
    ownersMfoPaymentType: [],
    dateStart: getCustomDate({ customDayOfMonth: new Date().getDate() + 1 }),
  };

  componentDidMount() {
    this.fetchOwnersMfoPaymentTypes();
  }

  addNewItem = async () => {
    const { ownerId, ownersMfoPropertiesId } = this.props.selectedItems[0];
    const { ownersMfoPaymentType, dateStart } = this.state;
    const { closeModal, translate, onRefresh } = this.props;

    try {
      await this.setState({ isLoading: true });

      const data = await api.ownersMfoPropertiesUpdate({
        ownersMfoPropertiesId: ownersMfoPropertiesId,
        ownerId: ownerId,
        ownersMfoPaymentTypeId: ownersMfoPaymentType[0],
        dateStart: convertToISOFormat(dateStart),
      });
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }
      message.success(translate('page.MFODealer.choseCalculationTypeChangeSuccessful'), 2.5);

      await this.setState({
        ownersMfoPaymentTypesList: data,
        isLoading: false,
      });
      onRefresh();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
      closeModal();
    }
  };

  fetchOwnersMfoPaymentTypes = async () => {
    try {
      await this.setState({ isLoading: true });

      const data = await api.ownersMfoPaymentTypes();
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        ownersMfoPaymentTypesList: data,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onTypes = (ownersMfoPaymentType) => {
    this.setState({ ownersMfoPaymentType });
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading, ownersMfoPaymentTypesList, ownersMfoPaymentType, dateStart } = this.state;
    const disable = !dateStart || !ownersMfoPaymentType.length;

    return (
      <Modal
        title={translate('page.MFODealer.changeCalculationType')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          <>
            <Button key="submit" type="primary" disabled={disable} onClick={this.addNewItem}>
              {translate('core.buttonTitles.change')}
            </Button>
            <Button key="back" onClick={closeModal}>
              {translate('owner.cancel')}
            </Button>
          </>,
        ]}
      >
        <Row type="flex" align="middle" justify="space-between" style={{ gap: '20px' }}>
          {!!ownersMfoPaymentTypesList.length && (
            <GridDropdown
              data={DropdownNormalizersServiceMFO.normalizeTypes(ownersMfoPaymentTypesList)}
              colConfig={MFO_TYPES_CONFIG}
              selectItems={ownersMfoPaymentType}
              onSave={this.onTypes}
              maximumNumberSelected={1}
              isSingle
              defaultTitle={translate('page.MFODealer.choseCalculationType')}
            />
          )}

          <Row type="flex" align="middle" justify="space-between">
            <Col span={4} md={6}>
              {translate('grids.comission-sub.validity-period-date-start')}
            </Col>
            <Col span={20} md={18}>
              <DatePicker
                format="yyyy.MM.dd"
                min={getCustomDate({ customDayOfMonth: new Date().getDate() + 1 })}
                value={dateStart}
                onChange={(e) => {
                  this.setState({ dateStart: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Row>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(PaymentTypeModal);
