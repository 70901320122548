import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../../services';
import { PAYMENT_TYPES_COLUMN_CONFIG, SERVICES_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import LoadingPanel from '../../../components/loader';
import { TARGET_TRANSFER_CONFIG, CODE_COLUMN_CONFIG } from '../../../core/constants/configs.const';
import AddCodeModalService from './addCodeModalService';

import './PaymentTypeNBUCodeGrid.scss';

const api = new ApiService();

class AddCodeModal extends PureComponent {
  state = {
    isLoading: false,
    services: [],
    selectServices: [],
    paymentTypes: [],
    selectPaymentTypes: [],
    selectCodes: [],
    codes: [],
    updateError: false,
    targetTransfers: [],
    selectedTargetTransfer: [],
  };

  componentDidMount() {
    this.fetchDropdownData();
  }

  getServices = async () => {
    try {
      const data = await api.getServiceDictionary({
        ownerId: [0],
      });

      await this.setState({ services: data && data.length ? DropdownNormalizersService.normalizeServices(data) : [] });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      await this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getNBUCodes = async () => {
    const { translate } = this.props;
    try {
      const data = await api.NBUCodesList();

      await this.setState({
        codes: data && data.length ? AddCodeModalService.normalizeNBUCodes(data, translate) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getTargetTransfers = async () => {
    try {
      const data = await api.getNBUSubCodesList();

      await this.setState({
        targetTransfers: data && data.length ? AddCodeModalService.normalizeNBUSubCodes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchDropdownData = async () => {
    await this.setState({ isLoading: true });
    await this.getServices();
    await this.getPaymentType();
    await this.getNBUCodes();
    await this.getTargetTransfers();
    await this.setState({ isLoading: false });
  };

  getOnDropdownSave = (fieldName) => (value) => {
    this.setState({ [fieldName]: value, updateError: '' }, () => {
      if (fieldName === 'selectCodes') {
        this.setState({ selectedTargetTransfer: [] });
      }
    });
  };

  addCodeForService = async () => {
    const { selectServices, selectPaymentTypes, selectCodes, selectedTargetTransfer } = this.state;
    const { onClose, translate, onRefresh, services } = this.props;

    await this.setState({ isLoading: true });

    const model = selectServices
      .map((serviceId) => ({
        serviceId,
        f108Code: selectCodes[0],
        f108SubCode: selectedTargetTransfer.length ? selectedTargetTransfer[0] : null,
      }))
      .reduce((acc, item) => [...acc, ...selectPaymentTypes.map((paymentType) => ({ paymentType, ...item }))], []);

    if (
      services.some(({ ServiceId, PaymentType }) =>
        model.some(({ serviceId, paymentType }) => serviceId === ServiceId && PaymentType === paymentType),
      )
    ) {
      this.setState({
        updateError:
          'Помилка! В довіднику вже є записи за вибраними параметрами. Перевірте значення та повторіть спробу.',
        isLoading: false,
      });
      return;
    }

    try {
      await api.addPaymentTypeServiceRelationsNBU(model);
      this.setState({ isLoading: false });
      onClose();
      message.success(translate('page.paymentTypeNBUCode.successfullyAdded'), 3);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getIsActionDisabled = () => {
    const { selectServices, selectPaymentTypes, codes, selectCodes, updateError, selectedTargetTransfer } = this.state;
    const selectedNbuCode = codes.find((item) => item.Id === selectCodes[0]);

    return (
      !selectServices.length ||
      !selectPaymentTypes.length ||
      updateError ||
      !selectedNbuCode ||
      (selectedNbuCode.WithSubcodes && !selectedTargetTransfer.length)
    );
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const {
      isLoading,
      services,
      selectServices,
      paymentTypes,
      selectPaymentTypes,
      codes,
      selectCodes,
      updateError,
      targetTransfers,
      selectedTargetTransfer,
    } = this.state;

    const selectedNbuCode = codes.find((item) => item.Id === selectCodes[0]);

    return (
      <Modal
        title={translate('page.paymentTypeNBUCode.addingNBUCode')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.addCodeForService}
            loading={isLoading}
            disabled={this.getIsActionDisabled()}
          >
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        className="AddCodeModal"
      >
        <>
          <div>
            <div className="AddCodeModal-label">{translate('page.commissionsServices.service')}:</div>
            <div className="AddCodeModal-selectWrapper">
              <GridDropdown
                data={services}
                colConfig={SERVICES_COLUMN_CONFIG}
                selectItems={selectServices}
                onSave={this.getOnDropdownSave('selectServices')}
                defaultTitle={translate('page.commissionsServices.chooseService')}
              />
            </div>

            <div className="AddCodeModal-label">{translate('page.commissionsServices.paymentType')}:</div>
            <div className="AddCodeModal-selectWrapper">
              <GridDropdown
                data={paymentTypes}
                colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                selectItems={selectPaymentTypes}
                onSave={this.getOnDropdownSave('selectPaymentTypes')}
                defaultTitle={translate('page.commissionsServices.choosePaymentType')}
              />
            </div>

            <div className="AddCodeModal-label">{translate('page.paymentTypeNBUCode.activeNBUCodes')}:</div>
            <div className="AddCodeModal-selectWrapper">
              <GridDropdown
                data={codes}
                colConfig={CODE_COLUMN_CONFIG}
                selectItems={selectCodes}
                onSave={this.getOnDropdownSave('selectCodes')}
                defaultTitle={translate('page.paymentTypeNBUCode.selectCode')}
                isSingle
              />
            </div>

            <div className="AddCodeModal-label">
              <label>{translate('page.paymentTypeNBUCode.targetTransfer')}</label>
            </div>
            <div className="AddCodeModal-selectWrapper">
              <GridDropdown
                data={AddCodeModalService.filterSubCodesBySelectedCode(targetTransfers, this.state.selectCodes)}
                colConfig={TARGET_TRANSFER_CONFIG}
                selectItems={selectedTargetTransfer}
                onSave={this.getOnDropdownSave('selectedTargetTransfer')}
                defaultTitle={translate('page.paymentTypeNBUCode.selectTargetTransfer')}
                disabled={!selectedNbuCode || !selectedNbuCode.WithSubcodes}
                isSingle
              />
            </div>
          </div>

          {updateError && <div className="AddCodeModal-error">{updateError}</div>}
          {isLoading && <LoadingPanel />}
        </>
      </Modal>
    );
  }
}

export default withTranslate(AddCodeModal);
