import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row } from 'antd';
import { Button } from '@progress/kendo-react-buttons';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ApiService } from '../../services';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import { getCustomDate } from '../../utils/getCustomDate';
import { OWNERS_COLUMN_CONFIG } from './MFODealersGrid/constants';

const api = new ApiService();

class MFOHistoryChanges extends PureComponent {
  state = {
    dealers: [],
    isLoading: false,
    modalVariant: '',
    rowAmount: '50',
    filtersString: '',
    sortString: '',
    pageValue: 1,
    dateStart: getCustomDate({ customDayOfMonth: new Date() }),
    dateEnd: getCustomDate({ customDayOfMonth: new Date() }),
    owners: [],
    currentOwners: [],
    hasNextPage: false,
    hasPreviousPage: false,
  };

  componentDidMount() {
    this.fetchMFODealers();
    this.fetchOwners();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, currentOwners } = this.state;
    const disabledBtn = !currentOwners || currentOwners.length === 0;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }
      if (disabledBtn) return;
      await this.fetchMFODealers();
    }
  }

  fetchOwners = async () => {
    try {
      await this.setState({ isLoading: true });
      const owners = await api.getOwnersMfo();

      if (owners && owners.length) {
        this.setState({ owners });
      }
      this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  onOwners = (currentOwners) => {
    this.setState({ currentOwners });
  };

  fetchMFODealers = async () => {
    const { rowAmount, pageValue, filtersString, currentOwners, dateStart, dateEnd } = this.state;

    try {
      await this.setState({ isLoading: true });

      const argumentsString = `take:${rowAmount}, skip:${
        rowAmount * (pageValue - 1)
      }, where: {and: [{ownerId:{in: [${currentOwners}]}},{dateExec:{gte: "${getDataByTimezoneOffset(
        dateStart,
      ).toISOString()}"}},{dateExec:{lte: "${getDataByTimezoneOffset(dateEnd).toISOString()}"}}, ${filtersString}]}`;

      const data = await api.getByGraphQl(
        `ownersMfoPropertiesHistList (${argumentsString}){items{ownerId,ownerName, ownersMfoPaymentTypeName,dateStart,userName}pageInfo{hasNextPage, hasPreviousPage}}`,
      );
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        dealers: data.data.ownersMfoPropertiesHistList.items,
        isLoading: false,
        hasNextPage: data.data.ownersMfoPropertiesHistList.pageInfo.hasNextPage,
        hasPreviousPage: data.data.ownersMfoPropertiesHistList.pageInfo.hasPreviousPage,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  normalizeOwners = (owners) => {
    if (!owners || owners.length === 0) {
      return [];
    }
    const newOwners = owners.map(({ OwnerId, OwnerName }) => ({
      Id: OwnerId,
      Name: OwnerName,
    }));

    return newOwners;
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { dateEnd, dateStart, currentOwners, owners } = this.state;

    const disabledBtn = !currentOwners || currentOwners.length === 0;
    return (
      <Col span={24} xl={16} xxl={14}>
        <Row type="flex" align="middle" justify="left" className="MFODealersGrid-dealerRow">
          <Col>
            <p>{translate('page-nav.nav-MFODealer')}</p>
          </Col>
          <Col type="flex" span={20} md={18} align="middle">
            <GridDropdownWithDeletedOwners
              data={this.normalizeOwners(owners)}
              colConfig={OWNERS_COLUMN_CONFIG}
              selectItems={currentOwners}
              onSave={this.onOwners}
              defaultTitle={translate('page.dealerCommissions.selectDealer')}
            />
          </Col>
        </Row>

        <Col span={24}>
          <Row type="flex" align="middle" justify="flex-start" className="CashCollectors-dateRow">
            <Col span={24} md={16} lg={8}>
              <Row type="flex" align="middle" justify="space-between" className="CashCollectors-dateRow">
                <Col span={4} md={6}>
                  <p>{translate('grids.comission-sub.validity-period-date-start')}</p>
                </Col>
                <Col span={20} md={18}>
                  <DatePicker
                    format="yyyy.MM.dd"
                    value={dateStart}
                    max={getCustomDate({ customDayOfMonth: new Date() })}
                    min={getCustomDate({ customDayOfMonth: 1, customDateMonth: 1, customDateFullYear: 2007 })}
                    onChange={(e) => {
                      this.setState({ dateStart: e.target.value });
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} md={16} lg={8}>
              <Row type="flex" align="middle" className="CashCollectors-dateRow">
                <Col span={4} md={6}>
                  <p>{translate('grids.comission-sub.validity-period-date-end')}</p>
                </Col>
                <Col span={20} md={18}>
                  <DatePicker
                    format="yyyy.MM.dd"
                    max={getCustomDate({ customDayOfMonth: new Date() })}
                    min={getCustomDate({ customDayOfMonth: 1, customDateMonth: 1, customDateFullYear: 2007 })}
                    value={dateEnd}
                    onChange={(e) => {
                      this.setState({ dateEnd: e.target.value });
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={24} lg={4}>
              <Button
                onClick={() => {
                  this.fetchMFODealers();
                }}
                icon="search"
                primary
                disabled={disabledBtn}
              >
                {translate('owner.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { dealers, isLoading, hasNextPage, hasPreviousPage, pageValue } = this.state;

    return (
      <>
        <Grid
          data={dealers}
          isLoading={isLoading}
          onRefresh={this.fetchMFODealers}
          name="MFOHistoryChanges"
          toolbar={this.buildToolbar()}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageValue={pageValue}
          pageChange={this.pageChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          handleRowAmountChange={this.handleRowAmountChange}
          isGQL
        >
          <GridColumn field="ownerId" title="page.MFODealer.dealerNumber" width="120" filter="numeric" />
          <GridColumn field="ownerName" title="page.MFODealer.dealerName" width="140" />
          <GridColumn field="ownersMfoPaymentTypeName" title="page.MFODealer.calculationType" width="150" />
          <GridColumn
            field="dateStart"
            title="page.MFODealer.dateStart"
            width="130"
            filter="date"
            format="dd.MM.yyyy"
          />
          <GridColumn field="userName" title="page.MFODealer.performer" width="120" />
        </Grid>
      </>
    );
  }
}

export default withTranslate(MFOHistoryChanges);
