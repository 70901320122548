import React, { PureComponent } from 'react';
import { Button, Input, message, Modal, Row } from 'antd';
import { ApiService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import DropdownNormalizersServiceMFO from '../../helper';
import { MFO_PROJECT_CONFIG } from '../constants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';

const api = new ApiService();

class PaymentCodeModal extends PureComponent {
  state = {
    isLoading: false,
    ownerProjectsList: [],
    ownerProjects: [],
    popupType: 'edit',
    newProjectValue: '',
  };

  componentDidMount() {
    this.fetchOwnerProjects();
  }

  addNewItem = async () => {
    const { ownerProjects, newProjectValue, popupType } = this.state;
    const { closeModal, translate, onRefresh, selectedItems } = this.props;

    try {
      await this.setState({ isLoading: true });

      const resp = selectedItems.map((item) => ({
        ownerId: item.ownerId,
        project: popupType === 'edit' ? ownerProjects[0] : newProjectValue,
      }));

      const data = await api.ownersMfoPropertiesProjectUpdate(resp);
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }
      message.success(translate('page.MFODealer.projectChangeSuccessful'), 2.5);

      await this.setState({
        isLoading: false,
      });
      onRefresh();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
      closeModal();
    }
  };

  renderByType = (type) => {
    const { translate } = this.props;
    const { ownerProjectsList, ownerProjects, newProjectValue } = this.state;
    switch (type) {
      case 'edit':
        return (
          <>
            <Row
              type="flex"
              align="middle"
              justify="space-between"
              style={{ gap: '10px' }}
              className="project-GridDropdown-wrapper-modal "
            >
              {!!ownerProjectsList.length && (
                <GridDropdown
                  data={DropdownNormalizersServiceMFO.normalizeProjects(ownerProjectsList)}
                  colConfig={MFO_PROJECT_CONFIG}
                  selectItems={ownerProjects}
                  maximumNumberSelected={1}
                  onSave={this.onTypes}
                  defaultTitle={translate('page.MFODealer.choseProject')}
                  isSingle
                />
              )}
              <div className="AddServiceByBigTable-action">
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ popupType: 'new' });
                  }}
                >
                  ...
                </Button>
              </div>
            </Row>
          </>
        );
      case 'new':
        return (
          <>
            <Row type="flex" align="middle" justify="space-between" style={{ gap: '20px' }}>
              <Input
                // className="UpdateMenuElementModal-input"
                value={newProjectValue}
                onChange={({ target: { value } }) => {
                  this.setState({ newProjectValue: value });
                }}
              />
            </Row>
          </>
        );
      default:
        return <></>;
    }
  };

  fetchOwnerProjects = async () => {
    try {
      await this.setState({ isLoading: true });

      const data = await api.ownerProjects();
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      if (data.length === 0) {
        this.setState({ popupType: 'new' });
      }

      await this.setState({
        ownerProjectsList: data,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onTypes = (ownerProjects) => {
    this.setState({ ownerProjects });
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading, popupType, ownerProjects, newProjectValue } = this.state;
    const disable = popupType === 'new' ? !newProjectValue.length : !ownerProjects.length;

    return (
      <Modal
        title={translate(popupType === 'new' ? 'page.MFODealer.projectNew' : 'page.MFODealer.projectUpdate')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          <>
            <Button key="submit" type="primary" disabled={disable} onClick={this.addNewItem}>
              {translate('core.buttonTitles.ok')}
            </Button>
            <Button
              key="back"
              onClick={() => {
                popupType === 'new' ? this.setState({ popupType: 'edit' }) : closeModal();
              }}
            >
              {translate('owner.cancel')}
            </Button>
          </>,
        ]}
      >
        {this.renderByType(popupType)}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(PaymentCodeModal);
