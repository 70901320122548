class AddCodeModalService {
  normalizeNBUCodes(nbuCodes, translate) {
    if (!nbuCodes.length) {
      return [];
    }

    return nbuCodes.map(({ F108Code, Description, IsActive, WithSubcodes }) => ({
      Id: F108Code,
      Name: Description,
      Active: IsActive ? translate('page.paymentTypeNBUCode.active') : translate('page.paymentTypeNBUCode.notActive'),
      WithSubcodes: !!WithSubcodes,
    }));
  }

  normalizeNBUSubCodes(subCodes) {
    if (!subCodes.length) {
      return [];
    }

    return subCodes.map(({ F108SubCode, Name, F108Code }) => ({
      Id: `${F108SubCode}`,
      Name,
      F108Code,
    }));
  }

  filterSubCodesBySelectedCode = (subCodes, selectedCodes) =>
    selectedCodes.length ? subCodes.filter(({ F108Code }) => F108Code === selectedCodes[0]) : [];
}

export default new AddCodeModalService();
