import React, { PureComponent } from 'react';
import { Button,  message, Modal, Row } from 'antd';
import { ApiService } from '../../../../services';
import { withTranslate } from '../../../../contexts/localContext';
import LoadingPanel from '../../../../components/loader';
import DropdownNormalizersServiceMFO from '../../helper';
import { MFO_CODES_CONFIG, MFO_TYPES_CONFIG } from '../constants';
import GridDropdown from '../../../../components/GridDropdown/GridDropdown';

const api = new ApiService();

class PaymentCodeModal extends PureComponent {
  state = {
    isLoading: false,
    ownersMfoPaymentCodesList: [],
    ownersMfoPaymentCode: [],
  };

  componentDidMount() {
    this.fetchownersMfoPaymentCodes();
  }

  addNewItem = async () => {
    const { ownersMfoPaymentCode } = this.state;
    const { closeModal, translate, onRefresh, selectedItems } = this.props;

    try {
      await this.setState({ isLoading: true });

      const resp = selectedItems.map((item) => ({
        ownerId: item.ownerId,
        f108: ownersMfoPaymentCode[0],
      }));

      const data = await api.ownersMfoPropertiesF108Update(resp);
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }
      message.success(translate('page.MFODealer.paymentDestinationCodeChangeSuccessful'), 2.5);

      await this.setState({
        isLoading: false,
      });
      onRefresh();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
      closeModal();
    }
  };

  fetchownersMfoPaymentCodes = async () => {
    try {
      await this.setState({ isLoading: true });

      const data = await api.NBUCodesList();
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        ownersMfoPaymentCodesList: data,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  onTypes = (ownersMfoPaymentCode) => {
    this.setState({ ownersMfoPaymentCode });
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading, ownersMfoPaymentCodesList, ownersMfoPaymentCode, } = this.state;
    const disable = !ownersMfoPaymentCode.length;

    return (
      <Modal
        title={translate('page.MFODealer.changePaymentDestinationCode')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          <>
            <Button key="submit" type="primary" disabled={disable} onClick={this.addNewItem}>
              {translate('core.buttonTitles.change')}
            </Button>
            <Button key="back" onClick={closeModal}>
              {translate('owner.cancel')}
            </Button>
          </>,
        ]}
      >
        <Row type="flex" align="middle" justify="space-between" style={{ gap: '20px' }}>
          {!!ownersMfoPaymentCodesList.length && (
            <GridDropdown
              data={DropdownNormalizersServiceMFO.normalizeCodes(ownersMfoPaymentCodesList)}
              colConfig={MFO_CODES_CONFIG}
              selectItems={ownersMfoPaymentCode}
              onSave={this.onTypes}
              maximumNumberSelected={1}
              isSingle
              defaultTitle={translate('page.MFODealer.chosePaymentDestinationCode')}
            />
          )}          
        </Row>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(PaymentCodeModal);
