export const CHANGE_ACTIONS = [
  {
    id: 'PaymentCodeModal',
    title: 'page.MFODealer.paymentDestinationCode',
    securityKey: 'dealersMFO-changePaymentDestinationCode',
  },
  {
    id: 'PaymentTypeModal',
    title: 'page.MFODealer.calculationType',
    securityKey: 'dealersMFO-changeCalculationType',
  },
  { id: 'ProjectModal', title: 'page.MFODealer.project', securityKey: 'dealersMFO-changeProject' },
];

export const MFO_TYPES_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '240',
    isShowOnMobile: true,
  },
];

export const MFO_CODES_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '240',
    isShowOnMobile: true,
  },
];

export const MFO_PROJECT_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '240',
    isShowOnMobile: true,
  },
];

export const OWNERS_COLUMN_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '100',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.dealer-commissions.column-owner-name',
    width: '640',
    isShowOnMobile: true,
  },
];
