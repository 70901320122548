import React, { PureComponent } from 'react';

import { ApiService, StorageService } from '../../../services';
import ReleasesMatrix from '../ReleasesMatrix';
import ReleasesService from './service';
import { withTranslate } from '../../../contexts/localContext';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class AllElementsGrid extends PureComponent {
  componentDidMount() {
    const releasesByStorage = StorageService.getItem('backConfig');

    if (releasesByStorage) {
      this.setState({ backConfig: releasesByStorage });

      return;
    }

    this.getBackConfig();
  }

  state = {
    backConfig: null,
    isLoading: false,
  };

  currentArray = findArray('releasePage');

  getBackConfig = async () => {
    try {
      this.setState({ isLoading: true });
      const backEndConfig = await api.getElementsFromBack({ Elements: [] });
      StorageService.setItem('backConfig', backEndConfig);
      this.setState({ backConfig: backEndConfig });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate } = this.props;
    const { backConfig, isLoading } = this.state;

    if (!checkElement('releasePage-content', this.currentArray) || !backConfig) {
      return null;
    }

    const ElementsListByRelease = ReleasesService.getElementsListWithRelease(backConfig, translate);
    const WebTagListByRelease = ReleasesService.getWebTagListWidthRelease(backConfig, translate);

    if (!ElementsListByRelease || !WebTagListByRelease) {
      return null;
    }

    return (
      <>
        <ReleasesMatrix
          ElementsListByRelease={ElementsListByRelease}
          WebTagListByRelease={WebTagListByRelease}
          withTagsReleaseInfo
        />
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(AllElementsGrid);
