import { ApiService } from './index';

class DownloadReceiptPDFService {
  api = new ApiService();

  downloadReceipt = async (setLoading, showError, transactionId) => {
    try {
      await setLoading(true);
      const base64Receipt = await this.api.getReceiptsToPdf({
        transactionId,
        receiptType: 'Simple',
      });

      this.handleDownload(base64Receipt, `receipt_${transactionId}.pdf`);
      await setLoading(false);
    } catch (err) {
      showError(err);
      await setLoading(false);
    }
  };

  handleDownload = (base64String, name, type) => {
    const responseBinaryString = atob(base64String);
    const responseBinaryLen = responseBinaryString.length;
    const responseBinaryLenByBytes = new Uint8Array(responseBinaryLen);

    for (let i = 0; i < responseBinaryLen; i++) {
      responseBinaryLenByBytes[i] = responseBinaryString.charCodeAt(i);
    }

    const blobURL = URL.createObjectURL(new Blob([responseBinaryLenByBytes], { type: `application/${type || 'pdf'}` }));
    const downloadLink = document.createElement('a');
    downloadLink.href = blobURL;
    downloadLink.download = name;
    downloadLink.click();
  };
}

export default new DownloadReceiptPDFService();
