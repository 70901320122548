import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import CellBoolean from '../account/cellBoolean';
import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { BASE_SERVICES_COLUMN_CONFIG, OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService, tabService } from '../../services';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

import './ServiceUserAccess.styled.scss';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;
const api = new ApiService();

class ServiceUserAccessGrid extends PureComponent {
  currentArray = findArray('serviceUserAccess');

  state = {
    users: [],
    isLoading: false,
    selectedServices: [],
    servicesList: [],
    selectOwners: [],
    owners: [],
  };

  componentDidMount() {
    this.initialFetch();
  }

  initialFetch = async () => {
    await this.setState({ isLoading: true });
    await this.fetchServiceList();
    await this.fetchOwners();
    await this.setState({ isLoading: false });
  };

  getUsersByService = async () => {
    const { selectedServices, selectOwners } = this.state;

    if (!selectedServices.length || !selectOwners.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const usersByService = await api.getUsersAccessListByService({
        serviceId: selectedServices[0],
      });

      const userList = await api.postGetUsersList(selectOwners);

      await this.setState({
        isLoading: false,
        users:
          userList && userList.length
            ? userList.map((user) => {
                const UserByServiceAccess =
                  usersByService && usersByService.find(({ UserId }) => UserId === user.UserId);

                return {
                  ...user,
                  IsAccess: !!UserByServiceAccess,
                  DatePost: UserByServiceAccess ? getDataByTimezoneOffset(UserByServiceAccess.DatePost) : null,
                };
              })
            : [],
      });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      const data = await api.owners();

      await this.setState({ owners: DropdownNormalizersService.normalizeOwners(data) });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchServiceList = async () => {
    try {
      const services = await api.getServiceDictionary({
        ownerId: [0],
      });

      this.setState({
        servicesList: DropdownNormalizersService.normalizeServices(services),
      });
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const { selectedServices, servicesList, selectOwners, owners } = this.state;
    const { translate } = this.props;

    return (
      <Row className="ServiceUserAccessGrid-toolbar">
        <div className="ServiceUserAccessGrid-fieldContent">
          <div className="ServiceUserAccessGrid-fieldRow">
            <div className="ServiceUserAccessGrid-label">{translate('page.commissionsServices.service')}</div>
            <div className="ServiceUserAccessGrid-select">
              <GridDropdown
                data={servicesList}
                colConfig={BASE_SERVICES_COLUMN_CONFIG}
                selectItems={selectedServices}
                onSave={(selectedServices) => {
                  this.setState({ selectedServices });
                }}
                defaultTitle={translate('page.commissionsServices.chooseService')}
                isSingle
              />
            </div>
          </div>

          <div className="ServiceUserAccessGrid-fieldRow">
            <div className="ServiceUserAccessGrid-label">{translate('page.commissionsServices.dealer')}</div>
            <div className="ServiceUserAccessGrid-select">
              <GridDropdown
                data={owners}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={selectOwners}
                onSave={(selectOwners) => {
                  this.setState({ selectOwners });
                }}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
              />
            </div>
          </div>
        </div>

        <div className="ServiceUserAccessGrid-searchAction">
          <Button
            type="primary"
            disabled={!servicesList.length || !selectOwners.length}
            onClick={this.getUsersByService}
          >
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {checkElement('serviceUserAccess-history', this.currentArray) && (
          <div className="ServiceUserAccessGrid-historyAction">
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab({ type: 'serviceUserAccessHistory' });
              }}
            >
              {translate('page.serviceUserAccess.historyChanges')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  render() {
    const { users, isLoading } = this.state;

    if (!checkElement('serviceUserAccess-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={users}
        toolbar={this.buildToolbar()}
        onRefresh={this.getUsersByService}
        name="serviceUserAccessGrid"
        sortable
        isLoading={isLoading}
      >
        <GridColumn
          field="IsAccess"
          title="page.serviceUserAccess.accessibility"
          width="150"
          cell={WrappedCellBoolean}
        />
        <GridColumn field="OwnerId" title="page.serviceUserAccess.dealerId" width="150" />
        <GridColumn field="OwnerName" title="grids.userList.column-dealer" width="150" />
        <GridColumn field="UserId" title="page.serviceUserAccess.userId" width="150" />
        <GridColumn field="Name" title="grids.userList.column-login" width="150" />
        <GridColumn field="FirstName" title="grids.userList.column-firstName" width="150" />
        <GridColumn field="LastName" title="grids.userList.column-lastName" width="150" />
        <GridColumn field="Address" title="grids.userList.column-address" width="150" />
        <GridColumn field="Description" title="grids.userList.column-desc" width="200" />
        <GridColumn
          field="DateLastLogin"
          title="grids.userList.column-dateLastLogin"
          width="200"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="Active" title="grids.userList.column-active" width="150" cell={WrappedCellBoolean} />
        <GridColumn
          field="DatePost"
          title="grids.userList.column-dateOfChange"
          width="200"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(ServiceUserAccessGrid);
