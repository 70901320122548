export const TRANSACTION_PROPS = [
  'Свойство транзакции (не выбрано)',
  'Account',
  'Amount',
  'BatchId',
  'Card.Amount',
  'Card.Account',
  'Payer.Card',
  'Card.Hash',
  'Card.Pan',
  'Card.Track2',
  'HoldRuleId',
  'HoldWhiteList',
  'Debtor.Code',
  'ErrorCode',
  'ErrorMessage',
  'Phone',
  'PayerPhone',
  'Rrn',
  'ServiceId',
  'Track2',
  'TransactionId',
  'UserId',
];

export const ADDITIONAL_TRANSACTION_PROPS = [
  'Свойство транзакции (не выбрано)',
  'Account',
  'Card.Account',
  'Payer.Card',
  'Card.Pan',
  'HoldRuleId',
  'Debtor.Code',
  'ErrorCode',
  'Phone',
  'PayerPhone',
  'ServiceId',
  'UserId',
];

export const SERVICE_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '100',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.transactions.column-serviceId',
    width: '345',
    isShowOnMobile: true,
  },
];

export const TRANSACTION_FIELDS_INFO = {
  ownerName: { filter: 'text', title: 'grids.transactions.column-ownerName' },
  transactionId: { filter: 'numeric', title: 'grids.transactions.column-transactionId' },
  requestId: { filter: 'numeric', title: 'grids.transactions.column-requestId' },
  terminalId: { filter: 'numeric', title: 'grids.transactions.column-terminalId' },
  serviceId: { filter: 'numeric', title: 'grids.transactions.column-serviceId' },
  serviceName: { filter: 'text', title: 'grids.transactions.column-serviceName' },
  amountTotal: { filter: 'numeric', title: 'grids.transactions.column-amountTotal' },
  amountOriginal: { filter: 'numeric', title: 'grids.transactions.column-amountOriginal' },
  commissionProvider: { filter: 'numeric', title: 'grids.transactions.column-comissionProvider' },
  commissionClient: { filter: 'numeric', title: 'grids.transactions.column-comissionClient' },
  currency: { filter: 'text', title: 'grids.transactions.column-currency' },
  dateInput: { filter: 'date', title: 'grids.transactions.column-dateInput' },
  datePost: { filter: 'date', title: 'grids.transactions.column-datePost' },
  dateDeclined: { filter: 'date', title: 'grids.transactions.column-dateDeclined' },
  dateAccepted: { filter: 'date', title: 'grids.transactions.column-dateAccepted' },
  account: { filter: 'text', title: 'grids.transactions.column-account' },
  comment: { filter: 'text', title: 'grids.transactions.column-comment' },
  statusName: { filter: 'text', title: 'grids.transactions.column-status' },
  paymentType: { filter: 'text', title: 'grids.transactions.column-typePayment' },
  operationName: { filter: 'text', title: 'page.transactions.operation' },
  terminalType: { filter: 'text', title: 'page.transactions.terminalType' },
};

export const DEFAULT_EXPAND_SEARCH_OPTIONS = {
  currentTransactionProp: ADDITIONAL_TRANSACTION_PROPS[0],
  currentTransactionPropValue: '',
  selectedPaymentTypes: [],
  selectedOperations: undefined,
  selectTerminalTypes: undefined,
  isNoComment: false,
  error: '',
};
