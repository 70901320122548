export const SEARCH_PARAMS = [
  { title: 'page.easypayUsersManage.phoneNumber', id: 1 },
  { title: 'page.easypayUsersManage.email', id: 2 },
  { title: 'page.easypayUsersManage.accountNumber', id: 3 },
  { title: 'page.easypayUsersManage.userNumber', id: 4 },
  { title: 'page.easypayUsersManage.cardNumber', id: 5 },
  { title: 'page.easypayUsersManage.rnkopp', id: 6 },
  { title: 'page.easypayUsersManage.transactionNumber', id: 7 },
];

export const GQL_FIELDS = [
  'address',
  'dateRegister',
  'email',
  'firstName',
  'lastName',
  'phone',
  'statusName',
  'userId',
  'city',
  'userGuid',
];

export const GQL_KEY_BY_MAP = new Map([
  [1, 'phone'],
  [2, 'email'],
  [3, 'walletNumber'],
  [4, 'userId'],
  [5, 'card'],
  [6, 'inn'],
  [7, 'transactionId'],
]);

export const GQL_METHOD_BY_MAP = new Map([
  [1, 'clients'],
  [2, 'clients'],
  [3, 'clientWallets'],
  [4, 'clients'],
  [5, 'clientByCards'],
  [6, 'clientByInns'],
  [7, 'clientByTransactionIds'],
]);
