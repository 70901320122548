import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

class UserServicesAccessService {
  mergeServicesWithPermission = (services, permissions) => {
    if (!services || !services.length) {
      return [];
    }

    if (!permissions || !permissions.length) {
      return services;
    }

    return services
      .map((item) => {
        const itemsPermission = permissions.find(({ ServiceId }) => ServiceId === item.ServiceId);

        return { ...itemsPermission, ...item };
      })
      .map((item) => ({
        ...item,
        DatePost: item.DatePost ? getDataByTimezoneOffset(item.DatePost).toISOString() : null,
      }));
  };
}

export default new UserServicesAccessService();
