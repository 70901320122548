export const dealersMFO = (translate, value) => {
  switch (value) {
    case 'dealersMFO-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'dealersMFO-deleteProject':
      return `${translate(`page.adminPanel.type-button`)} : ${translate(`page.MFODealer.deleteProject`)}`;
    case 'dealersMFO-deleteExpectedChanges':
      return `${translate('page.adminPanel.type-tab')}:
                        ${translate('page.MFODealer.expectedChanges')}, ${translate(
        `page.adminPanel.type-button`,
      )} : ${translate(`page.userRatingGrid.deleteExpectedChanges`)}`;
    case 'dealersMFO-changePaymentDestinationCode':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate(`page.MFODealer.paymentDestinationCode`)}`;
    case 'dealersMFO-changeCalculationType':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate(`page.MFODealer.calculationType`)}`;
    case 'dealersMFO-changeProject':
      return `${translate(`page.adminPanel.dropdown-button`)}: ${translate(`page.MFODealer.project`)}`;
    case 'dealersMFO-expectedChanges':
      return `${translate('page.adminPanel.type-navLink')}:
                                    ${translate('page.MFODealer.expectedChanges')}`;
    case 'dealersMFO-historyChanges':
      return `${translate('page.adminPanel.type-navLink')}: ${translate('page.MFODealer.historyChanges')}`;
  }
};
