import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Dropdown, Icon, Menu, Col, Checkbox } from 'antd';
import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { CHANGE_ACTIONS } from './constants';
import './MFODealersGrid.styled.scss';
import PaymentCodeModal from './modals/PaymentCodeModal';
import PaymentTypeModal from './modals/PaymentTypeModal';
import ProjectModal from './modals/ProjectModal';
import { ApiService, tabService } from '../../../services';
import DeleteProjectModal from './modals/DeleteProjectModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const api = new ApiService();

class MFODealersGrid extends PureComponent {
  currentArray = findArray('dealersMFO');

  state = {
    dealers: [],
    isLoading: false,
    selectedItems: [],
    modalVariant: '',
    rowAmount: '50',
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    pageValue: 1,
  };

  getContent = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Menu className="BaseGridCellDropdown">
        {CHANGE_ACTIONS.filter(({ securityKey }) => checkElement(securityKey, this.currentArray)).map(
          ({ id, title }) => (
            <Menu.Item
              onClick={() => {
                this.setState({ modalVariant: id });
              }}
              key={id}
              disabled={id === 'PaymentTypeModal' ? selectedItems.length !== 1 : selectedItems.length === 0}
            >
              {translate(title)}
            </Menu.Item>
          ),
        )}
      </Menu>
    );
  };

  componentDidMount() {
    this.fetchMFODealers();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }
      await this.fetchMFODealers();
    }
  }

  fetchMFODealers = async () => {
    try {
      await this.setState({ isLoading: true });

      const { rowAmount, pageValue, sortString } = this.state;

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order: ${
        sortString || '{ ownerName: ASC }'
      }`;

      const data = await api.getByGraphQl(
        `ownersMfoPropertiesList (${argumentsString}){items{ownerId,ownerName,f108,project,dateStart,ownersMfoPaymentTypeName,userName,ownersMfoPropertiesId,transactionExists}pageInfo{hasNextPage, hasPreviousPage}}`,
      );
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        dealers: data.data.ownersMfoPropertiesList.items,
        isLoading: false,
        hasNextPage: data.data.ownersMfoPropertiesList.pageInfo.hasNextPage,
        hasPreviousPage: data.data.ownersMfoPropertiesList.pageInfo.hasPreviousPage,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;

    const { selectedItems } = this.state;

    const disableDeleteProjectModal = selectedItems.length === 0 || !selectedItems.find((item) => item.project);
    const isRenderChangeButton = CHANGE_ACTIONS.some(({ securityKey }) => checkElement(securityKey, this.currentArray));

    return (
      <Col className="MFODealersGrid-toolbar">
        {isRenderChangeButton && (
          <div className="MFODealersGrid-toolbarItem">
            <Dropdown overlay={this.getContent()} trigger={['click']}>
              <Button type="primary">
                <span>{translate('core.buttonTitles.change')}</span>
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        )}
        {checkElement('dealersMFO-deleteProject', this.currentArray) && (
          <div className="MFODealersGrid-toolbarItem">
            <Button
              onClick={() => {
                this.setState({ modalVariant: 'DeleteProjectModal' });
              }}
              type="primary"
              disabled={disableDeleteProjectModal}
            >
              {translate('page.MFODealer.deleteProject')}
            </Button>
          </div>
        )}
        {/* <div className="MFODealersGrid-toolbarItem">
          <Button type="primary">{translate('page.MFODealer.projectsReport')}</Button>
        </div> */}
        {checkElement('dealersMFO-expectedChanges', this.currentArray) && (
          <div className="MFODealersGrid-toolbarItem">
            <Button
              onClick={() => {
                tabService.addTab({
                  type: 'MFOExpectedChanges',
                });
              }}
              type="primary"
            >
              {translate('page.MFODealer.expectedChanges')}
            </Button>
          </div>
        )}
        {checkElement('dealersMFO-historyChanges', this.currentArray) && (
          <div className="MFODealersGrid-toolbarItem">
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab({
                  type: 'MFOHistoryChanges',
                });
              }}
            >
              {translate('page.MFODealer.historyChanges')}
            </Button>
          </div>
        )}
      </Col>
    );
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { dealers, isLoading, selectedItems, modalVariant, hasNextPage, hasPreviousPage, pageValue } = this.state;

    if (!checkElement('dealersMFO-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={dealers}
          isLoading={isLoading}
          onRefresh={this.fetchMFODealers}
          name="MFODealersGrid"
          toolbar={this.buildToolbar()}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageValue={pageValue}
          pageChange={this.pageChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          handleRowAmountChange={this.handleRowAmountChange}
          multiSelected
          fieldForSelection="ownerId"
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />

          <GridColumn field="ownerId" title="page.MFODealer.dealerNumber" width="120" filter="numeric" />
          <GridColumn field="ownerName" title="page.MFODealer.dealerName" width="140" />
          <GridColumn field="project" title="page.MFODealer.project" width="140" />
          <GridColumn field="ownersMfoPaymentTypeName" title="page.MFODealer.calculationType" width="150" />
          <GridColumn
            field="dateStart"
            title="page.MFODealer.dateStart"
            width="130"
            filter="date"
            format="dd.MM.yyyy"
          />
          <GridColumn field="userName" title="page.MFODealer.performer" width="120" />
          <GridColumn field="f108" title="page.MFODealer.f108Code" width="120" />
          <GridColumn
            field="transactionExists"
            title="page.MFODealer.transactionExists"
            width="250"
            cell={({ dataItem: { transactionExists } }) => (
              <td style={{ textAlign: 'center' }}>
                <Checkbox checked={transactionExists} disabled />
              </td>
            )}
          />
        </Grid>

        {modalVariant === 'ProjectModal' && (
          <ProjectModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchMFODealers}
            selectedItems={selectedItems}
          />
        )}

        {modalVariant === 'PaymentTypeModal' && (
          <PaymentTypeModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchMFODealers}
            selectedItems={selectedItems}
          />
        )}

        {modalVariant === 'PaymentCodeModal' && (
          <PaymentCodeModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchMFODealers}
            selectedItems={selectedItems}
          />
        )}

        {modalVariant === 'DeleteProjectModal' && (
          <DeleteProjectModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            onRefresh={this.fetchMFODealers}
            selectedItems={selectedItems}
          />
        )}
      </>
    );
  }
}

export default withTranslate(MFODealersGrid);
