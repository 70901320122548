import React, { PureComponent } from 'react';
import { Button, Col, Select, Input, Row, message } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { SEARCH_TYPES } from './constants';
import { ApiService, DownloadReceiptPDFService, tabService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import './ClientCabinetStyle.scss';
import ChangeClientDataModal from './ChangeClientDataModal';
import checkElement from '../../utils/checkElement';
import { MaskedInput } from 'antd-mask-input';
const { Option } = Select;
const api = new ApiService();

class ClientCabinetGrid extends PureComponent {
  currentArray = findArray('clientCabinetFinmon');

  state = {
    isLoading: false,
    clientList: [],
    searchType: undefined,
    searchQuery: undefined,
    errorMessage: '',
    selectedItem: [],
    openModal: false,
  };

  fetchClient = async () => {
    const { searchType, searchQuery } = this.state;
    this.setState({ isLoading: true });
    try {
      const model = {
        [searchType]: searchQuery,
      };

      const clientListData = await api.getClients(model);

      if (!clientListData) {
        this.setState({ isLoading: false });
        return;
      }

      this.setState({
        clientList: this.normalizeData(clientListData),
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  normalizeData = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      transactionPropertyKey: this.state.searchType,
      transactionPropertyValue: this.state.searchQuery,
    }));
  };

  returnInputByType = (inputSearchType) => {
    const { translate } = this.props;
    const { searchQuery } = this.state;
    switch (inputSearchType) {
      case 'inn':
        return (
          <Input
            placeholder={translate('page.easyPayUsers.innPlaceholer')}
            className="EasyPayUsers-input"
            onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery })}
            value={searchQuery}
          />
        );

      case 'phone':
        return (
          <div className="EasyPayUsers-input">
            <MaskedInput
              onChange={({ target: { value: phone } }) => this.setState({ searchQuery: phone.replace(/\D+/g, '') })}
              value={searchQuery}
              mask="38 (000) - 000 - 00 - 00"
            />
          </div>
        );

      case 'fio':
        return (
          <Input
            placeholder={translate('page.easyPayUsers.fioPlaceholer')}
            className="EasyPayUsers-input"
            onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery })}
            value={searchQuery}
          />
        );

      case 'userId':
        return (
          <Input
            placeholder={translate('page.easyPayUsers.userIdPlaceholer')}
            className="EasyPayUsers-input"
            value={searchQuery}
            onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery })}
          />
        );

      default:
        return (
          <>
            {' '}
            <Input
              placeholder={translate('page.easyPayUsers.query')}
              className="EasyPayUsers-input"
              onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery })}
            />
          </>
        );
    }
  };

  downloadQuestionnaire = async () => {
    const { selectedItem } = this.state;

    try {
      await this.setState({ isLoading: true });
      const base64Doc = await api.getQuestionnairePdf(selectedItem[0].UserGuid);

      DownloadReceiptPDFService.handleDownload(base64Doc, `Questionnaire_user_${selectedItem[0].UserGuid}.pdf`);
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  downloadClientDocuments = async () => {
    const { selectedItem } = this.state;

    try {
      await this.setState({ isLoading: true });
      const base64Doc = await api.getQuestionnaireDocuments(selectedItem[0].UserGuid);

      DownloadReceiptPDFService.handleDownload(
        base64Doc,
        `QuestionnaireDocuments_user_${selectedItem[0].UserGuid}.zip`,
        'zip',
      );
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  confirmQuestionnaire = async () => {
    const { translate } = this.props;
    const { selectedItem } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.setFinMonQuestionnaireVerifiedStatus(selectedItem[0].UserGuid);

      message.success(translate('page.clientCabinet.questionnaireSuccessfullyConfirmed'), 2);
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  confirmDocuments = async () => {
    const { translate } = this.props;
    const { selectedItem } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.setFinMonQuestionnaireDocumentsVerifiedStatus(selectedItem[0].UserGuid);

      message.success(translate('page.clientCabinet.documentsSuccessfullyConfirmed'), 2);
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { searchType, searchQuery, selectedItem } = this.state;

    return (
      <Row>
        <Col className="EasyPayUsers-toolbar">
          <div className="EasyPayUsers-fieldRow">
            <div className="EasyPayUsers-shortLabel">{translate('page.clientCabinet.searchType')}:</div>
            <Select
              value={searchType}
              placeholder={translate('page.easyPayUsers.selectSearchType')}
              className="EasyPayUsers-select"
              onChange={(searchType) => {
                this.setState({ searchType, clientList: [], searchQuery: undefined });
              }}
              allowClear
            >
              {SEARCH_TYPES.map(({ translateTitle, title, id }) => (
                <Option value={id} key={id}>
                  {title || translate(translateTitle)}
                </Option>
              ))}
            </Select>
          </div>

          <div className="EasyPayUsers-fieldRow">
            <div className="EasyPayUsers-shortLabel">{translate('page.easyPayUsers.value')}:</div>
            {this.returnInputByType(searchType)}
          </div>
          <Button type="primary" disabled={!searchQuery || !searchType} onClick={this.fetchClient}>
            {translate('core.buttonTitles.search')}
          </Button>
        </Col>

        <Col className="clientCabinet-actionRow">
          {checkElement('clientCabinetFinmon-сhangeClientData', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={selectedItem.length === 0}
                onClick={() => {
                  this.setState({ openModal: true });
                }}
              >
                {translate('page.clientCabinet.changeClientData')}
              </Button>
            </div>
          )}

          {checkElement('clientCabinetFinmon-operationHistory', this.currentArray) && (
            <div>
              <Button
                type="primary"
                disabled={selectedItem.length === 0}
                onClick={() => {
                  tabService.addTab({ type: 'operationHistory', dataItem: { user: selectedItem[0] } });
                }}
              >
                {translate('page.clientCabinet.operationHistory')}
              </Button>
            </div>
          )}

          {checkElement('clientCabinetFinmon-downloadQuestionnaire', this.currentArray) && (
            <div>
              <Button type="primary" disabled={selectedItem.length === 0} onClick={this.downloadQuestionnaire}>
                {translate('page.clientCabinet.downloadQuestionnaire')}
              </Button>
            </div>
          )}

          {checkElement('clientCabinetFinmon-clientDocuments', this.currentArray) && (
            <div>
              <Button type="primary" disabled={selectedItem.length === 0} onClick={this.downloadClientDocuments}>
                {translate('page.clientCabinet.clientDocuments')}
              </Button>
            </div>
          )}

          {checkElement('clientCabinetFinmon-confirmQuestionnaire', this.currentArray) && (
            <div>
              <Button type="primary" disabled={selectedItem.length === 0} onClick={this.confirmQuestionnaire}>
                {translate('page.clientCabinet.confirmQuestionnaire')}
              </Button>
            </div>
          )}

          {checkElement('clientCabinetFinmon-confirmDocuments', this.currentArray) && (
            <div>
              <Button type="primary" disabled={selectedItem.length === 0} onClick={this.confirmDocuments}>
                {translate('page.clientCabinet.confirmDocuments')}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    );
  };

  handleSelectionChange = (selectedItem) => {
    this.setState({ selectedItem });
  };

  render() {
    const { clientList, isLoading, openModal, selectedItem } = this.state;
    if (!checkElement('clientCabinetFinmon-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={clientList}
          isLoading={isLoading}
          name="clientCabinetGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchClient}
          fieldForSelection="IdentificationId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="Name" title="page.serviceSupplier.fullName" width="290" />
          <GridColumn field="Phone" title="page.serviceSupplier.phone" width="190" />
          <GridColumn
            field="Birthday"
            title="grids.search-lists-sanctioners.column-dateBd"
            width="160"
            cell={({ dataItem: { Birthday } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(Birthday, 'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(Birthday) => getTransformDateString(Birthday, 'DD.MM.YYYY')}
          />
          <GridColumn field="Email" title="page.serviceSupplier.col-email" width="140" />
          <GridColumn field="VerificationSource" title="page.clientCabinet.VerificationSource" width="150" />
          <GridColumn
            field="VerificationDate"
            title="page.clientCabinet.VerificationDate"
            width="150"
            cell={({ dataItem: { VerificationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(VerificationDate)}</td>
            )}
            formatFilterCellData={(VerificationDate) => getTransformDateString(VerificationDate)}
          />
          <GridColumn
            field="VerificationExpirationDate"
            title="page.clientCabinet.VerificationExpirationDate"
            width="150"
            cell={({ dataItem: { VerificationExpirationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(VerificationExpirationDate)}</td>
            )}
            formatFilterCellData={(VerificationExpirationDate) => getTransformDateString(VerificationExpirationDate)}
          />
          <GridColumn field="Status" title="page.terminalSettings.field-status" width="190" />
          <GridColumn field="UserGuid" title="page.profilesMenu.userId" width="130" />
          <GridColumn field="TaxNumber" title="page.beneficiaries.inn" width="130" />
          <GridColumn field="IdentificationId" title="Id" width="130" />
          <GridColumn field="QuestionnaireStatus" title="page.clientCabinet.questionnaireStatus" width="190" />
          <GridColumn
            field="QuestionnaireDate"
            title="page.clientCabinet.questionnaireDate"
            width="180"
            cell={({ dataItem: { QuestionnaireDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(QuestionnaireDate)}</td>
            )}
            formatFilterCellData={(QuestionnaireDate) => getTransformDateString(QuestionnaireDate)}
          />
          <GridColumn
            field="QuestionnaireDocumentsStatus"
            title="page.clientCabinet.incomeDocumentStatus"
            width="230"
          />
          <GridColumn
            field="QuestionnaireDocumentsDate"
            title="page.clientCabinet.documentsDate"
            width="150"
            cell={({ dataItem: { QuestionnaireDocumentsDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(QuestionnaireDocumentsDate)}</td>
            )}
            formatFilterCellData={(QuestionnaireDocumentsDate) => getTransformDateString(QuestionnaireDocumentsDate)}
          />
        </Grid>
        {openModal && (
          <ChangeClientDataModal
            openModal={openModal}
            closeModal={() => {
              this.setState({ openModal: false });
            }}
            user={selectedItem[0]}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ClientCabinetGrid);
