import React, { PureComponent } from 'react';
import { Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService, tabService } from '../../../services';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';

import './EasypayUserCardGrid.styled.scss';

const api = new ApiService();

class EasypayUserCardGrid extends PureComponent {
  currentArray = findArray('easypayUsersManage');

  state = {
    accounts: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchAllGQL();
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.clientCards) {
      return { users: [], hasNextPage: false };
    }
    const cardList = data.data.clientCards;

    const accounts = cardList.items && cardList.items.length ? cardList.items : [];

    const pageInfo = accounts.pageInfo;

    return {
      accounts,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  iterateFetchGQL = async (skip, accountList, value) => {
    try {
      const query = `clientCards(take: 500,skip:${skip},where:{and:[{userGuid:{in:["${value}"]}}]}){items{cardId,expire,alias,statusName,dateUpdate,dateBlocked,cardGuid,instrumentId,cardPan,userId} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { accounts, hasNextPage } = this.getGQLResponse(data, '');

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(skip + 500, [...accountList, ...accounts], value)
        : [...accountList, ...accounts];
    } catch (e) {
      throw e;
    }
  };

  fetchAllGQL = async () => {
    const {
      dataItem: { userGuid },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const accounts = await this.iterateFetchGQL(0, [], userGuid);
      await this.setState({ isLoading: false, accounts });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate, dataItem } = this.props;

    return (
      <Row className="EasypayUserCardGrid-toolbar">
        <div className="EasypayUserCardGrid-toolbarItem">
          <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.fullName')}</div>
          <Input className="EasypayUserCardGrid-input" value={`${dataItem.firstName} ${dataItem.lastName}`} disabled />
        </div>

        <div className="EasypayUserCardGrid-toolbarItem">
          <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.phoneNumber')}</div>
          <Input className="EasypayUserCardGrid-phoneInput" value={`${dataItem.phone || ''}`} disabled />
        </div>
      </Row>
    );
  };

  getHandlers = () => {
    const { dataItem: dataItemFromProps } = this.props;

    return [
      {
        title: 'page.easypayUsersManage.cardTransactions',
        action: ({ dataItem }) =>
          tabService.addTab({ type: 'cardInfo', dataItem: { ...dataItemFromProps, ...dataItem } }),
        dropdown: false,
        show: true,
      },
    ];
  };

  render() {
    const { accounts, isLoading } = this.state;

    return (
      <Grid
        data={accounts}
        name="easypayUserCardGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAllGQL}
        isLoading={isLoading}
      >
        <GridColumn
          field="cardId"
          title="page.easypayUsersManage.cardNumber"
          width="150"
          handlers={this.getHandlers()}
          dropdown={checkElement('easypayUsersManage-cardTransaction', this.currentArray)}
        />
        <GridColumn field="expire" title="Expire" width="100" />
        <GridColumn field="alias" title="page.easypayUsersManage.alias" width="180" />
        <GridColumn field="statusName" title="page.easypayUsersManage.status" width="140" />
        <GridColumn
          field="dateUpdate"
          title="page.easypayUsersManage.changeDate"
          width="160"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn
          field="dateBlocked"
          title="page.easypayUsersManage.blockDate"
          width="160"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="cardGuid" title="Card_Guid" width="300" />
        <GridColumn field="instrumentId" title="page.easypayUsersManage.toolNumber" width="180" />
        <GridColumn field="cardPan" title="page.easypayUsersManage.systemCardNumber" width="200" />
        <GridColumn field="userId" title="page.easypayUsersManage.userNumber" width="170" />
      </Grid>
    );
  }
}

export default withTranslate(EasypayUserCardGrid);
