import React, { PureComponent } from 'react';
import { Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';

import SuspendedFinancialTransactionsGridToolbar from './SuspendedFinancialTransactionsGridToolbar';
import { INITIAL_VALUES } from './constants';
import FormStateContainer from '../../../components/FormStateContainer';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { ApiService, tabService } from '../../../services';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import { withTranslate } from '../../../contexts/localContext';

const api = new ApiService();

class SuspendedFinancialTransactionsGrid extends PureComponent {
  currentArray = findArray('externalOutgoingPayments');

  state = {
    operations: [],
    isLoading: false,
    dialogTypeVisible: '',
    selectedItems: [],
    reloadAttemptSlug: 0,
  };

  setOperations = (operations) => {
    this.setState({ operations });
  };

  buildToolbar = () => {
    const { selectedItems, reloadAttemptSlug } = this.state;

    return (
      <Row>
        <FormStateContainer initialValues={INITIAL_VALUES}>
          <SuspendedFinancialTransactionsGridToolbar
            selectedItems={selectedItems}
            setOperations={this.setOperations}
            reloadAttemptSlug={reloadAttemptSlug}
            setLoading={(isLoading) => {
              this.setState({ isLoading });
            }}
          />
        </FormStateContainer>
      </Row>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handlePaymentActionsGridTabOpen = ({ dataItem: { PaymentId } }) => {
    tabService.addTab({
      type: 'editExternalOutgoingPayments',
      paymentId: PaymentId,
    });
  };

  render() {
    const { operations, isLoading } = this.state;

    if (!checkElement('externalOutgoingPayments-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={operations}
          onRefresh={() => {
            this.setState({ reloadAttemptSlug: Math.random() });
          }}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="suspendedFinancialTransactionsGrid"
          fieldForSelection="PaymentId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn
            field="PaymentId"
            title="page.suspendedFinancialTransactions.col-paymentID"
            width="120"
            filter="numeric"
            {...(checkElement('externalOutgoingPayments-editPayment', this.currentArray)
              ? {
                  onClick: this.handlePaymentActionsGridTabOpen,
                }
              : {})}
          />
          <GridColumn field="SupplierName" title="page.suspendedFinancialTransactions.col-provider" width="180" />
          <GridColumn
            field="Number"
            title="page.suspendedFinancialTransactions.col-paymentId"
            width="240"
            filter="numeric"
          />
          <GridColumn field="Amount" title="page.suspendedFinancialTransactions.col-sum" width="120" filter="numeric" />
          <GridColumn field="Currency" title="page.suspendedFinancialTransactions.col-сurrency" width="120" />
          <GridColumn
            field="PaymentDate"
            title="page.suspendedFinancialTransactions.col-dateOfPayment"
            width="160"
            cell={({ dataItem: { PaymentDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(PaymentDate)}</td>
            )}
            formatFilterCellData={(PaymentDate) => getTransformDateString(PaymentDate)}
          />
          <GridColumn
            field="WorkDate"
            title="page.suspendedFinancialTransactions.col-reportDate"
            width="160"
            cell={({ dataItem: { WorkDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(WorkDate)}</td>
            )}
            formatFilterCellData={(WorkDate) => getTransformDateString(WorkDate)}
          />
          <GridColumn
            field="PaymentDateReceipt"
            title="page.suspendedFinancialTransactions.col-dateOfAcceptance"
            width="160"
            cell={({ dataItem: { PaymentDateReceipt } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(PaymentDateReceipt)}</td>
            )}
            formatFilterCellData={(PaymentDateReceipt) => getTransformDateString(PaymentDateReceipt)}
          />
          <GridColumn
            field="DatePost"
            title="page.suspendedFinancialTransactions.col-recordDate"
            width="160"
            cell={({ dataItem: { DatePost } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
            )}
            formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          />
          <GridColumn
            field="DateSignature"
            title="page.suspendedFinancialTransactions.col-dateOfSignature"
            width="160"
            cell={({ dataItem: { DateSignature } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(DateSignature)}</td>
            )}
            formatFilterCellData={(DateSignature) => getTransformDateString(DateSignature)}
          />
          <GridColumn field="AMfo" title="page.suspendedFinancialTransactions.col-senderMFO" width="240" />
          <GridColumn field="ABankName" title="page.suspendedFinancialTransactions.col-senderBank" width="240" />
          <GridColumn
            field="AAccount"
            title="page.suspendedFinancialTransactions.col-senderAccountId"
            width="240"
            filter="numeric"
          />
          <GridColumn field="AIban" title="page.suspendedFinancialTransactions.col-senderIBan" width="240" />
          <GridColumn field="AName" title="page.suspendedFinancialTransactions.col-senderName" width="240" />
          <GridColumn field="AIdClient" title="page.suspendedFinancialTransactions.col-senderEDRPOUCode" width="240" />
          <GridColumn field="BMfo" title="page.suspendedFinancialTransactions.col-recipientMFI" width="240" />
          <GridColumn field="BBankName" title="page.suspendedFinancialTransactions.col-recipientBank" width="240" />
          <GridColumn
            field="BAccount"
            title="page.suspendedFinancialTransactions.col-recipientAccountId"
            width="240"
            filter="numeric"
          />
          <GridColumn field="BIban" title="page.suspendedFinancialTransactions.col-recipientIBan" width="240" />
          <GridColumn field="BName" title="page.suspendedFinancialTransactions.col-recipientName" width="240" />
          <GridColumn
            field="BIdClient"
            title="page.suspendedFinancialTransactions.col-recipientEDRPOUCode"
            width="240"
          />
          <GridColumn field="Narrative" title="page.suspendedFinancialTransactions.col-purposePayment" width="180" />
          <GridColumn field="StatusName" title="page.suspendedFinancialTransactions.col-status" width="120" />
          <GridColumn field="UserNamePost" title="page.suspendedFinancialTransactions.col-performer" width="160" />
          <GridColumn field="Comments" title="page.suspendedFinancialTransactions.col-comment" width="160" />
          <GridColumn field="ErrorMsg" title="page.suspendedFinancialTransactions.col-error" width="120" />
          <GridColumn field="type" title="page.suspendedFinancialTransactions.col-type" width="120" />
          <GridColumn
            field="epTransactionId"
            title="page.suspendedFinancialTransactions.col-epTransactionId"
            width="120"
          />
        </Grid>
      </>
    );
  }
}

export default withTranslate(SuspendedFinancialTransactionsGrid);
