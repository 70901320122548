import React, { PureComponent } from 'react';
import {  Button, message, Modal } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class DeleteProjectModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteProject = async () => {
    const { translate, selectedItems, closeModal, onRefresh } = this.props;

    try {
      await this.setState({ isLoading: true });

      const resp = selectedItems.map((item) => ({
        ownerId: item.ownerId,
        project: item.project,
      }));

      const data = await api.ownersMfoPropertiesProjectDelete(resp);
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }
      message.success(translate('page.MFODealer.deleteProjectSuccessful'), 2.5);

      await this.setState({
        isLoading: false,
      });
      onRefresh();
      closeModal();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
      closeModal();
    }
  };
  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteProject}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button key="back" onClick={closeModal} style={{ margin: 'auto' }}>
            {translate('core.buttonTitles.back')}
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>{translate('page.MFODealer.deleteProjectText')}</div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteProjectModal);
