import React, { PureComponent } from 'react';
import { Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import { ApiService, StorageService, tabService } from '../../../services';
import GqlService from '../../../components/grids/gql.service';
import { ALL_GRID_COLUMNS } from './constants';
import OTPServicesGridService from './OTPServicesGridService';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';
import EditableGridInput from '../../../components/grids/EditableGridInput';
import DeleteModal from './DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

import './OTPServicesGrid.scss';

const api = new ApiService();

class OTPServicesGrid extends PureComponent {
  currentArray = findArray('OTPServices');

  state = {
    NBUCodes: [],
    isLoading: false,
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    selectIdsString: undefined,
    currentEditRow: null,
    selectedItems: [],
    modalVariant: '',
  };

  componentDidMount() {
    this.fetchNBUCodesWithOTPGQL();

    document.addEventListener('keyup', this.handleOnDisabled);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleOnDisabled);
  }

  handleOnDisabled = (e) => {
    const { keyCode, key } = e;

    if (keyCode === 27 || key === 'Escape') {
      this.setState(({ NBUCodes }) => ({
        currentEditRow: null,
        NBUCodes: NBUCodes.filter(({ editId }) => editId !== '-1'),
      }));
    }
  };

  onFieldsConfigChange = (list) => {
    this.fetchNBUCodesWithOTPGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  fetchNBUCodesWithOTPGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString } = this.state;

    try {
      this.setState({ isLoading: true });
      const storageColumns = StorageService.getItem('OTPServicesGrid');

      const currentColumns =
        (props && props.currentFieldList) ||
        (storageColumns ? [...GqlService.getColumnsFromStorage(storageColumns), 'id'] : ALL_GRID_COLUMNS);

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)},order: ${
        sortString || '{id: ASC}'
      }${filtersString ? `,where:{and:[${filtersString}]}` : ''}`;

      const query = `servicesOtp(${argumentsString}){items{${currentColumns.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { NBUCodes, hasPreviousPage, hasNextPage } = OTPServicesGridService.getGQLResponse(data);

      this.setState({ NBUCodes: NBUCodes, hasPreviousPage, hasNextPage, isLoading: false, currentEditRow: null });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
        currentEditRow: null,
      });

      await this.fetchNBUCodesWithOTPGQL();
    }
  }

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleAdd = () => {
    const { NBUCodes } = this.state;
    const emptyItem = { id: 0, f108: null, otp: false, editId: '-1' };

    this.setState({
      NBUCodes: [emptyItem, ...NBUCodes],
      currentEditRow: emptyItem,
    });
  };

  handleDelete = () => {
    const { selectedItems } = this.state;

    this.setState({
      modalVariant: selectedItems.find(({ editId }) => editId === '-1') ? 'forbiddenDelete' : 'allowDelete',
    });
  };

  updateNBUCodes = async () => {
    const { currentEditRow } = this.state;
    const { id, f108, otp, editId } = currentEditRow;

    const query = `${
      editId === '-1' ? 'serviceOtpEntityAdd' : 'serviceOtpEntityUpdate'
    }(input: { serviceOtpEntity:{id: ${editId === '-1' ? 0 : id}, f108: ${
      !f108 && f108 !== 0 ? null : `"${f108}"`
    }, otp: ${otp}} }){serviceOtp { id,f108,otp }}`;

    try {
      this.setState({ isLoading: true });
      await api.mutationByGraphQl(query);
      await this.fetchNBUCodesWithOTPGQL();
      this.setState({ isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }

    return null;
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { currentEditRow, selectedItems } = this.state;

    return (
      <Row className="OTPServicesGrid-toolbar">
        {checkElement('OTPServices-addEdit', this.currentArray) && (
          <div className="OTPServicesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              onClick={this.handleAdd}
              disabled={currentEditRow || selectedItems.length}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('OTPServices-deleteEdit', this.currentArray) && (
          <div className="OTPServicesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              disabled={!selectedItems.length}
              onClick={this.handleDelete}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}

        {checkElement('OTPServices-saveEdit', this.currentArray) && (
          <div className="OTPServicesGrid-buttonWrapper">
            <Button
              className="OTPServicesGrid-button"
              type="primary"
              disabled={!currentEditRow || selectedItems.length}
              onClick={this.updateNBUCodes}
            >
              {translate('core.buttonTitles.save')}
            </Button>
          </div>
        )}

        {checkElement('OTPServices-history', this.currentArray) && (
          <div className="OTPServicesGrid-buttonWrapper">
            <Button
              type="primary"
              className="OTPServicesGrid-button"
              onClick={() => {
                tabService.addTab({
                  type: 'history',
                });
              }}
            >
              {translate('page.OTPServices.history')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  getCheckboxCell = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return (
      <td align="center" style={{ textAlign: 'center' }} className="OTPServicesGrid-checkboxCell">
        <EditableGridInput
          type={'checkbox'}
          disabled={
            (currentEditRow && currentEditRow.editId !== dataItem.editId) ||
            selectedItems.length ||
            !checkElement('OTPServices-saveEdit', this.currentArray)
          }
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    );
  };

  cellInput = ({ dataItem, field }) => {
    const { currentEditRow, selectedItems } = this.state;

    const currentDataItem = currentEditRow && currentEditRow.editId === dataItem.editId ? currentEditRow : dataItem;

    return checkElement('OTPServices-saveEdit', this.currentArray) ? (
      <td align="center" style={{ textAlign: 'center' }}>
        <EditableGridInput
          disabled={(currentEditRow && currentEditRow.editId !== dataItem.editId) || selectedItems.length}
          value={currentDataItem[field]}
          onChange={(value) => {
            this.setState({ currentEditRow: { ...currentDataItem, [field]: value } });
          }}
        />
      </td>
    ) : (
      <td>{currentDataItem[field]}</td>
    );
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { NBUCodes, isLoading, pageValue, hasNextPage, hasPreviousPage, modalVariant, selectedItems } = this.state;

    if (!checkElement('OTPServices-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={NBUCodes}
          onRefresh={this.fetchNBUCodesWithOTPGQL}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          name="OTPServicesGrid"
          pageChange={this.getStateSetterByName('pageValue')}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onFieldsConfigChange={this.onFieldsConfigChange}
          fieldForSelection="editId"
          onSelect={this.handleSelectionChange}
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            className="OTPServicesGrid-checkboxCell"
          />
          <GridColumn field="f108" title="page.OTPServices.col-NBU" width="150" cell={this.cellInput} />
          <GridColumn field="otp" title="page.OTPServices.col-OTPCode" width="150" cell={this.getCheckboxCell} />
        </Grid>

        {modalVariant && (
          <DeleteModal
            variant={modalVariant}
            closeModal={() => {
              this.setState({ modalVariant: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.fetchNBUCodesWithOTPGQL}
          />
        )}
      </>
    );
  }
}

export default withTranslate(OTPServicesGrid);
