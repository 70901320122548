import React, { PureComponent } from 'react';
import { Button, Row, Select, Input } from 'antd';

import { ApiService, tabService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { SEARCH_PARAMS, GQL_FIELDS, GQL_KEY_BY_MAP, GQL_METHOD_BY_MAP } from './constants';
import EasypayUsersManageService from './EasypayUsersManage.service';

import './EasypayUsersManageGrid.styled.scss';

const { Option } = Select;
const api = new ApiService();

class EasypayUsersManageGrid extends PureComponent {
  currentArray = findArray('easypayUsersManage');

  state = {
    isLoading: false,
    selectedSearchParams: 1,
    users: [],
    value: '',
    errorMessage: '',
  };

  iterateFetchGQL = async (skip, userList, value, key, method) => {
    try {
      const query = `${method}(take: 500,skip:${skip},where:{and:[{${key}:{in:[${value}]}}]}){items{${GQL_FIELDS.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { users, hasNextPage } = EasypayUsersManageService.getGQLResponse(data, method);

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(skip + 500, [...userList, ...users], value)
        : [...userList, ...users];
    } catch (e) {
      throw e;
    }
  };

  fetchAllGQL = async (selectedSearchParams, value) => {
    try {
      await this.setState({ isLoading: true });
      const users = await this.iterateFetchGQL(
        0,
        [],
        EasypayUsersManageService.formatValue(value, selectedSearchParams),
        GQL_KEY_BY_MAP.get(selectedSearchParams),
        GQL_METHOD_BY_MAP.get(selectedSearchParams),
      );
      await this.setState({ isLoading: false, users });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchUsers = async () => {
    const { selectedSearchParams, value } = this.state;

    if (!value) {
      return;
    }

    const validator = EasypayUsersManageService.getValidationBySearchParams()[selectedSearchParams];

    const error = validator(value);

    if (error) {
      this.setState({ errorMessage: error });
      return;
    }

    await this.fetchAllGQL(selectedSearchParams, value);
  };

  renderInputByParam = () => {
    const { value } = this.state;

    return (
      <Input
        value={value}
        onChange={({ target: { value } }) => {
          this.setState({ value, errorMessage: '' });
        }}
        className="EasypayUsersManageGrid-select"
      />
    );
  };

  buildToolbar = () => {
    const { selectedSearchParams, value, errorMessage } = this.state;
    const { translate } = this.props;

    return (
      <Row className="EasypayUsersManageGrid-toolbar">
        <div className="EasypayUsersManageGrid-fileds">
          <div className="EasypayUsersManageGrid-filedRow">
            <div className="EasypayUsersManageGrid-label">{translate('page.easypayUsersManage.searchParam')}</div>

            <Select
              className="EasypayUsersManageGrid-select"
              value={selectedSearchParams}
              onChange={(selectedSearchParams) => {
                this.setState({ selectedSearchParams, value: '', errorMessage: '', users: [] });
              }}
            >
              {SEARCH_PARAMS.map(({ title, id }) => (
                <Option value={id} key={`EasypayUsersManageGrid-searchParamOption-${id}`}>
                  {translate(title)}
                </Option>
              ))}
            </Select>
          </div>

          <div className="EasypayUsersManageGrid-filedRow">
            <div className="EasypayUsersManageGrid-shortLabel">{translate('page.easypayUsersManage.value')}</div>

            {this.renderInputByParam()}
          </div>
        </div>

        <div className="EasypayUsersManageGrid-action">
          <Button type="primary" onClick={this.fetchUsers} disabled={!value || !!errorMessage}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {errorMessage && <div className="EasypayUsersManageGrid-error">{translate(errorMessage)}</div>}
      </Row>
    );
  };

  getHandlers = () => {
    return [
      {
        title: 'page.easypayUsersManage.accountNumbers',
        action: ({ dataItem }) => tabService.addTab({ type: 'accountNumbers', dataItem }),
        dropdown: false,
        show: checkElement('easypayUsersManage-wallets', this.currentArray),
      },
      {
        title: 'page.userAudit.userAuditTitle',
        action: ({ dataItem }) => tabService.addTab({ type: 'userAudit', dataItem: dataItem }),
        dropdown: false,
        show: checkElement('easypayUsersManage-userAudit', this.currentArray),
      },
      {
        title: 'page.easypayUsersManage.cards',
        action: ({ dataItem }) => tabService.addTab({ type: 'cards', dataItem }),
        dropdown: false,
        show: checkElement('easypayUsersManage-cards', this.currentArray),
      },
      {
        title: 'page.easypayUsersManage.transactionHistory',
        action: ({ dataItem }) => tabService.addTab({ type: 'transactionHistory', dataItem }),
        dropdown: false,
        show: checkElement('easypayUsersManage-transactionHistory', this.currentArray),
      },
      {
        title: 'page.userRatingGrid.userRatingGridTitle',
        action: ({ dataItem }) => tabService.addTab({ type: 'userRatingGrid', dataItem }),
        show: checkElement('easypayUsersManage-rating', this.currentArray),
      },
    ];
  };

  render() {
    const { users, isLoading } = this.state;

    if (!checkElement('easypayUsersManage-content', this.currentArray)) {
      return null;
    }

    const isDropdownShow =
      checkElement('easypayUsersManage-wallets', this.currentArray) ||
      checkElement('easypayUsersManage-userAudit', this.currentArray) ||
      checkElement('easypayUsersManage-cards', this.currentArray) ||
      checkElement('easypayUsersManage-transactionHistory', this.currentArray) ||
      checkElement('easypayUsersManage-rating', this.currentArray);

    return (
      <Grid
        data={users}
        name="easypayUsersManageGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchUsers}
        isLoading={isLoading}
      >
        <GridColumn
          field="userId"
          title="page.easypayUsersManage.userNumber"
          width="180"
          handlers={this.getHandlers()}
          dropdown={isDropdownShow}
        />
        <GridColumn field="phone" title="page.easypayUsersManage.phoneNumber" width="200" />
        <GridColumn field="email" title="page.easypayUsersManage.email" width="200" />
        <GridColumn field="lastName" title="grids.userList.column-lastName" width="150" />
        <GridColumn field="firstName" title="grids.userList.column-firstName" width="150" />
        <GridColumn field="statusName" title="page.easypayUsersManage.status" width="150" />
        <GridColumn field="address" title="grids.userList.column-address" width="150" />
        <GridColumn field="city" title="page.easypayUsersManage.city" width="200" />
        <GridColumn
          field="dateRegister"
          title="page.easypayUsersManage.registrationDate"
          width="200"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(EasypayUsersManageGrid);
