import React, { PureComponent } from 'react';
import { Button, Col, Select, Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import {
  CustomPropsSlug,
  NotTransactionPropertySlug,
  TRANSACTION_FIELDS_INFO_CLIENT_CABINET,
  TRANSACTION_PROPS,
} from './constants';
import { ApiService, tabService } from '../../services';

import { TRANSACTION_STATUSES } from '../../core/constants/transactionStatuses';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const { Option } = Select;
const api = new ApiService();

class OperationHistoryGrid extends PureComponent {
  currentArray = findArray('clientCabinetFinmon');
  state = {
    isLoading: false,
    clientList: [],
    transactionList: [],
    searchType: undefined,
    customSearchType: undefined,
    searchQuery: undefined,
    errorMessage: '',
    selectedStatuses: undefined,
    dateStart: new Date(),
    dateEnd: new Date(),
  };

  fetchClient = async () => {
    const { searchType, searchQuery, selectedStatuses, dateStart, dateEnd, customSearchType } = this.state;
    const { user } = this.props;
    this.setState({ isLoading: true });
    try {
      const model = {
        transactionStatus: selectedStatuses,
        transactionPropertyKey:
          searchType === NotTransactionPropertySlug ? undefined : customSearchType ? customSearchType : searchType,
        transactionPropertyValue: searchType === NotTransactionPropertySlug ? undefined : searchQuery,
        dateStart: getDataByTimezoneOffset(dateStart).toISOString(),
        dateEnd: getDataByTimezoneOffset(dateEnd).toISOString(),
        userGuid: user.UserGuid,
      };

      const clientListData = await api.getClientTransactions(model);

      if (!clientListData) {
        this.setState({ isLoading: false });
        return;
      }
      await this.fetchTransactionInfo(clientListData.map((item) => item.TransactionId));
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchTransactionInfo = async (transactionList) => {
    try {
      const data = await api.getByGraphQl(
        `transactions(order: {transactionId: ASC}, where:{and: [ {transactionId: {in: [${transactionList.join(
          ',',
        )}]}}]}){${Object.keys(TRANSACTION_FIELDS_INFO_CLIENT_CABINET).join()}} `,
      );

      this.setState({
        clientList:
          data && data.data && data.data.transactions && data.data.transactions.length ? data.data.transactions : [],
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  renderBaseGridColumn = ({ field, ...props }) => (
    <GridColumn
      field={field}
      title={TRANSACTION_FIELDS_INFO_CLIENT_CABINET[field].title}
      filter={TRANSACTION_FIELDS_INFO_CLIENT_CABINET[field].filter}
      {...props}
    />
  );

  buildToolbar = () => {
    const { translate, user } = this.props;
    const { searchType, errorMessage, selectedStatuses, dateStart, dateEnd } = this.state;

    return (
      <Row className="OperationHistoryGrid-wrapper">
        <Row>
          <Col className="clientCabinet-toolbar gap">
            <Input
              value={`${user.Name}; ${user.TaxNumber}; ${user.Phone};`}
              className="OperationHistory-Input"
              disabled
            />
          </Col>
          <Col className="clientCabinet-toolbar gap">
            <div className="clientCabinet-fieldRow">
              <div className="clientCabinet-label">{translate('page.changingProfileHistory.dateFrom')}</div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={(e) => {
                    this.setState({ dateStart: e.target.value });
                  }}
                  value={dateStart}
                  // min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                  // max={BlockedTransactionsPageService.getMaxSelectedDate()}
                />
              </div>
            </div>

            <div className="clientCabinet-fieldRow">
              <div className="clientCabinet-label">{translate('page.changingProfileHistory.dateTo')}</div>
              <div>
                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={(e) => {
                    this.setState({ dateEnd: e.target.value });
                  }}
                  value={dateEnd}
                  // min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                  // max={BlockedTransactionsPageService.getMaxSelectedDate()}
                />
              </div>
            </div>

            <Select
              value={selectedStatuses}
              onChange={(selectedStatuses) => {
                this.setState({ selectedStatuses });
              }}
              className="UnsuccessfulPaymentsAnalysisGrid-select"
              placeholder={translate('page.unsuccessfulPaymentsAnalysis.selectStatus')}
            >
              {TRANSACTION_STATUSES.map(({ value, title }) => (
                <Option value={value}>{title}</Option>
              ))}
            </Select>
          </Col>
          <Col className="clientCabinet-toolbar gap">
            <Select
              value={searchType}
              onChange={(searchType) => {
                this.setState({ searchType });
              }}
              placeholder={translate('page.easyPayUsers.searchType')}
              className="UnsuccessfulPaymentsAnalysisGrid-select"
            >
              {TRANSACTION_PROPS.map((option, index) => (
                <Option key={`transactionProps-${index.toString()}`} value={option}>
                  {translate(option)}
                </Option>
              ))}
            </Select>
            {searchType === CustomPropsSlug && (
              <Input
                placeholder={translate(CustomPropsSlug)}
                className="EasyPayUsers-input"
                onChange={({ target: { value: customSearchType } }) => this.setState({ customSearchType })}
              />
            )}
            <div className="clientCabinet-fieldRow-select">
              <Input
                disabled={searchType === NotTransactionPropertySlug || !searchType}
                placeholder={translate('page.easyPayUsers.query')}
                className="EasyPayUsers-input"
                onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery })}
              />
            </div>
          </Col>
        </Row>
        <Col className="clientCabinet-toolbar">
          <Button
            className="k-button k-primary sm-w-100"
            style={{
              padding: '16px',
              height: '48%',
              marginTop: '24px',
              marginLeft: '10px',
            }}
            icon="search"
            primary
            disabled={!dateStart || !dateEnd}
            onClick={this.fetchClient}
          >
            {translate('owner.search')}
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    const { clientList, isLoading } = this.state;

    const aggregate = [
      { field: 'transactionId', aggregate: 'count', format: 'n0' },
      { field: 'amountTotal', aggregate: 'sum', format: 'n2' },
      { field: 'amountOriginal', aggregate: 'sum', format: 'n2' },
    ];

    const isShowTransactionDetails = checkElement(
      'clientCabinetFinmon-operationHistoryTransactionDetails',
      this.currentArray,
    );

    return (
      <Grid
        data={clientList}
        isLoading={isLoading}
        name="operationHistoryGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchClient}
        aggregate={aggregate}
      >
        {this.renderBaseGridColumn({ field: 'ownerName', with: '145' })}
        <GridColumn
          field="transactionId"
          title={TRANSACTION_FIELDS_INFO_CLIENT_CABINET.transactionId.title}
          filter={TRANSACTION_FIELDS_INFO_CLIENT_CABINET.transactionId.filter}
          width="130"
          cell={({ dataItem: { transactionId } }) => (
            <td
              className={isShowTransactionDetails ? 'transactionTd' : ''}
              onClick={
                isShowTransactionDetails
                  ? () => {
                      tabService.addTab({ type: 'transactionDetails', dataItem: { transactionId: transactionId } });
                    }
                  : undefined
              }
            >
              {transactionId}
            </td>
          )}
        />

        {this.renderBaseGridColumn({ field: 'requestId', with: '112' })}
        {this.renderBaseGridColumn({ field: 'terminalId', with: '124' })}
        {this.renderBaseGridColumn({ field: 'serviceId', with: '110' })}
        {this.renderBaseGridColumn({ field: 'serviceName', with: '93' })}
        {this.renderBaseGridColumn({ field: 'amountTotal', with: '134', format: '{0:n2}' })}
        {this.renderBaseGridColumn({ field: 'amountOriginal', with: '180', format: '{0:n2}' })}
        {this.renderBaseGridColumn({ field: 'commissionProvider', with: '154', format: '{0:n2}' })}
        {this.renderBaseGridColumn({ field: 'commissionClient', with: '171', format: '{0:n2}' })}
        {this.renderBaseGridColumn({ field: 'currency', with: '90' })}
        {this.renderBaseGridColumn({ field: 'dateInput', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
        {this.renderBaseGridColumn({ field: 'datePost', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
        {this.renderBaseGridColumn({ field: 'dateDeclined', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
        {this.renderBaseGridColumn({ field: 'dateAccepted', with: '142', format: 'dd.MM.yyyy HH:mm:ss' })}
        {this.renderBaseGridColumn({ field: 'account', with: '112' })}
        {this.renderBaseGridColumn({ field: 'comment', with: '125' })}
        {this.renderBaseGridColumn({ field: 'statusName', with: '91' })}
        {this.renderBaseGridColumn({ field: 'paymentType', with: '125' })}
        {this.renderBaseGridColumn({ field: 'operationName', with: '125' })}
        {this.renderBaseGridColumn({ field: 'terminalType', with: '125' })}
      </Grid>
    );
  }
}

export default withTranslate(OperationHistoryGrid);
