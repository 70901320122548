import React, { PureComponent } from 'react';
import { Modal, Button, message, Input } from 'antd';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import LoadingPanel from '../../../components/loader';
import { MFO_CODES_CONFIG } from '../../MFODealers/MFODealersGrid/constants';
import dropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();

class ChangeCodeModal extends PureComponent {
  state = {
    isLoading: false,
    targetTransfers: '',
    paymentCode: [],
    codes: [],
  };

  componentDidMount() {
    this.fetchPaymentCodes();
  }

  fetchPaymentCodes = async () => {
    const { selectedItems } = this.props;

    try {
      await this.setState({ isLoading: true });

      const data = await api.NBUCodesList();
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        codes: data,
        isLoading: false,
        targetTransfers: selectedItems[0].Name || '',
        paymentCode: [selectedItems[0].F108Code],
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  addTargetTransfers = async () => {
    const { targetTransfers } = this.state;
    const { onClose, translate, onRefresh, selectedItems } = this.props;

    await this.setState({ isLoading: true });

    const model = {
      f108Code: selectedItems[0].F108Code,
      f108SubCode: selectedItems[0].F108SubCode,
      name: targetTransfers,
      isActive: selectedItems[0].IsActive,
    };

    try {
      await api.NBUSubCodeMerge(model);
      this.setState({ isLoading: false });
      onClose();
      message.success(translate('page.paymentTypeNBUCode.targetTransferSuccessfullyChanged'), 3);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const { isLoading, targetTransfers, paymentCode, codes } = this.state;

    return (
      <Modal
        title={translate('page.paymentTypeNBUCode.changeTargetTransfer')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.addTargetTransfers} disabled={!targetTransfers}>
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={onClose}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
        className="AddCodeModal"
      >
        <>
          <div>
            <div className="AddCodeModal-label">{translate('page.MFODealer.paymentDestinationCode')}:</div>
            <div className="AddCodeModal-selectWrapper">
              <GridDropdown
                data={dropdownNormalizersService.normalizeCodes(codes)}
                colConfig={MFO_CODES_CONFIG}
                selectItems={paymentCode}
                disabled
                defaultTitle={translate('page.MFODealer.chosePaymentDestinationCode')}
                isSingle
              />
            </div>

            <div className="AddCodeModal-label">{translate('page.paymentTypeNBUCode.targetTransfer')}:</div>
            <div className="AddCodeModal-selectWrapper">
              <Input
                value={targetTransfers}
                onChange={({ target: { value } }) => {
                  this.setState({ targetTransfers: value });
                }}
              />
            </div>
          </div>

          {isLoading && <LoadingPanel />}
        </>
      </Modal>
    );
  }
}

export default withTranslate(ChangeCodeModal);
