import React, { PureComponent } from 'react';
import { Button, Col, Select, Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { SEARCH_TYPES } from './constants';
import { ApiService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import './AuditSite.style.scss';

const { Option } = Select;
const api = new ApiService();

class AuditSite extends PureComponent {
  currentArray = findArray('auditSite');

  state = {
    isLoading: false,
    siteList: [],
    searchType: undefined,
    searchQuery: '',
    errorMessage: '',
    dateStart: new Date(),
    dateEnd: new Date(),
  };

  fetchAuthorizationAuditList = async () => {
    const { searchType, searchQuery, dateStart, dateEnd } = this.state;

    try {
      await this.setState({ isLoading: true });

      const list = await api.getUsersAudit({
        key: searchType || 0,
        value: searchQuery || '',
        dateStart: getDataByTimezoneOffset(StandardizeTimeForDate(dateStart, true)).toISOString(),
        dateEnd: getDataByTimezoneOffset(StandardizeTimeForDate(dateEnd)).toISOString(),
      });

      this.setState({
        isLoading: false,
        siteList: list,
      });
    } catch (err) {
      const { showError } = this.props;
      showError(err);
      this.setState({ isLoading: false });
    }
  };

  getDateChangeCallback = (fieldName) => (e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: this.getSubmitErrorsByDate(dateStart, dateEnd),
        }));
      },
    );
  };

  getSubmitErrorsByDate = (startDate, endDate) => {
    const { translate } = this.props;
    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = StandardizeTimeForDate(new Date(2007, 0, 1), true);
    const maxSelectedDate = StandardizeTimeForDate(new Date());

    if (
      minimumSelectedDate - normalizedStartDate > 0 ||
      minimumSelectedDate - normalizedEndDate > 0 ||
      normalizedStartDate - maxSelectedDate > 0 ||
      normalizedEndDate - maxSelectedDate > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { searchType, errorMessage, dateStart, dateEnd } = this.state;

    return (
      <Row className="AuditSite-toolbar">
        <div>
          <Col className="EasyPayUsers-toolbar">
            <div className="EasyPayUsers-fieldRow">
              <div className="EasyPayUsers-label">{translate('page.easyPayUsers.searchType')}</div>
              <Select
                value={searchType}
                placeholder={translate('page.easyPayUsers.selectSearchType')}
                className="EasyPayUsers-select"
                onChange={(searchType) => {
                  this.setState({ searchType, siteList: [] });
                }}
                allowClear
              >
                {SEARCH_TYPES.map(({ translateTitle, title, id }) => (
                  <Option value={id} key={id}>
                    {title || translate(translateTitle)}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="EasyPayUsers-fieldRow">
              <div className="EasyPayUsers-shortLabel">{translate('page.easyPayUsers.value')}</div>
              <Input
                placeholder={translate('page.easyPayUsers.query')}
                className="EasyPayUsers-input"
                onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery, siteList: [] })}
              />
            </div>
          </Col>

          <div className="AuditSite-dateRow">
            <div className="AuditSite-dateFieldRow">
              <div className="AuditSite-dateLabel">{translate('page.changingProfileHistory.dateFrom')}</div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>
            <div className="AuditSite-dateFieldRow">
              <div className="AuditSite-dateLabel">{translate('page.changingProfileHistory.dateTo')}</div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>
          </div>
        </div>
        <Button type="primary" disabled={!!errorMessage} onClick={this.fetchAuthorizationAuditList}>
          {translate('core.buttonTitles.search')}
        </Button>
        {errorMessage && <div className="AuditSite-errorMessage">{errorMessage}</div>}
      </Row>
    );
  };

  render() {
    const { siteList, isLoading } = this.state;
    if (!checkElement('auditSite-content', this.currentArray)) {
      return null;
    }
    return (
      <Grid
        data={siteList}
        isLoading={isLoading}
        name="AuditSiteGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAuthorizationAuditList}
      >
        <GridColumn field="Id" title="page.auditSite.PpNumber" width="50px" />
        <GridColumn field="Operation" title="page.auditSite.operationType" width="200px" />
        <GridColumn field="InstrumentName" title="page.auditSite.tool" width="240px" />
        <GridColumn field="InstrumentValue" title="page.auditSite.toolValue" width="200px" />
        <GridColumn field="CardName" title="page.auditSite.cardName" width="160px" />
        <GridColumn field="IP" title="IP" width="160px" />
        <GridColumn field="Amount" title="page.auditSite.amount" width="80px" />
        <GridColumn field="Status" title="page.auditSite.status" width="80px" />
        <GridColumn
          field="DatePost"
          title="grids.accountsDetail.column-date"
          width="240px"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
        />
        <GridColumn field="UserAgent" title="page.auditSite.agent" width="160px" />
        <GridColumn field="ErrorMessage" title="page.auditSite.error" width="160px" />
        <GridColumn field="RestrictionRule" title="page.auditSite.restrictionRule" width="160px" />
        <GridColumn field="UserId" title="page.profilesMenu.userId" width="160px" />
        <GridColumn field="ServiceName" title="page.fishkaService.col-serviceName" width="160px" />
        <GridColumn field="ServiceId" title="page.fishkaService.col-serviceID" width="160px" />

        <GridColumn field="PhoneNumber" title="grids.userProperties.column-phone" width="180px" />
      </Grid>
    );
  }
}

export default withTranslate(AuditSite);
