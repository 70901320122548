import { getTransformDateString } from '../../utils/getTransformDateString';
import menuProfile from '../../components/MenuProfiles';

class DropdownNormalizersService {
  normalizeOwners(owners) {
    if (!owners) {
      return [];
    }

    return owners.map((owners) => ({
      ...owners,
      Id: owners.OwnerId,
      Name: owners.ShortName || owners.OwnerName,
    }));
  }

  normalizeNonDeleteOwners = (owners) =>
    this.normalizeOwners(owners).filter(({ OwnerStatus }) => OwnerStatus !== 'Удален');

  normalizePaymentTypes(paymentTypes) {
    if (!paymentTypes.length) {
      return [];
    }

    return paymentTypes.map((paymentType) => ({
      ...paymentType,
      Id: paymentType.PaymentTypeId,
      Name: paymentType.PaymentType,
    }));
  }

  normalizePaymentTypeCode(paymentTypes) {
    if (!paymentTypes.length) {
      return [];
    }
    return paymentTypes.map((paymentType) => ({
      ...paymentType,
      Id: paymentType.PaymentType,
      Name: paymentType.PaymentTypeName,
    }));
  }
  normalizePaymentTypeAll(paymentTypes) {
    if (!paymentTypes.length) {
      return [];
    }
    return paymentTypes.map((paymentType) => ({
      ...paymentType,
      Id: paymentType.Code,
      Name: paymentType.Name,
    }));
  }
  normalizeProfiles(profiles, translateCallback) {
    if (!profiles || !profiles.length) {
      return [];
    }

    return profiles.map((profile) => ({
      ...profile,
      Id: profile.ProfileId,
      Name: profile.ProfileName,
      Visibility: profile.Visibility
        ? translateCallback('page.commissionsServices.visibleEveryone')
        : translateCallback('page.commissionsServices.limited'),
    }));
  }

  normalizeServices(services) {
    if (!services || !services.length) {
      return [];
    }

    return services.map((service) => ({
      ...service,
      Id: service.ServiceId,
      Name: service.ServiceName || service.Name,
    }));
  }

  normalizeDealerCommissionsServices = (services) => {
    if (!services || !services.length) {
      return [];
    }

    return services.map((service) => ({
      ...service,
      Id: service.Id,
      Name: service.ServiceName,
    }));
  };

  normalizeServiceOwners = (serviceOwners) => {
    if (!(serviceOwners || serviceOwners.length)) {
      return [];
    }
    return serviceOwners.map((serviceOwner) => ({
      ...serviceOwner,
      Id: serviceOwner.SodId,
      Name: serviceOwner.Name,
    }));
  };
  normalizePsAccount = (psAccounts) => {
    if (!(psAccounts || psAccounts.length)) {
      return [];
    }

    return psAccounts.map((psAccount) => ({
      ...psAccount,
      Id: psAccount.AccountId,
      Name: psAccount.Name,
    }));
  };
  normalizeContracts = (contracts) => {
    if (!(contracts || contracts.length)) {
      return [];
    }

    return contracts.map((contract) => ({
      ...contract,
      TreatyDate: getTransformDateString(contract.TreatyDate),
      Id: contract.TreatyId,
      Name: contract.TreatyNumber,
    }));
  };
  normalizeSuppliers = (suppliers) => {
    if (!(suppliers || suppliers.length)) {
      return [];
    }

    return suppliers.map((supplier) => ({
      ...supplier,
      Id: supplier.SupplierId,
      Name: supplier.Name,
    }));
  };
  normalizePaymentSystems = (paymentSystems) => {
    if (!(paymentSystems || paymentSystems.length)) {
      return [];
    }
    return paymentSystems.map((paymentSystem) => ({
      ...paymentSystem,
      Id: paymentSystem.psd_id,
      Name: paymentSystem.NamePaymentSystem,
    }));
  };
  normalizeTerminalList = (terminals) => {
    if (!(terminals || terminals.length)) {
      return [];
    }
    return terminals.map((terminal) => ({
      ...terminal,
      Id: terminal.TerminalId,
      Name: terminal.TerminalId,
    }));
  };

  normalizeTemplateList(templateList) {
    if (!templateList || !templateList.length) {
      return [];
    }

    return templateList
      .sort(({ DatePost: prevDatePost }, { DatePost: nextDatePost }) => new Date(nextDatePost) - new Date(prevDatePost))
      .map((template) => ({
        ...template,
        Id: template.TemplateId,
        Name: template.TemplateName,
        DatePost: getTransformDateString(template.DatePost),
      }));
  }

  normalizeTechnicalPartners = (technicalPartners) => {
    if (!(technicalPartners || technicalPartners.length)) {
      return [];
    }
    return technicalPartners.map((technicalPartners) => ({
      ...technicalPartners,
      Id: technicalPartners.Id,
      Name: technicalPartners.Name,
    }));
  };

  normalizeMenuProfiles = (menuProfiles) => {
    if (!(menuProfiles || menuProfiles.length)) return [];

    return menuProfiles.map((menuProfile) => ({
      ...menuProfile,
      Id: menuProfile.profileId,
      Name: menuProfile.name,
    }));
  };

  normalizeCodes= (codes) => {
    if (!(codes || codes.length)) return [];

    return codes.map((code) => ({
      ...code,
      Id: code.F108Code,
      Name: code.Description,
    }));
  };
}

export default new DropdownNormalizersService();
