import React, { PureComponent } from 'react';
import { Alert, Button, message, Modal } from 'antd';

import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';
import LoadingPanel from '../../../../components/loader';

const api = new ApiService();

class DeleteExpectedChangesModal extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteСallbacks = async () => {
    const { selectedItems, onRefresh, closeModal, translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const deleteData = selectedItems.map((item) => ({
        ownersMfoPropertiesFutureId: item.ownersMfoPropertiesFutureId,
      }));
      await api.ownersMfoPropertiesFutureDelete(deleteData);
      this.setState({ isLoading: false });
      closeModal();
      message.success(translate('page.MFODealer.historyChangesDeleteSuccessful'), 2.5);
      onRefresh();
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }

    await this.setState({ isLoading: false });
  };

  render() {
    const { translate, variant, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.OTPServices.deleting')}
        visible={variant}
        onCancel={closeModal}
        footer={[
          variant !== 'forbiddenDelete' && (
            <Button key="submit" type="danger" onClick={this.deleteСallbacks}>
              {translate('page.commission.btn-delete')}
            </Button>
          ),

          <Button key="back" onClick={closeModal} style={{ margin: 'auto' }}>
            {translate('core.buttonTitles.back')}
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>{translate('page.OTPServices.areYouSure')}</div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteExpectedChangesModal);
