import React, { PureComponent } from 'react';
import { Button, message, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import { withTranslate } from '../../../contexts/localContext';
import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import AddCodeModal from './AddCodeModal';
import ChangeCodeModal from './ChangeCodeModal';

const api = new ApiService();

class PurposeOfTransferGrid extends PureComponent {
  currentArray = findArray('NBUServicesRelations');

  state = {
    codes: [],
    isLoading: false,
    selectedItems: [],
    dialogVisible: '',
  };

  componentDidMount() {
    this.getCodes();
  }

  getCodes = async () => {
    try {
      await this.setState({ isLoading: true });
      const codes = await api.NBUSubCodesList();
      await this.setState({ codes: codes && codes.length ? codes : [] });
      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleSelectionChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handleDeleteCodes = async () => {
    const { selectedItems } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const { F108Code, F108SubCode } = selectedItems[0];
      const model = {
        f108Code: F108Code,
        f108SubCode: F108SubCode,
      };
      await api.NBUSubCodeDelete(model);
      this.getCodes();
      message.success(translate('page.paymentTypeNBUCode.successfullyDelete'), 2);
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  renderTooltip = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Row>
        <div className="PaymentTypeNBUCodeGrid">
          {checkElement('NBUServicesRelations-purposeOfTransferAdd', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogVisible: 'addService' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          )}

          {checkElement('NBUServicesRelations-purposeOfTransferDelete', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length}
              onClick={() => {
                this.setState({ dialogVisible: 'deleteService' });
              }}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          )}
          {checkElement('NBUServicesRelations-purposeOfTransferСhange', this.currentArray) && (
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogVisible: 'changeService' });
              }}
              disabled={selectedItems.length !== 1}
            >
              {translate('core.buttonTitles.change')}
            </Button>
          )}
        </div>
      </Row>
    );
  };

  render() {
    const { codes, isLoading, dialogVisible, selectedItems } = this.state;
    const { translate } = this.props;

    return (
      <>
        <Grid
          data={codes}
          onRefresh={this.getCodes}
          toolbar={this.renderTooltip()}
          isLoading={isLoading}
          name="purposeOfTransferGrid"
          fieldForSelection="F108SubCode"
          onSelect={this.handleSelectionChange}
          initialSort={{ field: 'DatePost', dir: 'desc' }}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn
            field="F108Code"
            title="page.paymentTypeNBUCode.numberTargetTransfer"
            width="160"
            filter="numeric"
          />
          <GridColumn field="F108Name" title="page.paymentTypeNBUCode.nameTargetTransfer" width="800" />
          <GridColumn field="Name" title="page.paymentTypeNBUCode.purposeOfTransfer" width="470" />
        </Grid>

        {dialogVisible === 'addService' && (
          <AddCodeModal
            visible={dialogVisible === 'addService'}
            onClose={() => {
              this.setState({ dialogVisible: '' });
            }}
            onRefresh={this.getCodes}
          />
        )}

        {dialogVisible === 'deleteService' && (
          <DeleteModal
            visible={dialogVisible === 'deleteService'}
            closeModal={() => {
              this.setState({ dialogVisible: '' });
            }}
            submitCallback={this.handleDeleteCodes}
            deleteContent={translate('page.paymentTypeNBUCode.areYouSure')}
          />
        )}

        {dialogVisible === 'changeService' && (
          <ChangeCodeModal
            visible={dialogVisible === 'changeService'}
            onClose={() => {
              this.setState({ dialogVisible: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.getCodes}
          />
        )}
      </>
    );
  }
}

export default withTranslate(PurposeOfTransferGrid);
