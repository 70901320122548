import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

class UnsuccessfulPaymentsAnalysisService {
  getStartValue = (date) =>
    new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 0, 0, 0, 0);

  getEndValue = (date) =>
    new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), 23, 59, 59, 999);

  getMinimumSelectedDate = () =>
    new Date(new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate() - 1, 0, 0, 0, 0));

  getMaxSelectedDate = () =>
    new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 23, 59, 59, 999));

  getValidateDate = (startDateFromProps, endDateFromProps, translate) => {
    const startDate = this.getStartValue(startDateFromProps);
    const endDate = this.getEndValue(endDateFromProps);

    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (startDate - endDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = this.getMinimumSelectedDate();
    const maxSelectedDate = this.getMaxSelectedDate();

    if (
      minimumSelectedDate - startDate > 0 ||
      minimumSelectedDate - endDate > 0 ||
      startDate - maxSelectedDate > 0 ||
      endDate - maxSelectedDate > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    if (getDataByTimezoneOffset(endDate) - getDataByTimezoneOffset(startDate) > 31 * 24 * 60 * 60 * 1000) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };
}

export default new UnsuccessfulPaymentsAnalysisService();
