class EasypayUsersManageService {
  validatePhone = (value) => {
    if (!value) {
      return '';
    }

    if (value.split('').some((char) => isNaN(+char) && char !== '*')) {
      return 'page.easypayUsersManage.invalidValueFormat';
    }

    if (value.length < 4 || value.length > 12) {
      return 'page.easypayUsersManage.invalidValueFormat';
    }

    //check that mobile number start with 380
    if (value.length > 9) {
      const diff = 12 - value.length;

      if (value.slice(0, 3 - diff) !== '380'.slice(diff)) {
        return 'page.easypayUsersManage.invalidValueFormat';
      }
    }

    return '';
  };

  validateEmail = (value) => {
    if (!value) {
      return '';
    }

    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
      ? ''
      : 'page.easypayUsersManage.invalidValueFormat';
  };

  validateAccount = (value) => {
    if (!value) {
      return '';
    }

    if (value.split('').some((char) => isNaN(+char)) || value.length > 50) {
      return 'page.easypayUsersManage.invalidValueFormat';
    }

    return '';
  };

  validateCardNumber = (value) => {
    if (!value) {
      return '';
    }

    if (value.split('').some((char) => isNaN(+char) && char !== '*') || value.length > 16 || value.length < 10) {
      return 'page.easypayUsersManage.invalidValueFormat';
    }

    return '';
  };

  formatValue = (value, param) => {
    if (param === 1) {
      if (value.length > 8) {
        const diff = 12 - value.length;

        return `${'380'.slice(0, diff)}${value}`;
      }

      return value;
    }

    if (param === 7 || param === 4) {
      return value;
    }

    return `"${value}"`;
  };

  getGQLResponse = (data, slug) => {
    if (!data || !data.data || !data.data[slug]) {
      return { users: [], hasNextPage: false };
    }
    const userList = data.data[slug];

    const users = userList.items && userList.items.length ? userList.items : [];

    const pageInfo = users.pageInfo;

    return {
      users,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  getValidationBySearchParams = () => ({
    1: this.validatePhone,
    2: this.validateEmail,
    3: this.validateAccount,
    4: this.validateAccount,
    5: this.validateCardNumber,
    6: this.validateAccount,
    7: this.validateAccount,
  });
}

export default new EasypayUsersManageService();
