import React, { PureComponent } from 'react';
import { Col, message, Row } from 'antd';

import { Button } from '@progress/kendo-react-buttons';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getCustomDate } from '../../../utils/getCustomDate';
import CashDeptService from './CashDept.service';
import findArray from '../../../utils/findArrForCurrentPage';

import './cashdept.scss';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import checkElement from '../../../utils/checkElement';
import GridDropdownWithDeletedOwners from '../../../components/GridDropdown/GridDropdownWithDeletedOwners';
import DropdownNormalizersService from '../../../core/normalizers/dropdownNormalizers.service';

const api = new ApiService();
const MIN_VALID_DATE = getCustomDate({
  customTime: { h: 0, m: 0, s: 0, ms: 0 },
  customDayOfMonth: 1,
  customDateMonth: 0,
  customDateFullYear: 2007,
});

class CashDept extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('CashDept');
    this.state = {
      isLoading: false,
      start: new Date(),
      end: new Date(),
      owners: [],
      allOwners: [],
      data: [],
      isButtonDisabled: false,
    };
  }

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  componentDidMount() {
    this.fetchOwners();
  }

  getCashDeptData = async () => {
    const { start, end, owners } = this.state;
    const { translate } = this.props;

    if (start - end > 0) {
      this.setState({ isButtonDisabled: true });

      message.error(translate('page.cashDept.differenceDateError'), 2);

      return;
    }

    await this.toggleLoader(true);

    try {
      const diffDateWarningMessage = CashDeptService.validateDiffDates(translate, start, end);
      if (diffDateWarningMessage) {
        message.error(diffDateWarningMessage, 5);
        return;
      }

      const model = {
        Owners: owners.join(','),
        DateStart: CashDeptService.normalizeDate(start),
        DateEnd: CashDeptService.normalizeDate(end),
      };
      const data = await api.cashDept(model);
      this.setState({ data: CashDeptService.normalizeResponseData(data) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners });
  };

  onDateChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
      isButtonDisabled: false,
    });
  };

  buildToolbar = () => {
    const { start, end, isButtonDisabled, allOwners, owners } = this.state;
    const { translate } = this.props;

    return (
      <Row className="CashDebt-toolbar">
        <Col span={24} xl={16} className="CashDebt-toolbarRow">
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(allOwners)}
            selectItems={owners}
            onSave={this.onOwners}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isSingle
            isGetDefaultSelectItemsFromStorage
          />
        </Col>

        <Col span={24} xl={16} className="CashDebt-toolbarRow">
          <div className="CashDebt-datePickersRow">
            <div className="CashDebt-datePickerRow">
              <div className="CashDebt-label">{translate('grids.comission-sub.validity-period-date-start')}</div>
              <DatePicker
                format="yyyy.MM.dd"
                className="CashDebt-datePicker"
                onChange={(e) => {
                  this.onDateChange(e, 'start');
                }}
                value={start}
                min={MIN_VALID_DATE}
              />
            </div>

            <div className="CashDebt-datePickerRow">
              <div className="CashDebt-label">{translate('grids.comission-sub.validity-period-date-end')}</div>
              <DatePicker
                format="yyyy.MM.dd"
                className="CashDebt-datePicker"
                value={end}
                min={MIN_VALID_DATE}
                onChange={(e) => {
                  this.onDateChange(e, 'end');
                }}
              />
            </div>
          </div>

          {checkElement('cashDept-search', this.currentArray) && (
            <Button
              icon="search"
              className="CashDebt-button"
              onClick={this.getCashDeptData}
              disabled={isButtonDisabled}
              primary
            >
              {translate('owner.search')}
            </Button>
          )}
        </Col>
      </Row>
    );

    // return (
    //   <Col span={24} lg={18}>
    //     <Row className="CashDept-ownerDropdown">
    //       <DropdownOwner mode="single" onOwnersChange={this.onOwners} />
    //     </Row>
    //     <Row type="flex" justify="space-between">
    //       <Col span={24} md={10}>
    //         <Row type="flex" align="middle" className="CashDept-datePickerRow">
    //           <Col span={4} md={8}>
    //             <p className="">{translate('grids.comission-sub.validity-period-date-start')}</p>
    //           </Col>
    //           <Col span={20} md={16}>
    //             <DatePicker
    //               format="yyyy.MM.dd"
    //               value={start}
    //               min={MIN_VALID_DATE}
    //               onChange={(e) => {
    //                 this.onDateChange(e, 'start');
    //               }}
    //             />
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col span={24} md={10}>
    //         <Row type="flex" align="middle" className="CashDept-datePickerRow">
    //           <Col span={4} md={8}>
    //             <p>{translate('grids.comission-sub.validity-period-date-end')}</p>
    //           </Col>
    //           <Col span={20} md={16}>
    //             <DatePicker
    //               format="yyyy.MM.dd"
    //               value={end}
    //               min={MIN_VALID_DATE}
    //               onChange={(e) => {
    //                 this.onDateChange(e, 'end');
    //               }}
    //             />
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col md={4} lg={4}>
    //         <Row className="CashDept-datePickerRow">
    //           {checkElement('cashDept-search', this.currentArray) && (
    //             <Button icon="search" primary onClick={this.getCashDeptData} disabled={isButtonDisabled}>
    //               {translate('owner.search')}
    //             </Button>
    //           )}
    //         </Row>
    //       </Col>
    //     </Row>
    //   </Col>
    // );
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <>
        <Grid
          data={data}
          name="commissionCashDept-grid"
          isLoading={isLoading}
          toolbar={this.buildToolbar()}
          onRefresh={this.getCashDeptData}
          permissionName="CashDept"
        >
          <GridColumn field="DealerCode" title="grids.cashDept.column-DealerId" width="120" />
          <GridColumn field="DealerName" title="grids.cashDept.column-DealerName" width="120" />
          <GridColumn field="Date" title="grids.cashDept.column-Date" width="120" />
          <GridColumn field="BeginBalans" title="grids.cashDept.column-startingBalance" width="190" />
          <GridColumn field="Collection" title="grids.cashDept.column-CollectionAmount" width="170" />
          <GridColumn field="Enrollment" title="grids.cashDept.column-AmountCredited" width="220" />
          <GridColumn field="Lack" title="grids.cashDept.column-write-off-shortfall" width="170" />
          <GridColumn field="Adjustment" title="grids.cashDept.column-Adjustments" width="170" />
          <GridColumn field="EndBalans" title="grids.cashDept.column-BalanceAtTheEnd" width="170" />
        </Grid>
      </>
    );
  }
}

export default withTranslate(CashDept);
