const auditSite = (translate, value) => {
    switch (value) {
      case 'auditSite-content':
        return `${translate(`page.adminPanel.pageContent`)}`;
     
      default:
        return value;
    }
  };
  
  export default auditSite;