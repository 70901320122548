import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import ClientCabinetGrid from './ClientCabinetGrid';
import OperationHistoryGrid from './OperationHistoryGrid';
import TransactionDetails from './TransactionDetails';

class ClientCabinetPage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-clientCabinet',
        content: <ClientCabinetGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'operationHistory':
        tabs.push({
          title: 'page.clientCabinet.operationHistory',
          content: <OperationHistoryGrid user={dataItem.user} />,
          closable: true,
          key: key,
        });
        break;

      case 'transactionDetails':
        tabs.push({
          title: 'grids.transactionDeatails.transaction',
          content: <TransactionDetails dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
    }
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default ClientCabinetPage;
