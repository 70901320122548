import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Row, Col, Input, Select } from 'antd';
import { formatDate } from '@telerik/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { ApiService, tabService, StorageService } from '../../services';
import { withTranslate } from '../../contexts/localContext';
import { withRouter } from 'react-router-dom';
import DateRange from '../../components/daterange';
import Grid from '../../components/grids/baseGrid';
import checkElement from '../../utils/checkElement';
import findArray from '../../utils/findArrForCurrentPage';
import './FinancialTransactionGrid.scss';
import AlertModal from '../../components/grids/transactions/AlertModal';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import GridDropdownWithDeletedOwners from '../../components/GridDropdown/GridDropdownWithDeletedOwners';

const { Option } = Select;
const api = new ApiService();
const convertToISOFormat = (date) => formatDate(date, 'yyyy.MM.ddTHH:mm:ss');
const aggregate = [
  { field: 'TransactionId', aggregate: 'count', format: 'n0' },
  { field: 'TerminalId', aggregate: 'count', format: 'n0' },
  { field: 'AmountTotal', aggregate: 'sum', format: 'n2' },
  { field: 'AmountOriginal', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionClient', aggregate: 'sum', format: 'n2' },
  { field: 'MoneyTakerAmount', aggregate: 'sum', format: 'n2' },
];

const statuses = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];

const transactionProps = [
  'Свойство транзакции (не выбрано)',
  'Account',
  'Amount',
  'BatchId',
  'Card.Amount',
  'Card.Hash',
  'Card.Pan',
  'Card.Track2',
  'HoldRuleId',
  'HoldWhiteList',
  'Debtor.Code',
  'ErrorCode',
  'ErrorMessage',
  'Phone',
  'Rrn',
  'ServiceId',
  'Track2',
  'TransactionId',
  'UserId',
];

class GridTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('financialTransactions');
    this.state = {
      owners: [],
      allOwners: [],
      isLoading: false,
      data: [],
      statusValue: '0',
      transactionValue: transactionProps[0],
      range: {
        start: null,
        end: null,
      },
      chequeNumber: '',
      transactionDetail: '',
      chequeNumberError: false,
    };
  }

  componentDidMount() {
    this.fetchOwners();
  }

  getInitialStatus = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    const storageValue = StorageService.getItem('searchtransactions-dropdown-status');
    if (val === 'blocked') return statuses[6];
    else if (storageValue !== null && checkElement('searchtransactions-dropdown-status', this.currentArray))
      return storageValue;
    else return statuses[2];
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  statusToColor = (value, isAlt) => {
    const opacity = isAlt ? '1' : '0.6';
    switch (value) {
      case 'принят':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(92, 184, 92, ${opacity}))`;
      case 'отклонен':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(205, 92, 92, ${opacity}))`;
      case 'удален':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(244, 164, 96, ${opacity}))`;
      case 'заблокирован':
        return `linear-gradient(to right bottom, #f5f5f5, rgba(70, 130, 180, ${opacity}))`;
      default:
        return '';
    }
  };

  rowRender = (element, props) => {
    const value = props.dataItem.StatusName;
    const { style: elementStyle, children } = element.props;
    const color = this.statusToColor(value, props.isAltRow);
    const style = { background: `${color}`, ...elementStyle };
    return React.cloneElement(element, { style }, children);
  };

  changeDateSeconds = (formatedDate) => {
    const date = new Date(formatedDate);
    return date;
  };

  filterByDatePost = (el) => {
    const {
      range: { start, end },
    } = this.state;
    const startDate = new Date(start).setMilliseconds(0);
    const endDate = new Date(end).setMilliseconds(0);
    if (el && el.DatePost) {
      const changedDate = this.changeDateSeconds(el.DatePost);
      if (changedDate.setMilliseconds(0) >= startDate && changedDate.setMilliseconds(0) <= endDate) return true;
      else return false;
    } else return false;
  };

  checkChequeNumber = (chequeNumber) =>
    (chequeNumber || chequeNumber === 0) && !/^\b\d{5,7}\b-\b\d{1,9}\b-\b\d{1,9}\b$/.test(chequeNumber);

  makeQuery = async () => {
    const { owners, statusValue, transactionValue, range, chequeNumber, transactionDetail } = this.state;
    if (owners.length < 1) return;
    const start = convertToISOFormat(range.start);
    const end = convertToISOFormat(range.end);

    const isChequeNumberUnValid = this.checkChequeNumber(chequeNumber);

    this.setState({ chequeNumberError: isChequeNumberUnValid });

    if (isChequeNumberUnValid) {
      return;
    }

    try {
      this.toggleLoader(true);
      const model = {
        Owners: owners,
        StatusId: Number(statusValue),
        ChequeNumber: chequeNumber,
        DetailName: transactionProps[0] === transactionValue ? '' : transactionValue,
        DetailValue: transactionDetail,
        DateStart: start,
        DateEnd: end,
        DisplayComments: true,
        DisplayUser: true,
        Hist: true,
      };
      const data = await api.transactions(model);
      this.setState({ data: data.filter((el) => this.filterByDatePost(el)) });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  onOwners = (owners) => {
    this.setState({ owners });
  };

  setRange = (value) => {
    let { range } = this.state;
    range.start = value.start;
    range.end = value.end;
    this.setState({ range: value });
  };

  onStatusChange = (value) => {
    this.setState({ statusValue: value }, () => StorageService.setItem('searchtransactions-dropdown-status', value));
  };

  onTransactionChange = (value) => {
    this.setState({ transactionValue: value });
  };

  onChequeNumberChange = (e) => {
    this.setState({ chequeNumber: e.target.value });
  };

  onTransactionDetailChange = (e) => {
    this.setState({ transactionDetail: e.target.value });
  };

  ifBlocked = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const val = search.slice(1, 8);
    if (val === 'blocked') {
      return true;
    }
    return false;
  };

  fetchOwners = async () => {
    try {
      this.toggleLoader(true);
      const allOwners = await api.owners();

      if (allOwners && allOwners.length) {
        this.setState({ allOwners });
      }
      this.toggleLoader(false);
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  buildToolbar = () => {
    const { statusValue, transactionValue, chequeNumber, transactionDetail, allOwners, owners } = this.state;
    const { translate } = this.props;
    return (
      <Row type="flex" justify="start" gutter={16} className="FinancialTransactionGrid-toolbar">
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 14 }} xl={12} span={24}>
          <GridDropdownWithDeletedOwners
            data={DropdownNormalizersService.normalizeOwners(allOwners)}
            selectItems={owners}
            onSave={this.onOwners}
            defaultTitle={translate('page.dealerCommissions.selectDealer')}
            isGetDefaultSelectItemsFromStorage
          />

          <DateRange
            isBase
            // shortFormat
            onRange={this.setRange}
            blocked={this.ifBlocked()}
            minStartDate={new Date(new Date() - 7 * 24 * 60 * 60 * 1000)}
            minEndDate={new Date(new Date() - 7 * 24 * 60 * 60 * 1000)}
          />
        </Col>
        <Col xs={{ order: 3, span: 24 }} lg={{ order: 2, span: 7 }} xl={4} span={24} className="md-mt-10">
          <Row type="flex" align="middle" justify="start">
            <Col span={24}>
              <Col>
                <Select
                  value={statusValue}
                  onChange={(value) => this.onStatusChange(value)}
                  placeholder="Статус"
                  style={{ width: '100%' }}
                >
                  <Option value="0">все статусы</Option>
                  <Option value="1">введен</Option>
                  <Option value="2">принят</Option>
                  <Option value="3">отклонён</Option>
                  <Option value="4">удален</Option>
                  <Option value="5">обрабатывается</Option>
                  <Option value="6">заблокирован</Option>
                  <Option value="7">создан</Option>
                  <Option value="8">удержан</Option>
                </Select>
              </Col>
              {/*<Col>*/}
              {/*  <Input*/}
              {/*    placeholder="№ чека"*/}
              {/*    style={{ width: '100%', marginTop: '10px' }}*/}
              {/*    value={chequeNumber}*/}
              {/*    className="transaction-input"*/}
              {/*    onChange={this.onChequeNumberChange}*/}
              {/*  />*/}
              {/*</Col>*/}
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ order: 4, span: 24 }}
          lg={{ order: 4, span: 14 }}
          xl={{ order: 3, span: 8 }}
          span={24}
          className="lg-mt-10"
        >
          <Row type="flex" align="middle" justify="space-between" gutter={16}>
            <Col xs={24} lg={16}>
              <Row>
                <Select value={transactionValue} onChange={this.onTransactionChange} style={{ width: '100%' }}>
                  {transactionProps.map((option, index) => (
                    <Option key={`transactionProps-${index.toString()}`} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Input
                  value={transactionDetail}
                  placeholder="Значение"
                  onChange={this.onTransactionDetailChange}
                  className="transaction-input"
                />
              </Row>
            </Col>
            <Col xs={24} lg={8} className="md-mt-10">
              <Button
                className="sm-w-100"
                style={{
                  padding: '16px',
                  height: '100%',
                }}
                icon="search"
                primary
                onClick={this.makeQuery}
              >
                {translate('owner.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  renderTransactionIdOnclick = (props) => {
    tabService.addTab({ type: 'details', dataItem: props.dataItem });
  };

  render = () => {
    const { isLoading, data, chequeNumberError } = this.state;

    if (!checkElement('financialTransactions-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={data}
          aggregate={aggregate}
          onRefresh={this.makeQuery}
          toolbar={this.buildToolbar()}
          isLoading={isLoading}
          rowRender={this.rowRender}
          name="financialTransactions"
          isGroup
          // withOutBaseButtons
        >
          <GridColumn
            field="TransactionId"
            title="grids.transactions.column-transactionId"
            width="130"
            onClick={(props) => this.renderTransactionIdOnclick(props)}
            filter="numeric"
          />
          <GridColumn field="TerminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />
          <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />
          <GridColumn field="ServiceName" title="grids.transactions.column-serviceName" width="93" />
          <GridColumn
            field="AmountTotal"
            title="grids.transactions.column-amountTotal"
            width="134"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn
            field="AmountOriginal"
            title="grids.transactions.column-amountOriginal"
            width="180"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn
            field="CommissionClient"
            title="grids.transactions.column-comissionClient"
            width="171"
            filter="numeric"
            format="{0:n2}"
          />
          <GridColumn field="Currency" title="grids.transactions.column-currency" width="90" />
          <GridColumn
            field="DateInput"
            title="grids.transactions.column-dateInput"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="DatePost"
            title="grids.transactions.column-datePost"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="DateDeclined"
            title="grids.transactions.column-dateDeclined"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn
            field="DateAccepted"
            title="grids.transactions.column-dateAccepted"
            width="142"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn field="Comment" title="grids.transactions.column-comment" width="125" />
          <GridColumn field="StatusName" title="grids.transactions.column-status" width="91" />
          {/* Useless column!!! */}
          {/* <GridColumn
          title="grids.transactions.column-details"
          width="91"
        /> */}
        </Grid>
        {chequeNumberError && (
          <AlertModal
            visible={chequeNumberError}
            closeModal={() => {
              this.setState({ chequeNumberError: false });
            }}
          />
        )}
      </>
    );
  };
}

export default withRouter(withTranslate(GridTransactions));
