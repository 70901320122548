import React, { PureComponent } from 'react';
import { Select, Input, Button, message } from 'antd';
import { withTranslate } from '../../../../contexts/localContext';
import { ApiService } from '../../../../services';

import GridDropdown from '../../../../components/GridDropdown/GridDropdown';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import {
  CONTRACTS_CONFIG,
  PAYMENT_SYSTEM_COL_CONFIG,
  PS_ACCOUNT,
  SERVICE_OWNERS,
  SUPPLIERS_CONFIG,
  TARGET_TRANSFER_CONFIG,
  CODE_COLUMN_CONFIG,
} from '../../../../core/constants/configs.const';
import { MAKE_PAYMENT_DAY_OPTIONS, requiredFields, requiredFieldTranslations } from './constants';

import DropdownNormalizersService from '../../../../core/normalizers/dropdownNormalizers.service';
import './BankDetails.scss';
import AddCodeModalService from '../../../PaymentTypeNBUCode/PaymentTypeNBUCodeGrid/addCodeModalService';
import LoadingPanel from '../../../../components/loader';
import TextArea from 'antd/es/input/TextArea';
import { getDataByTimezoneOffset } from '../../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class BankDetails extends PureComponent {
  isInitialSuppliersTreatiesSpent = React.createRef();
  tabUniqId = Math.random();

  state = {
    isAddMode: false,
    serviceOwners: [],
    paymentSystems: [],
    paymentType: [],
    psAccounts: [],
    recipients: [],
    contracts: [],
    bankDetails: [],
    codes: [],
    codeTargets: [],
    schedules: [],
    settlementTypes: [],
    transactionPaymentTypes: [],
    supplierSettlementTypeDateStart: '',
    selectPsAccount: [],
    selectServiceOwner: [],
    selectContract: [],
    selectPaymentSystem: [],
    selectRecipient: [],
    selectCodes: [],
    selectCodeTargets: [],
    isNBUCodesRelExist: false,
    formData: {
      ContractSubject: '',
      SupplierBankMfo: '',
      SupplierBankAccount: '',
      Iban: '',
      ExtendedData: '',
      SupplierSettlementType: '',
      SupplierNarrative: '',
      Code: '',
      SupplierBankIdClient: '',
      SettlementDay: '',
      PaymentType: '',
      F108: '',
      ScheduleId: '',
      SplitPayments: false,
    },
    isLoading: false,
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchServiceOwners = async () => {
    const { selectServiceOwner } = this.state;
    try {
      const serviceOwners = await api.getServiceOwners();
      await this.setState({ serviceOwners });

      if (selectServiceOwner && selectServiceOwner.length) {
        const currentServiceOwner = serviceOwners.find(({ SodId }) => selectServiceOwner.includes(SodId)).PsCustomerId;
        await this.fetchPsAccount(currentServiceOwner);
      }
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    }
  };

  fetchPsAccount = async (id) => {
    try {
      const psAccounts = await api.getPsAccount(id);
      await this.setState({ psAccounts });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    }
  };

  fetchServicesTypes = async () => {
    try {
      const paymentType = await api.getServicesTypes();
      await this.setState({ paymentType });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    }
  };

  fetchSuppliersTreaties = async () => {
    const { selectRecipient } = this.state;
    if (!selectRecipient[0]) return [];
    try {
      const contracts = await api.getSuppliersTreaties(selectRecipient[0]);
      await this.setState({ contracts }, () => {
        if (this.isInitialSuppliersTreatiesSpent.current) {
          this.setState({ selectContract: [] });
        } else {
          this.isInitialSuppliersTreatiesSpent.current = true;
        }
      });
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  fetchSuppliers = async () => {
    try {
      const recipients = await api.getSuppliers();
      this.setState({ recipients });
    } catch (e) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(e);
    }
  };

  fetchSupplierSettlementType = async () => {
    try {
      const settlementTypes = await api.getSupplierSettlementType();

      this.setState({ settlementTypes });
    } catch (e) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(e);
    }
  };

  fetchTransactionPaymentType = async () => {
    try {
      const transactionPaymentTypes = await api.getTransactionPaymentType();

      this.setState({ transactionPaymentTypes });
    } catch (e) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(e);
    }
  };

  fetchServicesBankDetails = async () => {
    const { formData } = this.state;

    const {
      dataItem: {
        selectItem: { ServiceId },
      },
    } = this.props;
    try {
      await this.toggleLoader(true);
      const bankDetails = await api.getServicesBankingDetails(ServiceId);
      await this.setState({
        formData: Object.entries(bankDetails).reduce((acc, [key, value]) => {
          if (formData.hasOwnProperty(key)) {
            acc[key] = value === null ? '' : value;
          }
          return acc;
        }, {}),
        selectPaymentSystem: bankDetails.PsdId ? [bankDetails.PsdId] : [],
        selectPsAccount: bankDetails.PsAccountId ? [bankDetails.PsAccountId] : [],
        selectServiceOwner: bankDetails.SodId ? [bankDetails.SodId] : [],
        selectContract: bankDetails.TreatyId ? [bankDetails.TreatyId] : [],
        selectRecipient: bankDetails.SupplierId ? [bankDetails.SupplierId] : [],
        selectCodes: bankDetails.F108 ? [bankDetails.F108] : [],
        selectCodeTargets: bankDetails.F108Subcode ? [`${bankDetails.F108Subcode}`] : [],
        supplierSettlementTypeDateStart: bankDetails.SupplierSettlementTypeDateStart,
        isAddMode: !bankDetails,
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      await this.toggleLoader(false);
    }
  };

  fetchPaymentSystemList = async () => {
    try {
      const paymentSystems = await api.getPaymentSystemDictionaryList();
      this.setState({ paymentSystems });
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };

  fetchNBUCodes = async () => {
    const { translate } = this.props;
    try {
      const data = await api.NBUCodesList();
      await this.setState({
        codes: data && data.length ? AddCodeModalService.normalizeNBUCodes(data, translate) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.toggleLoader(false);
      showError(error);
    }
  };

  fetchPaymentsSchedules = async () => {
    try {
      const schedules = await api.getServicesPaymentsSchedules();
      this.setState({ schedules });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.toggleLoader(false);
    }
  };

  fetchCodeTargets = async () => {
    try {
      const data = await api.getNBUSubCodesList();

      await this.setState({
        codeTargets: data && data.length ? AddCodeModalService.normalizeNBUSubCodes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  setIsNBUCodesRelExist = async () => {
    const {
      dataItem: {
        selectItem: { ServiceId: currentServiceId },
      },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const relList = await api.getNBUPaymentTypeServiceRelations();
      await this.setState({
        isNBUCodesRelExist:
          relList && relList.length
            ? relList.some(({ ServiceId, PaymentType }) => ServiceId === currentServiceId && PaymentType === 0)
            : false,
      });

      await this.setState({ isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchRequest = async () => {
    await this.toggleLoader(true);
    await this.fetchServicesBankDetails();
    await this.fetchPaymentSystemList();
    await this.fetchServiceOwners();
    await this.fetchServicesTypes();
    await this.fetchSuppliers();
    await this.fetchSupplierSettlementType();
    await this.fetchTransactionPaymentType();
    await this.fetchSuppliersTreaties();
    await this.fetchNBUCodes();
    await this.fetchPaymentsSchedules();
    await this.fetchCodeTargets();
    await this.setIsNBUCodesRelExist();
    await this.toggleLoader(false);
  };

  onHandleSelectItems = (name) => (selectedItem) => {
    const { recipients, serviceOwners } = this.state;

    this.setState({ [name]: selectedItem }, async () => {
      if (name === 'selectRecipient') {
        await this.setState({ isLoading: true });
        const currentRecipient = recipients.find(({ SupplierId }) => SupplierId === selectedItem[0]);

        this.setState(({ formData }) => ({
          formData: { ...formData, SupplierBankIdClient: currentRecipient ? currentRecipient.ClientId : '' },
        }));

        await this.fetchSuppliersTreaties();
        await this.setState({ isLoading: false });
      }

      if (name === 'selectServiceOwner') {
        await this.setState({ isLoading: true });
        const currentServiceOwner = serviceOwners.find(({ SodId }) => selectedItem.includes(SodId)).PsCustomerId;
        await this.fetchPsAccount(currentServiceOwner);
        await this.setState({ isLoading: false });
      }

      if (name === 'selectCodes') {
        this.setState({ selectCodeTargets: [] });
      }
    });
  };

  componentDidMount() {
    this.fetchRequest();
  }

  onChangeFormData = (e) => {
    const { name, value } = e.target;

    this.setState(({ formData }) => ({ formData: { ...formData, [name]: value } }));
  };

  onChangeSelect = (name) => (selectValue) => {
    const { formData } = this.state;

    if (name === 'SettlementDay') {
      this.setState({
        formData: { ...formData, [name]: selectValue, ScheduleId: selectValue === 1 ? formData.ScheduleId : '' },
      });

      return;
    }

    this.setState({ formData: { ...formData, [name]: selectValue } });
  };

  onChecked = (e) => {
    const { checked } = e.target;
    const { formData } = this.state;
    this.setState({ formData: { ...formData, SplitPayments: checked } });
  };
  onChangeDate = (e) => {
    const { value } = e.target;
    this.setState({ supplierSettlementTypeDateStart: value });
  };

  checkBeforeSubmit = async () => {
    const { translate } = this.props;
    const { formData, codes, selectCodes } = this.state;

    const transactionTargetBySelectedNBUCode = codes.find(({ Id }) => selectCodes.includes(Id));

    const resultRequiredFields =
      transactionTargetBySelectedNBUCode && transactionTargetBySelectedNBUCode.WithSubcodes
        ? [
            ...requiredFields,
            {
              field: 'selectCodeTargets',
              selectType: true,
            },
          ]
        : requiredFields;

    const requiredErrorList = resultRequiredFields
      .map(({ field, selectType }) => {
        const isTargetValueFalsy = selectType ? !this.state[field].length : !formData[field] && formData[field] !== 0;

        return isTargetValueFalsy ? field : '';
      })
      .filter((item) => item);

    if (requiredErrorList.length) {
      const requiredErrorListString = requiredErrorList
        .map((slug) => translate(requiredFieldTranslations[slug]))
        .join(', ');

      return `${translate('page.serviceMainProps.savingDidNot')} ${translate(
        'page.serviceMainProps.mandatoryFieldsAreNotFilled',
      )} ${requiredErrorListString}`;
    }

    return '';
  };

  onUpdateBankDetails = async () => {
    const {
      selectPsAccount,
      selectServiceOwner,
      selectContract,
      selectPaymentSystem,
      selectRecipient,
      selectCodes,
      supplierSettlementTypeDateStart,
      formData,
      isAddMode,
      selectCodeTargets,
      isNBUCodesRelExist,
    } = this.state;

    const {
      dataItem: {
        selectItem: { ServiceId },
      },
      translate,
      onSave,
    } = this.props;

    const { F108, SupplierBankIdClient, SupplierNarrative, ...formState } = formData;

    const checkError = await this.checkBeforeSubmit();

    if (checkError) {
      message.error(checkError, 3);

      return;
    }

    try {
      this.toggleLoader(true);
      await api.validateIban(formState.Iban);
    } catch (e) {
      this.toggleLoader(false);
      message.error(`${translate('page.bankDetails.error-iban')}`, 2);
      return;
    }

    try {
      const model = {
        ...formState,
        ServiceId: ServiceId,
        PsAccountId: selectPsAccount[0],
        TreatyId: selectContract[0],
        PsdId: selectPaymentSystem[0],
        SodId: selectServiceOwner[0],
        F108: selectCodes[0],
        SupplierId: selectRecipient[0],
        SupplierSettlementTypeDateStart: supplierSettlementTypeDateStart,
        SupplierBankIdClient: SupplierBankIdClient,
        SupplierNarrative: SupplierNarrative
          ? JSON.stringify(SupplierNarrative).replaceAll('\\n', ' ').replaceAll('"', '')
          : '',
      };
      await this.toggleLoader(true);

      if (selectCodeTargets.length) {
        const updateNBUCodeRelationsMethod = isNBUCodesRelExist
          ? api.updateNBUPaymentTypeServiceRelations
          : api.addPaymentTypeServiceRelationsNBU;

        await updateNBUCodeRelationsMethod([
          {
            PaymentType: 0,
            f108Code: selectCodes[0],
            f108SubCode: selectCodeTargets[0],
            ServiceId,
          },
        ]);
      }

      const updateMethod = isAddMode ? api.servicesBankingDetailsAdd : api.servicesBankingDetailsUpdate;
      await updateMethod(model);
      await this.toggleLoader(false);
      await message.success(`${translate('page.bankDetails.successfullySaved')}`, 2);
      onSave();
    } catch (e) {
      const { showError } = this.props;
      await this.toggleLoader(false);
      showError(e);
    }
  };
  render() {
    const {
      serviceOwners,
      paymentSystems,
      psAccounts,
      recipients,
      contracts,
      codes,
      supplierSettlementTypeDateStart,
      selectPsAccount,
      selectServiceOwner,
      selectContract,
      selectPaymentSystem,
      selectRecipient,
      selectCodes,
      selectCodeTargets,
      schedules,
      settlementTypes,
      transactionPaymentTypes,
      codeTargets,
      formData: {
        ContractSubject,
        SupplierBankMfo,
        SupplierBankAccount,
        Iban,
        ExtendedData,
        SupplierSettlementType,
        SupplierNarrative,
        SupplierBankIdClient,
        SettlementDay,
        PaymentType,
        SplitPayments,
        ScheduleId,
      },
      isLoading,
    } = this.state;
    const {
      dataItem: {
        selectItem: { ServiceId, Name },
      },
      translate,
      customSaveActionTitle,
    } = this.props;

    const transactionTargetBySelectedNBUCode = codes.find(({ Id }) => selectCodes.includes(Id));

    return (
      <>
        <div className="BankDetails">
          <div className="BankDetails-header">
            <div className="BankDetails-header--col">
              <div className="BankDetails-row">
                <div className="BankDetails-label">{translate('page.bankDetails.label-serviceId')}</div>
                <div className="BankDetails-header-wr--input">
                  <Input value={ServiceId} disabled className="BankDetails-input" />
                </div>
              </div>
              <div className="BankDetails-row">
                <div className="BankDetails-label">{translate('page.bankDetails.label-name')}</div>
                <div className="BankDetails-header-wr--input">
                  <TextArea value={Name} disabled className="BankDetails-input" autoSize />
                </div>
              </div>
            </div>
            <div className="BankDetails-header-btn"></div>
          </div>
          <div className="BankDetails-formContent">
            <div className="BankDetails-row">
              <div className="BankDetails-label">{translate('page.bankDetails.payment-system')}</div>
              <div className="BankDetails-gridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizePaymentSystems(paymentSystems)}
                  colConfig={PAYMENT_SYSTEM_COL_CONFIG}
                  onSave={this.onHandleSelectItems('selectPaymentSystem')}
                  selectItems={selectPaymentSystem}
                  defaultTitle={translate('page.bankDetails.dropDown-title.payment-system')}
                  isSingle
                />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">{translate('page.bankDetails.service-owner')}</div>
              <div className="BankDetails-gridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeServiceOwners(serviceOwners)}
                  colConfig={SERVICE_OWNERS}
                  onSave={this.onHandleSelectItems('selectServiceOwner')}
                  selectItems={selectServiceOwner}
                  defaultTitle={translate('page.bankDetails.dropDown-title.service-owner')}
                  isSingle
                />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">{translate('page.bankDetails.check-ps')}</div>
              <div className="BankDetails-gridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizePsAccount(psAccounts)}
                  onSave={this.onHandleSelectItems('selectPsAccount')}
                  selectItems={selectPsAccount}
                  colConfig={PS_ACCOUNT}
                  defaultTitle={translate('page.bankDetails.dropDown-title.account-ps')}
                  isSingle
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">{translate('page.bankDetails.recipient')}</div>
              <div className="BankDetails-gridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeSuppliers(recipients)}
                  colConfig={SUPPLIERS_CONFIG}
                  onSave={this.onHandleSelectItems('selectRecipient')}
                  selectItems={selectRecipient}
                  defaultTitle={translate('page.bankDetails.dropDown-title.recipient')}
                  isSingle
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">{translate('page.bankDetails.treaty')}</div>
              <div className="BankDetails-gridDropdown BankDetails-narrowGridDropdown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeContracts(contracts)}
                  onSave={this.onHandleSelectItems('selectContract')}
                  colConfig={CONTRACTS_CONFIG}
                  selectItems={selectContract}
                  defaultTitle={translate('page.bankDetails.dropDown-title.contract')}
                  isSingle
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.subject-contract')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <TextArea
                  value={ContractSubject}
                  name="ContractSubject"
                  onChange={this.onChangeFormData}
                  className="BankDetails-input"
                />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.code')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <Input
                  value={SupplierBankIdClient}
                  disabled
                  name="SupplierBankIdClient"
                  className="BankDetails-input"
                />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.mfi')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <Input value={SupplierBankMfo} name="SupplierBankMfo" disabled className="BankDetails-input" />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.check')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <Input value={SupplierBankAccount} name="SupplierBankAccount" disabled className="BankDetails-input" />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.iban')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <Input value={Iban} name="Iban" onChange={this.onChangeFormData} className="BankDetails-input" />
              </div>
            </div>
            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.purpose-payment')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <TextArea
                  value={SupplierNarrative}
                  name="SupplierNarrative"
                  onChange={this.onChangeFormData}
                  className="BankDetails-input"
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.additional-data')}</label>
              </div>
              <div className="BankDetails-formContent-input">
                <Input
                  value={ExtendedData}
                  name="ExtendedData"
                  onChange={this.onChangeFormData}
                  className="BankDetails-input"
                />
              </div>
            </div>

            <div className="BankDetails-row BankDetails-row--noBottomGap BankDetails-row--colDirForTablet">
              <div className="BankDetails-row BankDetails-row--freeGrow">
                <div className="BankDetails-label">
                  <label>{translate('page.bankDetails.type-settlement')}</label>
                </div>
                <div
                  className="BankDetails-formContent-input"
                  style={{ position: 'relative' }}
                  id={`SupplierSettlementType-${this.tabUniqId}`}
                >
                  <Select
                    placeholder={translate('page.bankDetails.dropDown-title.type-settlement')}
                    name="SupplierSettlementType"
                    value={SupplierSettlementType}
                    onChange={(e) => this.onChangeSelect('SupplierSettlementType')(e)}
                    getPopupContainer={() => document.getElementById(`SupplierSettlementType-${this.tabUniqId}`)}
                    className="BankDetails-input"
                  >
                    {settlementTypes.map(({ Id, Name }) => (
                      <Select.Option key={Id} value={Id}>
                        {Id}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="BankDetails-row">
                <div className="BankDetails-label">
                  <label>{translate('page.bankDetails.start-date')}</label>
                </div>
                <div className="BankDetails-formContent-input--sm">
                  <DatePicker
                    value={supplierSettlementTypeDateStart ? new Date(supplierSettlementTypeDateStart) : new Date()}
                    name="SupplierSettlementTypeDateStart"
                    format="yyyy.MM.dd"
                    onChange={this.onChangeDate}
                    className="BankDetails-input"
                  />
                </div>
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.payment-type')}</label>
              </div>
              <div
                className="BankDetails-formContent-input"
                style={{ position: 'relative' }}
                id={`PaymentType-${this.tabUniqId}`}
              >
                <Select
                  name="PaymentType"
                  placeholder={translate('page.bankDetails.dropDown-title.payment-type')}
                  value={PaymentType || undefined}
                  onChange={(e) => this.onChangeSelect('PaymentType')(e)}
                  getPopupContainer={() => document.getElementById(`PaymentType-${this.tabUniqId}`)}
                  className="BankDetails-input"
                >
                  {transactionPaymentTypes.map(({ Id, Name }) => (
                    <Select.Option key={Id} value={Id}>
                      {Name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="BankDetails-row BankDetails-row--noBottomGap BankDetails-row--colDirForTablet">
              <div className="BankDetails-row BankDetails-row--freeGrow">
                <div className="BankDetails-label">
                  <label>{translate('page.bankDetails.make-payments-to')}</label>
                </div>
                <div
                  className="BankDetails-formContent-select"
                  style={{ position: 'relative' }}
                  id={`SettlementDay-${this.tabUniqId}`}
                >
                  <Select
                    placeholder={translate('page.bankDetails.choose-payment-day')}
                    value={SettlementDay}
                    onChange={(e) => this.onChangeSelect('SettlementDay')(e)}
                    className="BankDetails-input"
                    getPopupContainer={() => document.getElementById(`SettlementDay-${this.tabUniqId}`)}
                  >
                    {MAKE_PAYMENT_DAY_OPTIONS.map(({ label, value }) => (
                      <Select.Option key={value} value={translate(label)}>
                        {translate(`${label}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="BankDetails-row BankDetails-row--freeGrow">
                <div className="BankDetails-label--small">{translate('page.bankDetails.day')}</div>
                <div
                  className="BankDetails-formContent-select"
                  style={{ position: 'relative' }}
                  id={`ScheduleId-${this.tabUniqId}`}
                >
                  <Select
                    value={ScheduleId || 0}
                    onChange={(e) => this.onChangeSelect('ScheduleId')(e)}
                    disabled={SettlementDay !== 1}
                    className="BankDetails-input"
                    getPopupContainer={() => document.getElementById(`ScheduleId-${this.tabUniqId}`)}
                  >
                    {schedules.map(({ ScheduleId, Description }) => (
                      <Select.Option key={ScheduleId} value={ScheduleId}>
                        {Description}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.bankDetails.payment-code')}</label>
              </div>
              <div className="BankDetails-gridDropdown BankDetails-gridDropdown--top">
                <GridDropdown
                  data={codes}
                  colConfig={CODE_COLUMN_CONFIG}
                  selectItems={selectCodes}
                  onSave={this.onHandleSelectItems('selectCodes')}
                  defaultTitle={translate('page.paymentTypeNBUCode.selectCode')}
                  isSingle
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div className="BankDetails-label">
                <label>{translate('page.paymentTypeNBUCode.targetTransfer')}</label>
              </div>
              <div className="BankDetails-gridDropdown BankDetails-gridDropdown--top">
                <GridDropdown
                  data={AddCodeModalService.filterSubCodesBySelectedCode(codeTargets, this.state.selectCodes)}
                  colConfig={TARGET_TRANSFER_CONFIG}
                  selectItems={selectCodeTargets}
                  onSave={this.onHandleSelectItems('selectCodeTargets')}
                  defaultTitle={translate('page.paymentTypeNBUCode.selectTargetTransfer')}
                  disabled={!transactionTargetBySelectedNBUCode || !transactionTargetBySelectedNBUCode.WithSubcodes}
                  isSingle
                />
              </div>
            </div>

            <div className="BankDetails-row">
              <div>
                <label>{translate('page.bankDetails.payment-no-combine')}</label>
              </div>
              <div className="BankDetails-input--checkbox">
                <Input
                  type="checkbox"
                  value={SplitPayments}
                  name="SplitPayments"
                  checked={SplitPayments}
                  onChange={this.onChecked}
                  className="BankDetails-input BankDetails-input--checkbox"
                />
              </div>
            </div>

            <Button type="primary" onClick={this.onUpdateBankDetails}>
              {translate(customSaveActionTitle || 'core.buttonTitles.save')}
            </Button>
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </>
    );
  }
}
export default withTranslate(BankDetails);
