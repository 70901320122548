class DropdownNormalizersServiceMFO {
  normalizeTypes = (types) => {
    if (!(types || types.length)) return [];

    return types.map((type) => ({
      ...type,
      Id: type.OwnerMfoPaymentTypeId,
      Name: type.Name,
    }));
  };



  normalizeCodes= (codes) => {
    if (!(codes || codes.length)) return [];

    return codes.map((code) => ({
      ...code,
      Id: code.F108Code,
      Name: code.Description,
    }));
  };

  normalizeProjects= (projects) => {
    if (!(projects || projects.length)) return [];

    return projects.map((project) => ({
      ...project,
      Id: project.Project,
      Name: project.Project,
    }));
  };
}

export default new DropdownNormalizersServiceMFO();
