import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import AddCodeModalService from './addCodeModalService';
import { ApiService } from '../../../services';
import GridDropdown from '../../../components/GridDropdown/GridDropdown';
import { TARGET_TRANSFER_CONFIG, CODE_COLUMN_CONFIG } from '../../../core/constants/configs.const';

const api = new ApiService();

class ChangeCodeModal extends PureComponent {
  state = {
    isLoading: false,
    selectCodes: [],
    codes: [],
    isErrorExist: false,
    targetTransfers: [],
    selectedTargetTransfer: [],
  };

  componentDidMount() {
    this.fetchInfo();
  }

  fetchInfo = async () => {
    const { selectedItems } = this.props;

    console.log('selectedItems', selectedItems);

    if (selectedItems.length === 1) {
      this.setState({
        selectCodes: [selectedItems[0].F108Code],
        selectedTargetTransfer: selectedItems[0].F108SubCode ? [`${selectedItems[0].F108SubCode}`] : [],
      });
    }

    await this.setState({ isLoading: true });
    await this.getNBUCodes();
    await this.getTargetTransfers();
    await this.setState({ isLoading: false });
  };

  getNBUCodes = async () => {
    const { translate } = this.props;
    try {
      const data = await api.NBUCodesList();

      await this.setState({
        codes: data && data.length ? AddCodeModalService.normalizeNBUCodes(data, translate) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getTargetTransfers = async () => {
    try {
      const data = await api.getNBUSubCodesList();

      await this.setState({
        targetTransfers: data && data.length ? AddCodeModalService.normalizeNBUSubCodes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  handleChange = async () => {
    const { translate, onClose, onRefresh, selectedItems } = this.props;
    const { selectCodes, selectedTargetTransfer } = this.state;
    this.setState({ isLoading: true });

    try {
      await api.updateNBUPaymentTypeServiceRelations(
        selectedItems.map(({ ServiceId, PaymentType }) => ({
          ServiceId,
          PaymentType,
          F108Code: selectCodes[0],
          f108SubCode: selectedTargetTransfer.length ? selectedTargetTransfer[0] : null,
        })),
      );
      this.setState({ isLoading: true });
      onClose();
      message.success(translate('page.paymentTypeNBUCode.successfullyChanged'), 3);
      onRefresh();
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false, isErrorExist: true });
      showError(error);
    }
  };

  onDropdownSave = (fieldName) => (value) => {
    this.setState({ [fieldName]: value, isErrorExist: '' }, () => {
      if (fieldName === 'selectCodes') {
        this.setState({ selectedTargetTransfer: [] });
      }
    });
  };

  render() {
    const { translate, onClose, visible } = this.props;
    const { codes, selectCodes, isErrorExist, isLoading, targetTransfers, selectedTargetTransfer } = this.state;

    const selectedNbuCode = codes.find((item) => item.Id === selectCodes[0]);

    return (
      <Modal
        title={translate('page.paymentTypeNBUCode.changeNBUCode')}
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleChange}
            loading={isLoading}
            disabled={
              isErrorExist || !selectedNbuCode || (selectedNbuCode.WithSubcodes && !selectedTargetTransfer.length)
            }
          >
            {translate('core.buttonTitles.change')}
          </Button>,
          <Button key="back" onClick={onClose} loading={isLoading}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        <div className="AddCodeModal-label">{translate('page.paymentTypeNBUCode.activeNBUCodes')}:</div>
        <div className="AddCodeModal-selectWrapper">
          <GridDropdown
            data={codes}
            colConfig={CODE_COLUMN_CONFIG}
            selectItems={selectCodes}
            onSave={this.onDropdownSave('selectCodes')}
            defaultTitle={translate('page.paymentTypeNBUCode.selectCode')}
            isSingle
          />
        </div>
        <div className="AddCodeModal-label">
          <label>{translate('page.paymentTypeNBUCode.targetTransfer')}</label>
        </div>
        <div className="AddCodeModal-selectWrapper">
          <GridDropdown
            data={AddCodeModalService.filterSubCodesBySelectedCode(targetTransfers, this.state.selectCodes)}
            colConfig={TARGET_TRANSFER_CONFIG}
            selectItems={selectedTargetTransfer}
            onSave={this.onDropdownSave('selectedTargetTransfer')}
            defaultTitle={translate('page.paymentTypeNBUCode.selectTargetTransfer')}
            disabled={!selectedNbuCode || !selectedNbuCode.WithSubcodes}
            isSingle
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslate(ChangeCodeModal);
