import React, { PureComponent } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input, Button, message, Card } from 'antd';

import UpdateModal from './UpdateModal';
import { withTranslate } from '../../../contexts/localContext';
import BlockedTransactionsPageService from './blockedTransactionsPage.service';
import './BlockedTransactionsPage.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
import { ApiService, tabService } from '../../../services';
import LoadingPanel from '../../../components/loader';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import ModalEmptyData from './ModalEmptyData';

const api = new ApiService();

class BlockedTransactionsPage extends PureComponent {
  currentArray = findArray('blockedTransactions');
  state = {
    dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
    dateEnd: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999),
    errorDateMessage: '',
    emails: '',
    emailsError: '',
    dialogVariant: '',
    isLoading: false,
    emptyReport: false,
  };

  getDateChangeCallback =
    (fieldName) =>
    ({ target: { value } }) => {
      const { translate } = this.props;

      this.setState(
        {
          [fieldName]:
            fieldName === 'dateStart'
              ? BlockedTransactionsPageService.geStartValue(value)
              : BlockedTransactionsPageService.getEndValue(value),
          errorDateMessage: '',
        },
        () => {
          this.setState(({ dateStart, dateEnd }) => ({
            errorDateMessage: BlockedTransactionsPageService.getSubmitErrorsByDate(
              BlockedTransactionsPageService.geStartValue(dateStart),
              BlockedTransactionsPageService.getEndValue(dateEnd),
              translate,
            ),
          }));
        },
      );
    };

  onEmailsChange = ({ target: { value } }) => {
    const { translate } = this.props;

    this.setState(
      {
        emails: value,
        emailsError: '',
      },
      () => {
        this.setState(() => ({
          emailsError: BlockedTransactionsPageService.validateEmail(value, translate),
        }));
      },
    );
  };

  onSubmit = async () => {
    const { dateStart, dateEnd, emails } = this.state;
    const { translate } = this.props;

    try {
      await this.setState({ isLoading: true });
      const result = await api.paymentsExternalReportByPeriod({
        emails: emails.split(';').map((email) => email.trim()),
        dateStart: getDataByTimezoneOffset(BlockedTransactionsPageService.geStartValue(dateStart)).toISOString(),
        dateEnd: getDataByTimezoneOffset(BlockedTransactionsPageService.getEndValue(dateEnd)).toISOString(),
      });
      await this.setState({ isLoading: false });
      if (!result) {
        this.setState({ emptyReport: true });
        return;
      }

      await message.success(translate('page.blockedTransactions.processingRequest'), 2);
    } catch (error) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(error);
    }
  };

  createDynamicTab = (slug) => {
    return tabService.addTab({
      type: slug,
    });
  };

  render() {
    const { translate } = this.props;
    const { dateStart, dateEnd, errorDateMessage, emailsError, emails, dialogVariant, isLoading, emptyReport } =
      this.state;

    if (!checkElement('blockedTransactions-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <div className="BlockedTransactionsPage">
          <Card type="inner" title={translate('page.blockedTransactions.createReport')}>
            <div className="BlockedTransactionsPage-sendReport">
              <div className="BlockedTransactionsPage-sendReportFields">
                <div className="BlockedTransactionsPage-sendReportFieldsItem">
                  <div className="BlockedTransactionsPage-sendReportFieldsItemChild">
                    <div className="BlockedTransactionsPage-label">
                      {translate('page.changingProfileHistory.dateFrom')}
                    </div>
                    <div>
                      <DatePicker
                        format="yyyy.MM.dd"
                        onChange={this.getDateChangeCallback('dateStart')}
                        value={dateStart}
                        min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                        max={BlockedTransactionsPageService.getMaxSelectedDate()}
                      />
                    </div>
                  </div>

                  <div className="BlockedTransactionsPage-sendReportFieldsItemChild">
                    <div className="BlockedTransactionsPage-label">
                      {translate('page.changingProfileHistory.dateTo')}
                    </div>
                    <div>
                      <DatePicker
                        format="yyyy.MM.dd"
                        onChange={this.getDateChangeCallback('dateEnd')}
                        value={dateEnd}
                        min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                        max={BlockedTransactionsPageService.getMaxSelectedDate()}
                      />
                    </div>
                  </div>

                  {errorDateMessage && <div className="BlockedTransactionsPage-dateError">{errorDateMessage}</div>}
                </div>

                <div className="BlockedTransactionsPage-sendReportFieldsItem">
                  <div className="BlockedTransactionsPage-sendReportFieldsItemChild">
                    <div className="BlockedTransactionsPage-label">{translate('page.blockedTransactions.email')}</div>
                    <Input
                      className="BlockedTransactionsPage-emailField"
                      value={emails}
                      onChange={this.onEmailsChange}
                    />
                  </div>
                  {emailsError && <div className="BlockedTransactionsPage-dateError">{emailsError}</div>}
                </div>
              </div>

              <div className="BlockedTransactionsPage-sendReportSubmitAction">
                <Button type="primary" disabled={errorDateMessage || emailsError || !emails} onClick={this.onSubmit}>
                  {translate('page.blockedTransactions.createReport')}
                </Button>
              </div>
            </div>
          </Card>

          <Card type="inner" title={translate('page.blockedTransactions.updateLists')} style={{ marginTop: '20px' }}>
            <div className="BlockedTransactionsPage-sendReportActions">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dialogVariant: 'individuals' });
                }}
              >
                {translate('page.blockedTransactions.individuals')}
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dialogVariant: 'juridicPersons' });
                }}
              >
                {translate('page.blockedTransactions.juridicPersons')}
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dialogVariant: 'terrorist' });
                }}
              >
                {translate('page.blockedTransactions.terrorists')}
              </Button>

              {checkElement('blockedTransactions-searchInLists', this.currentArray) && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.createDynamicTab('searchSanctionsTerrorist');
                  }}
                >
                  {translate('core.buttonTitles.search-lists')}
                </Button>
              )}

              {checkElement('blockedTransactions-SUOList', this.currentArray) && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.createDynamicTab('SUO');
                  }}
                >
                  {translate('page.blockedTransactions.SUOList')}
                </Button>
              )}
            </div>
          </Card>
        </div>
        {dialogVariant && (
          <UpdateModal
            variant={dialogVariant}
            closeModal={() => {
              this.setState({ dialogVariant: '' });
            }}
          />
        )}
        {emptyReport && (
          <ModalEmptyData
            visible={emptyReport}
            onCloseModal={() => {
              this.setState({ emptyReport: false });
            }}
          />
        )}

        {isLoading && <LoadingPanel />}
      </>
    );
  }
}

export default withTranslate(BlockedTransactionsPage);
