import React, { PureComponent } from 'react';

import { ApiService, StorageService, tabService } from '../../../services';
import ReleasesService from './service';
import Grid from '../../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { getTransformDateString } from '../../../utils/getTransformDateString';
import { withTranslate } from '../../../contexts/localContext';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';
const api = new ApiService();

class ReleasesGrid extends PureComponent {
  currentArray = findArray('releasePage');
  componentDidMount() {
    const releasesByStorage = StorageService.getItem('backConfig');

    if (releasesByStorage) {
      this.setState({ backConfig: releasesByStorage });

      return;
    }

    this.getBackConfig();
  }

  state = {
    backConfig: null,
    isLoading: false,
  };

  getBackConfig = async () => {
    try {
      this.setState({ isLoading: true });
      const backEndConfig = await api.getElementsFromBack({ Elements: [] });
      StorageService.setItem('backConfig', backEndConfig);
      this.setState({ backConfig: backEndConfig });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate } = this.props;
    const { backConfig, isLoading } = this.state;

    const releasesList = ReleasesService.getDateForReleases(backConfig, translate);

    if (!checkElement('releasePage-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={releasesList.sort(
          ({ DatePost: PrevDatePost }, { DatePost: NextDatePost }) => +new Date(NextDatePost) - +new Date(PrevDatePost),
        )}
        name="releasesGrid"
        toolbar={<></>}
        onRefresh={() => {}}
        isLoading={isLoading}
      >
        <GridColumn
          field="ReleaseVersion"
          title="page.releases.col-releaseVersion"
          width="130"
          {...(checkElement('releasePage-releaseNewElements', this.currentArray)
            ? {
                onClick: ({ dataItem: { DatePost, ReleaseVersion } }) => {
                  tabService.addTab({
                    type: 'releaseNewElements',
                    dataItem: {
                      DatePost,
                      ReleaseVersion,
                      ElementsListByRelease: ReleasesService.getElementsListByRelease(
                        backConfig,
                        ReleaseVersion,
                        translate,
                      ),
                      WebTagListByRelease: ReleasesService.getWebTagListByRelease(
                        backConfig,
                        ReleaseVersion,
                        translate,
                      ),
                    },
                  });
                },
              }
            : {})}
        />

        <GridColumn
          field="DatePost"
          title="page.releases.col-dateChange"
          width="180"
          cell={({ dataItem: { DatePost } }) => (
            <td style={{ textAlign: 'center' }}>{getTransformDateString(DatePost)}</td>
          )}
          formatFilterCellData={(DatePost) => getTransformDateString(DatePost)}
          warningInfo={translate('page.releases.dateIndicativeMessage')}
        />
      </Grid>
    );
  }
}
export default withTranslate(ReleasesGrid);
