import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Input, Row } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';
import checkElement from '../../../utils/checkElement';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';
import { ApiService, tabService } from '../../../services';
import findArray from '../../../utils/findArrForCurrentPage';

import './EasypayUserAccountNumberInfoGrid.styled.scss';

const api = new ApiService();

class EasypayUserAccountNumberInfoGrid extends PureComponent {
  currentArray = findArray('easypayUsersManage');

  state = {
    transactions: [],
    isLoading: false,
    errorMessage: '',
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
  };

  getSubmitErrorsByDate = (startDate, endDate) => {
    const { translate } = this.props;

    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    const normalizedStartDate = StandardizeTimeForDate(new Date(startDate), true);
    const normalizedEndDate = StandardizeTimeForDate(new Date(endDate));

    if (normalizedStartDate - normalizedEndDate >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    const minimumSelectedDate = StandardizeTimeForDate(new Date(2007, 0, 1), true);
    const maxSelectedDate = StandardizeTimeForDate(new Date());

    if (
      minimumSelectedDate - normalizedStartDate > 0 ||
      minimumSelectedDate - normalizedEndDate > 0 ||
      normalizedStartDate - maxSelectedDate > 0 ||
      normalizedEndDate - maxSelectedDate > 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  getDateChangeCallback = (fieldName) => (e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: this.getSubmitErrorsByDate(dateStart, dateEnd),
        }));
      },
    );
  };

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.clientWalletsMovements) {
      return { users: [], hasNextPage: false };
    }
    const transactionList = data.data.clientWalletsMovements;

    const transactions = transactionList.items && transactionList.items.length ? transactionList.items : [];

    const pageInfo = transactionList.pageInfo;

    return {
      transactions,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  iterateFetchGQL = async (skip, transactionList, entity) => {
    try {
      const query = `clientWalletsMovements(take: 500,skip:${skip},${entity}){items{transactionId, walletNumber, serviceId, amount, statusName, dateUpdate, paymentDetail} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { transactions, hasNextPage } = this.getGQLResponse(data);

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(skip + 500, [...transactionList, ...transactions], entity)
        : [...transactionList, ...transactions];
    } catch (e) {
      throw e;
    }
  };

  fetchAllGQL = async () => {
    const { errorMessage, dateStart, dateEnd } = this.state;
    const {
      dataItem: { Number },
    } = this.props;

    if (errorMessage) {
      return;
    }

    try {
      await this.setState({ isLoading: true });
      const transactions = await this.iterateFetchGQL(
        0,
        [],
        `where:{and:[{walletNumber:{in:["${Number}"]}}, {dateUpdate:{gte: "${getDataByTimezoneOffset(
          StandardizeTimeForDate(dateStart, true),
        ).toISOString()}"}}, {dateUpdate:{lte: "${getDataByTimezoneOffset(
          StandardizeTimeForDate(dateEnd),
        ).toISOString()}"}}]}`,
      );
      await this.setState({ isLoading: false, transactions });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate, dataItem } = this.props;
    const { errorMessage, dateStart, dateEnd } = this.state;

    return (
      <Row className="EasypayUserAccountNumberInfoGrid-toolbar">
        <div className="EasypayUserAccountNumberInfoGrid-fieldsContent">
          <div className="EasypayUserAccountNumberInfoGrid-userInfo">
            <div className="EasypayUserAccountNumberInfoGrid-toolbarItem">
              <div className="EasypayUserAccountNumberInfoGrid-label">
                {translate('page.easypayUsersManage.fullName')}
              </div>
              <Input
                className="EasypayUserAccountNumberInfoGrid-input"
                value={`${dataItem.firstName} ${dataItem.lastName}`}
                disabled
              />
            </div>

            <div className="EasypayUserAccountNumberInfoGrid-toolbarItem">
              <div className="EasypayUserAccountNumberInfoGrid-label">
                {translate('page.easypayUsersManage.phoneNumber')}
              </div>
              <Input
                className="EasypayUserAccountNumberInfoGrid-phoneInput"
                value={`${dataItem.phone || ''}`}
                disabled
              />
            </div>
          </div>

          <div className="EasypayUserAccountNumberInfoGrid-datesInfo">
            <div className="EasypayUserAccountNumberInfoGrid-datesRow">
              <div className="EasypayUserAccountNumberInfoGrid-dateLabel">
                {translate('page.changingProfileHistory.dateFrom')}
              </div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>
            <div className="EasypayUserAccountNumberInfoGrid-datesRow">
              <div className="EasypayUserAccountNumberInfoGrid-dateLabel">
                {translate('page.changingProfileHistory.dateTo')}
              </div>

              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={StandardizeTimeForDate(new Date(2007, 0, 1), true)}
                max={StandardizeTimeForDate(new Date())}
              />
            </div>
          </div>
        </div>

        <div className="EasypayUserAccountNumberInfoGrid-action">
          <Button type="primary" onClick={this.fetchAllGQL} disabled={!!errorMessage}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {errorMessage && <div className="EasypayUserAccountNumberInfoGrid-error">{errorMessage}</div>}
      </Row>
    );
  };

  getHandlers = () => [
    {
      title: 'page.easypayUsersManage.details',
      action: ({ dataItem }) => tabService.addTab({ type: 'transactionDetails', dataItem }),
      dropdown: false,
      show: true,
    },
  ];

  render() {
    const { transactions, isLoading } = this.state;

    return (
      <Grid
        data={transactions}
        name="easypayUserAccountNumberInfoGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAllGQL}
        isLoading={isLoading}
      >
        <GridColumn
          field="transactionId"
          title="page.easypayUsersManage.transactionNumber"
          width="160"
          handlers={this.getHandlers()}
          dropdown={checkElement('easypayUsersManage-walletTransactionDetails', this.currentArray)}
        />
        <GridColumn field="walletNumber" title="page.easypayUsersManage.accountNumber" width="160" />
        <GridColumn field="serviceId" title="page.easypayUsersManage.service" width="120" />
        <GridColumn field="amount" title="page.easypayUsersManage.sum" width="120" />
        <GridColumn field="statusName" title="page.easypayUsersManage.status" width="150" />
        <GridColumn
          field="dateUpdate"
          title="page.easypayUsersManage.date"
          width="200"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="paymentDetail" title="page.easypayUsersManage.details" width="600" />
      </Grid>
    );
  }
}

export default withTranslate(EasypayUserAccountNumberInfoGrid);
