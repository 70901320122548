import React, { PureComponent } from 'react';

import MFODealersGrid from './MFODealersGrid';
import PageTabs from '../../components/tabber/tabber';
import MFOExpectedChanges from './MFOExpectedChanges';
import MFOHistoryChanges from './MFOHistoryChanges';

class MFODealers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: 'page-nav.nav-MFODealer',
          content: <MFODealersGrid />,
          closable: false,
          key: '0',
        },
      ],
    };
  }

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type }, key) => {
    const { tabs } = this.state;
    switch (type) {
      case 'MFOExpectedChanges':
        tabs.push({
          title: `page.MFODealer.expectedChanges`,
          content: <MFOExpectedChanges />,
          closable: true,
          key: key,
        });
        break;
      case 'MFOHistoryChanges':
        tabs.push({
          title: `page.MFODealer.historyChanges`,
          content: <MFOHistoryChanges />,
          closable: true,
          key: key,
        });
        break;
      default:
        console.warn('Unknown tab type');
        break;
    }

    this.updateTabs(tabs);
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default MFODealers;
