import React, { PureComponent } from 'react';

import PageTabs from '../../components/tabber/tabber';
import EasypayUsersManageGrid from './EasypayUsersManageGrid';
import EasypayUserAccountNumbersGrid from './EasypayUserAccountNumbersGrid/EasypayUserAccountNumbersGrid';
import EasypayUserAccountNumberInfoGrid from './EasypayUserAccountNumberInfoGrid/EasypayUserAccountNumberInfoGrid';
import EasypayUserCardGrid from './EasypayUserCardGrid/EasypayUserCardGrid';
import EasypayUserCardInfoGrid from './EasypayUserCardInfoGrid/EasypayUserCardInfoGrid';
import { withTranslate } from '../../contexts/localContext';
import UserAudit from '../UserAudit';
import EasypayUserTransactionHistory from './EasypayUserTransactionHistory/EasypayUserTransactionHistory';
import EasypayUserRatingGrid from './EasypayUserRating/EasypayUserRatingGrid';
import { GridTransactionDetail } from '../../components/grids';

class EasypayUsersManage extends PureComponent {
  state = {
    tabs: [
      {
        title: 'page-nav.nav-users',
        content: <EasypayUsersManageGrid />,
        closable: false,
        key: '0',
      },
    ],
  };

  updateTabs = (tabs) => {
    this.setState({ tabs: [...tabs] });
  };

  onAddTab = ({ type, dataItem }, key) => {
    const { tabs } = this.state;
    const { translate } = this.props;

    switch (type) {
      case 'accountNumbers':
        tabs.push({
          title: 'page.easypayUsersManage.accountNumbers',
          content: <EasypayUserAccountNumbersGrid dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;

      case 'accountNumberInfo':
        tabs.push({
          title: `${translate('page.easypayUsersManage.walletTransactions')} № ${dataItem.WalletNumber}`,
          content: <EasypayUserAccountNumberInfoGrid dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'userAudit':
        tabs.push({
          title: `${translate('page.userAudit.userAuditTitle')} ${dataItem.firstName} ${dataItem.lastName}`,
          content: <UserAudit dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'cards':
        tabs.push({
          title: `${translate('page.easypayUsersManage.cards')}`,
          content: <EasypayUserCardGrid dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'cardInfo':
        tabs.push({
          title: `${translate('page.easypayUsersManage.cardTransactions')} № ${dataItem.cardId}`,
          content: <EasypayUserCardInfoGrid dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;
      case 'transactionHistory':
        tabs.push({
          title: `${translate('page.easypayUsersManage.transactionHistory')}`,
          content: <EasypayUserTransactionHistory dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;

      case 'userRatingGrid':
        tabs.push({
          title: `${translate('page.userRatingGrid.userRatingGridTitle')}`,
          content: <EasypayUserRatingGrid dataItem={dataItem} />,
          closable: true,
          key: key,
        });
        break;

      case 'transactionDetails':
        tabs.push({
          title: 'page.dashboard.tab-transaction-details',
          content: <GridTransactionDetail dataItem={dataItem} withoutActions />,
          closable: true,
          key: key,
        });
        break;

      default:
        break;
    }
  };

  render() {
    const { tabs } = this.state;
    return <PageTabs tabs={tabs} onRemoveTab={this.updateTabs} onAddTab={this.onAddTab} />;
  }
}

export default withTranslate(EasypayUsersManage);
