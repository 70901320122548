import React, { PureComponent } from 'react';
import { Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';

import './EasypayUserRating.styled.scss';

const api = new ApiService();

class EasypayUserRatingGrid extends PureComponent {
  state = {
    usersPoints: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchAllGQL();
  }

  getGQLResponse = (data) => {
    if (!data || !data.data || !data.data.usersPoints) {
      return { users: [], hasNextPage: false };
    }
    const usersPointsList = data.data.usersPoints;

    const usersPoints = usersPointsList.items && usersPointsList.items.length ? usersPointsList.items : [];

    const pageInfo = usersPoints.pageInfo;

    return {
      usersPoints,
      hasNextPage: pageInfo && pageInfo.hasNextPage,
    };
  };

  iterateFetchGQL = async (skip, usersPointsList, value) => {
    try {
      const query = `usersPoints(take: 500,skip:${skip},where:{and:[{userGuid:{in:["${value}"]}}]}){items{dateUpdate, description, points, transactionId} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);

      const { usersPoints, hasNextPage } = this.getGQLResponse(data, '');

      return hasNextPage
        ? this.iterateFetchSuppliersGQL(skip + 500, [...usersPointsList, ...usersPoints], value)
        : [...usersPointsList, ...usersPoints];
    } catch (e) {
      throw e;
    }
  };

  fetchAllGQL = async () => {
    const {
      dataItem: { userGuid },
    } = this.props;

    try {
      await this.setState({ isLoading: true });
      const usersPoints = await this.iterateFetchGQL(0, [], userGuid);
      await this.setState({ isLoading: false, usersPoints });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  buildToolbar = () => {
    const { translate, dataItem } = this.props;

    return (
      <Row className="EasypayUserCardGrid-toolbar">
        <div className="EasypayUserCardGrid-toolbarItem">
          <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.fullName')}</div>
          <Input className="EasypayUserCardGrid-input" value={`${dataItem.firstName} ${dataItem.lastName}`} disabled />
        </div>

        <div className="EasypayUserCardGrid-toolbarItem">
          <div className="EasypayUserCardGrid-label">{translate('page.easypayUsersManage.phoneNumber')}</div>
          <Input className="EasypayUserCardGrid-phoneInput" value={`${dataItem.phone || ''}`} disabled />
        </div>
      </Row>
    );
  };

  render() {
    const { usersPoints, isLoading } = this.state;

    return (
      <Grid
        data={usersPoints}
        name="easypayUserRatingGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchAllGQL}
        isLoading={isLoading}
      >
        <GridColumn
          field="dateUpdate"
          title="grids.accountsDetail.column-date"
          width="200px"
          filter="date"
          format="dd.MM.yyy HH:mm:ss"
        />
        <GridColumn field="description" title="page.userRatingGrid.description" width="270" />
        <GridColumn field="transactionId" title="grids.reportUsersInStopListGet.column-transactionId" width="170" />
        <GridColumn field="points" title="page.userRatingGrid.points" width="170" />
      </Grid>
    );
  }
}

export default withTranslate(EasypayUserRatingGrid);
