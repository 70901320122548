import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Dropdown, Icon, Menu, Col } from 'antd';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';

import { ApiService } from '../../services';
import DeleteExpectedChangesModal from './MFODealersGrid/modals/DeleteExpectedChangesModal';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const api = new ApiService();

class MFOExpectedChanges extends PureComponent {
  currentArray = findArray('dealersMFO');

  state = {
    dealers: [],
    isLoading: false,
    selectedItems: [],
    modalVariant: '',
    rowAmount: '50',
    filtersString: '',
    sortString: '',
    pageValue: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    isModalOpen: false,
  };

  componentDidMount() {
    this.fetchMFODealers();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount } = this.state;

    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }
      await this.fetchMFODealers();
    }
  }

  fetchMFODealers = async () => {
    try {
      await this.setState({ isLoading: true });

      const { rowAmount, pageValue, sortString } = this.state;

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)}, order: ${
        sortString || '{ ownerName: ASC }'
      }`;

      const data = await api.getByGraphQl(
        `ownersMfoPropertiesFutureList (${argumentsString}){items{ownerId,ownerName, ownersMfoPaymentTypeName,dateStart,userName,ownersMfoPropertiesFutureId}pageInfo{hasNextPage, hasPreviousPage}}`,
      );
      if (!data) {
        this.setState({ isLoading: false });
        return;
      }

      await this.setState({
        dealers: data.data.ownersMfoPropertiesFutureList.items,
        isLoading: false,
        hasNextPage: data.data.ownersMfoPropertiesFutureList.pageInfo.hasNextPage,
        hasPreviousPage: data.data.ownersMfoPropertiesFutureList.pageInfo.hasPreviousPage,
      });
    } catch (e) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedItems } = this.state;

    return (
      <Col className="MFODealersGrid-toolbar">
        <div className="MFODealersGrid-toolbarItem">
          {checkElement('dealersMFO-deleteExpectedChanges', this.currentArray) && (
            <Button
              disabled={!selectedItems.length}
              onClick={() => this.setState({ isModalOpen: true })}
              type="primary"
            >
              {translate('page.userRatingGrid.deleteExpectedChanges')}
            </Button>
          )}
        </div>
      </Col>
    );
  };

  pageChange = (pageValue) => {
    this.setState({ pageValue });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { dealers, isLoading, selectedItems, isModalOpen, hasNextPage, hasPreviousPage, pageValue } = this.state;

    return (
      <>
        <Grid
          data={dealers}
          isLoading={isLoading}
          onRefresh={this.fetchMFODealers}
          name="MFOExpectedChangesGrid"
          toolbar={this.buildToolbar()}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageValue={pageValue}
          pageChange={this.pageChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          setSortingString={this.getStateSetterByName('sortString')}
          handleRowAmountChange={this.handleRowAmountChange}
          fieldForSelection="ownerId"
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="ownerId" title="page.MFODealer.dealerNumber" width="120" filter="numeric" />
          <GridColumn field="ownerName" title="page.MFODealer.dealerName" width="140" />
          <GridColumn field="ownersMfoPaymentTypeName" title="page.MFODealer.calculationType" width="150" />
          <GridColumn
            field="dateStart"
            title="page.MFODealer.dateStart"
            width="130"
            filter="date"
            format="dd.MM.yyyy"
          />
          <GridColumn field="userName" title="page.MFODealer.performer" width="120" />
        </Grid>

        {isModalOpen && (
          <DeleteExpectedChangesModal
            variant={isModalOpen}
            closeModal={() => {
              this.setState({ isModalOpen: false });
            }}
            onRefresh={this.fetchMFODealers}
            selectedItems={selectedItems}
          />
        )}
      </>
    );
  }
}

export default withTranslate(MFOExpectedChanges);
